import React from 'react'
import './Email.scss'

const Email = (props) => {
  let errorJsx = null
  const inputClasses = ['ep-input-email']

  if (props.validateStatus && props.validateStatus === 'error') {
    errorJsx = (
      <section className="ep-error-message">
        <section>{props.message}</section>
      </section>
    )

    inputClasses.push('ep-error')
  }

  return (
    <>
      <input
        className={inputClasses.join(' ')}
        type="email"
        placeholder="Email Address"
        onChange={props.onChange}
        value={props.value}
        readOnly={props.readOnly ? props.readOnly : false}
      />

      {errorJsx}
    </>
  )
}

export default Email
