import React from 'react'

const ModalContent = ({ selectedAppointment }) => {
  let linkMap = '#'
  if (selectedAppointment.address !== '-') {
    linkMap = `https://maps.google.com/?q=${selectedAppointment.address}`
  }
  return (
    <div className="modal-container">
      <div className="modal-content content-info">
        <h2>Appointment details</h2>
        <p className="p-row">
          <span className="row-label">Appointment status:</span>
          <span className="row-value">{selectedAppointment.status}</span>
        </p>
        <p className="p-row">
          <span className="row-label">Date Creation:</span>
          <span className="row-value">{selectedAppointment.created_at}</span>
        </p>
        <p className="p-row">
          <span className="row-label">Date Inspection:</span>
          <span className="row-value">{selectedAppointment.date}</span>
        </p>
        <p className="p-row">
          <span className="row-label">Inspection Time:</span>
          <span className="row-value">{selectedAppointment.time}</span>
        </p>
        <p className="p-row">
          <span className="row-label">Inspector/Mechanic shop:</span>
          <span className="row-value">{selectedAppointment.technician}</span>
        </p>
        <p className="p-row">
          <span className="row-label">Address Inspection:</span>
          <a href={linkMap} target="_blank" className="row-value" rel="noreferrer">
            {selectedAppointment.address}
          </a>
        </p>
        <p className="p-row">
          <span className="row-label">Vehicle:</span>
          <span className="row-value">{selectedAppointment.name}</span>
        </p>
        <p className="p-row">
          <span className="row-label">Phone Number:</span>
          <span className="row-value">{selectedAppointment.technician_phone}</span>
        </p>
        <p className="p-row">
          <span className="row-label">Note:</span>
          <span className="row-value">{selectedAppointment.note}</span>
        </p>
      </div>
      <div className="modal-content content-photo">
        <img src={selectedAppointment.photo} alt="Car image" />
      </div>
    </div>
  )
}

export default ModalContent
