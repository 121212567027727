import React from 'react'
import './Zip.scss'

const Zip = (props) => {
  return (() => {
    let errorJsx = null
    const inputClasses = ['ep-input-email']

    if (props.validateStatus && props.validateStatus === 'error' && props.errors) {
      errorJsx = (
        <div className="ep-error-message">
          <div>{props.message}</div>
        </div>
      )

      inputClasses.push('ep-error')
    }

    return (
      <>
        <i className="ep-icon ep-icon-zip ep-icon-absolute" />
        <input className={`ep-input-zip ${inputClasses.join(' ')}`} type="text" placeholder="Enter ZIP code" onChange={props.onChange} value={props.value} />

        {errorJsx}
      </>
    )
  })()
}

export default Zip
