import axios from 'axios'

export const inspector = () => {
  let config = {}
  const auth = JSON.parse(localStorage.getItem('auth'))
  let technicianId = 0

  if (auth && typeof auth.access_token !== 'undefined') {
    config = {
      headers: {
        Authorization: `Bearer ${auth.access_token}`,
      },
    }
  }

  if (auth && typeof auth.technician !== 'undefined' && auth.technician) {
    technicianId = auth.technician.id
  }
  return axios.get(`${process.env.REACT_APP_API_URL}/dashboard/technicians/${technicianId}`, config)
}
