import React from 'react'
import Container from '../../Container'
import Form from './form'

import './index.scss'

const SignUp = () => {
  return (
    <Container>
      <Form />
    </Container>
  )
}

export default SignUp
