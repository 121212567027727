import React, { useEffect, useRef, useState } from 'react'
import { Layout } from 'antd'
import './Container.scss'
import Header from './Header'
import Footer from '../components/footer/Footer'

const Container = (props) => {
  const [height, setHeight] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    const html = window.document.documentElement
    setHeight(html.scrollHeight - 162)
  }, [])

  return (
    <div className="ep-container">
      <Layout className="ep-layout">
        <Header />
        <Layout.Content>
          <div ref={ref} style={{ height: `${height}px` }}>
            {props.children}
          </div>
        </Layout.Content>
      </Layout>
      <Footer />
    </div>
  )
}

export default Container
