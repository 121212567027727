import React, { useEffect, useState } from 'react'
import MyAccount from '../../myAccount'
import { Table, Modal, Button } from 'antd'
import './currentAppointments.scss'
import { extendMoment } from 'moment-range'
import ModalContent from './modalContent'
import { getUpcoming } from 'api/getUpcomingInspectors'
import { getPeriodString } from 'helper/periodHelper'
import noImage from 'images/inspection/noimage-car.jpeg'
import WheelLoader from 'components/loaders/WheelLoader'
import { getAuthData } from 'helper/auth'
import Filter from './Filter'

const Scheduled = () => {
  const Moment = require('moment')
  const moment = extendMoment(Moment)

  const [data, setData] = useState([])
  const [dataForModal, setDataForModal] = useState([])
  const [selectedAppointment, setSelectedAppointment] = useState({})
  const [visibleDetail, setVisibleDetail] = useState(false)
  const [loadingData, setLoadingData] = useState(false)
  const [mechanicshopAddress, setMechanicshopAddress] = useState('')
  const [filter, setFilter] = useState('All')
  const [filterInput, setFilterInput] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [dateRange, setDateRange] = useState({
    from: '',
    to: '',
  })

  const authTecnician = getAuthData().technician

  useEffect(() => {
    setFilterInput('')
  }, [filter])

  const columns = [
    {
      title: 'Appointment',
      dataIndex: 'appointment',
      key: 'appointment',
      render: (text) => <>{text}</>,
    },
    {
      title: 'Date and Time of inspection',
      dataIndex: 'date_time',
      key: 'date_time',
      render: (text) => (
        <>
          <div>{text.date}</div> <div>{text.time}</div>
        </>
      ),
    },
    {
      title: 'Vehicle',
      dataIndex: 'vehicle',
      key: 'vehicle',
      render: (text) => <>{text}</>,
    },
    {
      title: 'Inspection Package',
      dataIndex: 'package',
      key: 'package',
      render: (text) => <>{text}</>,
    },
    {
      title: 'Detail',
      dataIndex: 'view',
      key: 'view',
      render: (id) => (
        <a href="#" onClick={() => handleView(id)}>
          Detail
        </a>
      ),
    },
  ]

  useEffect(() => {
    setLoadingData(true)

    let address = ''

    if (authTecnician.is_bodyshop && authTecnician.appartment) {
      address = `${authTecnician.street}, ${authTecnician.appartment}, ${authTecnician.city}, ${authTecnician.zip}`
    } else if (authTecnician.is_bodyshop && !authTecnician.appartment) {
      address = `${authTecnician.street}, ${authTecnician.city}, ${authTecnician.zip}`
    }

    setMechanicshopAddress(address)

    getUpcoming()
      .then((results) => {
        const rows = []
        const res = results.data.data

        res.map((row, index) => {
          let inspectionTime = ''
          let vehicle = `${row.src_car_info.attributes.make} ${row.src_car_info.attributes.model} ${row.src_car_info.attributes.year}`

          if (row.inspection_time_from && row.inspection_time_end) {
            inspectionTime = getPeriodString(row.inspection_time_from, row.inspection_time_end)
          } else {
            inspectionTime = moment(row.inspection_date_time).format('hh:mm A')
          }

          rows.push({
            appointment: row.id,
            date_time: {
              date: moment(row.inspection_date_time).format('DD MMM YYYY'),
              time: inspectionTime,
            },
            vehicle: vehicle.length < 16 ? vehicle : `${vehicle.slice(0, 16)}...`,
            package: row.package.name,
            view: row.id,
            key: index,
          })
        })

        setDataForModal(res)
        setData(rows)
        setFilteredData(rows)
        setLoadingData(false)
      })
      .catch(() => setLoadingData(false))
  }, [])

  const handleView = (id) => {
    setVisibleDetail(true)

    const viewAppointment = dataForModal.filter((item) => item.id === id)[0]

    let address = ''
    let inspectionTime = ''

    if (viewAppointment.inspection_time_from && viewAppointment.inspection_time_end) {
      inspectionTime = getPeriodString(viewAppointment.inspection_time_from, viewAppointment.inspection_time_end)
    } else {
      inspectionTime = moment(viewAppointment.inspection_date_time).format('hh:mm A')
    }

    if (viewAppointment.is_our_location) {
      address = mechanicshopAddress
    } else {
      address = viewAppointment.location.zip ? `${viewAppointment.location.address}, ${viewAppointment.location.zip}` : viewAppointment.location.address
    }

    const data = {
      firstName: viewAppointment.customer.user.first_name.replace(/^\s+/, ''),
      lastName: viewAppointment.customer.user.last_name.replace(/^\s+/, ''),
      phone: viewAppointment.customer.user.phone,
      appointmentId: viewAppointment.id,
      location: { address },
      note: viewAppointment.note ? viewAppointment.note : "The customer hasn't provided a note",
      inspectionDate: moment(viewAppointment.inspection_date_time).format('DD MMM YYYY'),
      inspectionTime,
      package: viewAppointment.package.name,
      carPhoto: viewAppointment.src_car_info?.photos[0]?.url ?? noImage,
      vehicle: `${viewAppointment.src_car_info.attributes.make} ${viewAppointment.src_car_info.attributes.model} ${viewAppointment.src_car_info.attributes.year}`,
    }

    setSelectedAppointment(data)
  }

  const handleFilterInput = (e) => {
    if (filter === 'Package') {
      setFilterInput(e)
    } else {
      setFilterInput(e.target.value)
    }
  }

  const handleFilter = (e) => {
    setFilter(e)
  }

  useEffect(() => {
    let filtered = []
    if (filter === 'All') {
      filtered = data
    } else if (filter === 'Appointment') {
      filtered = data.filter((item) => {
        return item.appointment.toString().includes(filterInput)
      })
    } else if (filter === 'Vehicle') {
      filtered = data.filter((item) => {
        return item.vehicle.toLowerCase().includes(filterInput)
      })
    } else if (filter === 'Package') {
      if (filterInput.length > 0) {
        filtered = data.filter((item) => {
          return item.package === filterInput
        })
      } else {
        filtered = data
      }
    } else if (filter === 'Date') {
      filtered = data.filter((item) => {
        const start = dateRange.from
        const end = dateRange.to
        const range = moment.range(start, end)
        if (range.contains(moment(item.date_time.date))) {
          return true
        }
        if (
          moment(start).format('DD MMM YYYY') === moment(end).format('DD MMM YYYY') &&
          moment(item.date_time.date).format('DD MMM YYYY') === moment(start).format('DD MMM YYYY')
        ) {
          return true
        }
      })
    }

    setFilteredData(filtered)
  }, [filterInput, filter, dateRange])

  const onCalendarChange = (range) => {
    if (!range) return setDateRange({ from: '', to: '' })

    const from = range[0] ? moment(range[0]).format('MM/DD/YYYY') : null
    const to = range[1] ? moment(range[1]).format('MM/DD/YYYY') : null

    setDateRange((state) => ({
      from: from || state.from,
      to: to || state.to,
    }))
  }

  return (
    <>
      <MyAccount>
        {loadingData ? (
          <WheelLoader />
        ) : (
          <>
            <Filter
              handleFilter={handleFilter}
              filter={filter}
              handleFilterInput={handleFilterInput}
              filterInput={filterInput}
              onCalendarChange={onCalendarChange}
              dateRange={dateRange}
            />
            <Table
              pagination={{ pageSize: 7 }}
              columns={columns}
              dataSource={filteredData}
              onRow={() => {
                return {
                  onClick: () => {}, // click row
                  onDoubleClick: () => {}, // double click row
                  onContextMenu: () => {}, // right button click row
                  onMouseEnter: () => {}, // mouse enter row
                  onMouseLeave: () => {}, // mouse leave row
                }
              }}
            />
          </>
        )}

        <Modal
          className="ap-modal"
          visible={visibleDetail}
          // title="Appointment Detail"
          onOk={() => setVisibleDetail(false)}
          onCancel={() => setVisibleDetail(false)}
          footer={[
            <Button key="submit" type="primary" onClick={() => setVisibleDetail(false)}>
              Close
            </Button>,
          ]}
        >
          <ModalContent selectedAppointment={selectedAppointment} />
        </Modal>
      </MyAccount>
    </>
  )
}

export default Scheduled
