import React, { useEffect, useState, useCallback } from 'react'
import { Col, Row, message } from 'antd'
import { connect } from 'react-redux'
import MapComponentWithMarkers from 'components/MapComponentWithMarkers'
import Steps from 'components/steps'
import Container from '../../../../Container'
import ListItem from './components/ListItem'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import actions from 'store/order/actions'
import './Index.scss'
import ContinueBackCmp from 'components/buttons/ContinueBackCmp'
import { isOpen } from 'helper/openRangeHelper'

const filterWithoutCoords = (array = []) => {
  return array.filter((item) => {
    const itemLat = item?.coords?.lat;
    const itemLng = item?.coords?.lng;

    return itemLat && itemLng;
  });
};

const SelectShop = (props) => {
  const history = useHistory();
  const [selected, setSelected] = useState(undefined);

  const [bodyShops, setBodyShops] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    window.scrollTo(0, 0)
    
    const auth = JSON.parse(window.localStorage.getItem('auth'))

    if (auth?.technician) {
      history.push('/inspector/my-account/statistic')
    }

    props.setOrderInspection({
      ...props.orderReducer.inspection,
      shop: null,
    });
  }, []);

  const fetchBodyShopsByZipCoode = useCallback(async () => {
    try {
      let allResults = await axios.get(`${apiUrl}/lists/technicians?is_body_shop=1`);

      let shopsWithZip = [];
      let shopsWithoutZip = [];

      const allShops = allResults.data.map((singleShop) => {
        const {
          geo_coordinates,
          id,
          street,
          city,
          zip,
          open_time,
          close_time,
          user: { first_name, phone },
          work_days,
        } = singleShop;
        return {
          name: first_name,
          isOpen: isOpen(open_time, close_time),
          location: `${street} ${city}, ${zip}`,
          workTime: `${open_time}-${close_time}`,
          number: phone,
          id,
          coords: geo_coordinates,
          workDays: work_days,
          zip,
        };
      });

      allShops.map((shop) => {
        if (shop.zip === props.inspection.location.zip) {
          shopsWithZip.push(shop);
        } else {
          shopsWithoutZip.push(shop);
        }
      });

      setBodyShops(filterWithoutCoords([...shopsWithZip, ...shopsWithoutZip]));
    } catch (error) {
      console.log('err', error)
    }
  }, [apiUrl, props.inspection.location.zip]);

  useEffect(() => {
    fetchBodyShopsByZipCoode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPress = (id) => {
    setSelected(id);

    const current = bodyShops.find((shop) => shop.id === id);
    props.setOrderInspection({
      ...props.inspection,
      shop: current,
    });
    //TODO: run props.setOrderInspection(payload);
    // default
    //   location: {
    //     zip: "91911",
    //     address: "1003 Fourth Ave, San Diego, CA 92101, USA",
    //     geo_coordinates: {
    //         lat: 32.71575,
    //         lng: -117.1610914
    //     }
    // },
  };

  const arrayOfView = () =>
    bodyShops.map((item) => {
      return (
        <ListItem
          name={item.name}
          location={item.location}
          isOpen={item.isOpen}
          workTime={item.workTime}
          number={item.number}
          id={item.id}
          key={item.id}
          onPress={(index) => onPress(index)}
        />
      );
    });

  return (
    <Container>
      <Steps style={{ marginTop: '35px' }} />
      <p className="SelectShopTitleSt">Select Mechanic Shop</p>
      <div className="main-container">
        <div
          className="List"
          // style={{ borderTopLeftRadius: 12, borderBottomLeftRadius: 12 }}
        >
          {arrayOfView()}
        </div>
        {bodyShops.length && (
          <div className="Map">
            <MapComponentWithMarkers
              onPress={(index) => onPress(index)}
              zoom={12}
              selected={selected}
              markers={bodyShops}
              onMarkerPress={(id) => onPress(id)}
              style={{
                height: '650px',
                width: '1040px',
                borderBottomRightRadius: 12,
                borderTopRightRadius: 12,
              }}
            />
          </div>
        )}
      </div>
      <Row>
        <Col span={24}>
          <ContinueBackCmp
            route={props.inspection.shop ? '/order/payment' : '/order/location/select-shop'}
            onClick={() => (props.inspection.shop ? null : message.error('Choose the mechanic shop'))}
            pageName="select shop"
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    orderReducer: state.order.orderReducer,
    applicantInfo: state.order.orderReducer.applicantInfo,
    inspection: state.order.orderReducer.inspection,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOrderInspection: (payload) => dispatch(actions.setOrderInspection(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectShop);
