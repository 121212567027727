import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import { useForm } from 'antd/es/form/Form'
import Modal from 'antd/lib/modal/Modal'
import Validator from 'validatorjs'

import './setting.scss'
import MyAccount from '../../myAccount'
import { getAuthData } from 'helper/auth'
import { inspector } from 'api/inspector'
import { changeInfoTechician, changePassword } from '../../../../../api/user'

import GeneralSettingsTechnician from './generalSettingsTechician'
import GeneralSettingsBodyshop from './generalSettingsBodyshop'
import PasswordSettings from './passwordSettings'
import WorkTimeAndDays from './workTimeAndDays'
import ModalContent from './modalContent'

const dataInputs = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  old_password: '',
  password: '',
  req_password: '',
  street: '',
  suit: '',
  city: '',
  zip: '',
  open_time: '',
  close_time: '',
  work_days: [],
  send_mail: 0,
  is_bodyshop: 1,
  company_id: 1,
}

const Setting = () => {
  const auth = getAuthData()
  const [form] = useForm()
  const [isBodyshop, setIsBodyshop] = useState()
  const [visibleLocation, setVisibleLocation] = useState(false)
  const [dataForCancel, setDataForCancel] = useState(dataInputs)
  const [inspectorData, setInspectorData] = useState(dataInputs)
  const [errors, setErrors] = useState(dataInputs)

  const [messages, setMessages] = useState({
    old_password: {
      message: '',
      validateStatus: null,
    },
    password: {
      message: '',
      validateStatus: null,
    },
    req_password: {
      message: '',
      validateStatus: null,
    },
  })

  useEffect(() => {
    inspector().then((data) => {
      const _inspector = { ...inspectorData }
      _inspector.first_name = data.data.first_name
      _inspector.last_name = data.data.last_name
      _inspector.email = data.data.email
      _inspector.phone = data.data.phone
      _inspector.city = data.data.city ?? inspectorData.city
      _inspector.zip = data.data.zip ?? inspectorData.zip
      _inspector.street = data.data.street ?? inspectorData.street
      _inspector.suit = data.data.appartment ?? inspectorData.suit
      _inspector.send_mail = 0
      _inspector.company_id = auth.technician.company_id
      _inspector.is_bodyshop = auth && auth.technician.is_bodyshop
      _inspector.open_time = data.data.open_time ?? inspectorData.open_time
      _inspector.close_time = data.data.close_time ?? inspectorData.close_time
      _inspector.work_days = data.data.work_days ?? inspectorData.work_days

      setInspectorData(_inspector)
      setDataForCancel(_inspector)
      setIsBodyshop(data.data.is_bodyshop)
      form.setFieldsValue(_inspector)
    })
  }, [])

  const handleChangeInput = (e, name) => {
    const _inspector = { ...inspectorData }
    _inspector[name] = e.target.value
    setInspectorData(_inspector)
    setErrors({
      ...errors,
      [name]: '',
    })

    setMessages({
      ...messages,
      [name]: {
        message: '',
        validateStatus: null,
      },
    })
  }

  const submitPassword = () => {
    const { old_password, password, req_password } = inspectorData
    const data = {
      old_password,
      password,
      req_password,
    }

    const rules = {
      old_password: ['required', 'regex:/^[a-zA-Z0-9!@#$%^&*)(+=._-]{8,24}$/'],
      password: ['required', 'regex:/^[a-zA-Z0-9!@#$%^&*)(+=._-]{8,24}$/'],
      req_password: ['required', 'regex:/^[a-zA-Z0-9!@#$%^&*)(+=._-]{8,24}$/', 'same:password'],
    }

    const customMessages = {
      required: 'The :attribute field is required',
      'required.old_password': 'Input your current password',
      'required.password': 'Input your new password',
      'required.req_password': 'Confirm your new password',

      min: 'At least 8 chars',
      max: 'A maximum of 24 chars',
    }

    const validation = new Validator(data, rules, customMessages)

    if (validation.passes()) {
      changePassword({ old_password, password, req_password })
        .then(() => {
          message.success('Your password changed')
        })
        .catch((err) => {
          message.error(err.response.data.error.message)
        })
    }

    if (validation.fails()) {
      const errors = validation.errors.all()
      let msg = { ...messages }

      for (let prop in errors) {
        msg = {
          ...msg,
          [prop]: { message: errors[prop][0], validateStatus: 'error' },
        }
      }
      setMessages(msg)
    }
  }

  const submitInfo = () => {
    const { first_name, last_name, email, phone, zip, street, suit, city, send_mail, is_bodyshop, company_id, work_days, close_time, open_time } = inspectorData

    const data = {
      first_name,
      email,
      phone: phone.replace(/\s/g, ''),
      send_mail,
      is_bodyshop,
      company_id,
      open_time,
      close_time,
      work_days,
    }

    const dataTechnician = {
      last_name,
      ...data,
    }

    const dataBodyshop = {
      zip,
      street,
      appartment: suit,
      city,
      ...data,
    }

    const rulesTechnician = {
      phone: 'required|digits:10',
      email: 'required|email',
      last_name: ['required', 'regex:/^[A-Za-z]+$/'],
      first_name: ['required', 'regex:/^[A-Za-z]+$/'],
    }

    const rulesBodyshop = {
      ...rulesTechnician,
      first_name: 'required',
      last_name: '',
      zip: 'required',
      street: 'required',
      city: 'required',
    }
    const customMessagesTechnician = {
      'required.first_name': 'Input your first name',
      'required.last_name': 'Input your last name',

      'required.phone': 'Input your phone',
      'required.email': 'Input your email',

      regex: 'Use only letters',
      'digits.phone': 'Use only 10 digits',
    }

    const customMessagesBodyshop = {
      ...customMessagesTechnician,
      'required.zip': 'Input your ZIP',
      'required.city': 'Input your city',
      'required.street': 'Input your street',
    }

    const validation = isBodyshop
      ? new Validator(dataBodyshop, rulesBodyshop, customMessagesBodyshop)
      : new Validator(dataTechnician, rulesTechnician, customMessagesTechnician)

    const dataObj = isBodyshop ? dataBodyshop : dataTechnician

    if (validation.passes()) {
      changeInfoTechician(dataObj)
        .then(() => {
          message.success('Your information changed')
        })
        .catch((err) => {
          message.error(err.response.data.message)
        })
    }

    if (validation.fails()) {
      const errs = validation.errors.all()
      let objOfErrors = { ...errors }

      for (let prop in errs) {
        objOfErrors = {
          ...objOfErrors,
          [prop]: errs[prop][0],
        }
      }

      setErrors(objOfErrors)
    }
  }

  const cancelInfo = (formName) => {
    let arrayOfFields = []

    const getObjectData = (fields) => {
      let obj = { ...inspectorData }

      fields.map((field) => {
        obj = {
          ...obj,
          [field]: dataForCancel[field],
        }
      })

      return obj
    }

    const getObjectErrors = (fields) => {
      let obj = { ...errors }

      fields.map((field) => {
        obj = {
          ...obj,
          [field]: '',
        }
      })

      return obj
    }

    if (formName === 'password') {
      arrayOfFields = ['password', 'old_password', 'req_password']
    } else if (formName === 'generalBodyshop') {
      arrayOfFields = ['first_name', 'last_name', 'email', 'phone', 'street', 'suit', 'city', 'zip']
    } else {
      arrayOfFields = ['first_name', 'last_name', 'email', 'phone']
    }

    setInspectorData(getObjectData(arrayOfFields))
    form.setFieldsValue(getObjectData(arrayOfFields))
    setErrors(getObjectErrors(arrayOfFields))
  }

  return (
    <MyAccount>
      {isBodyshop ? (
        <GeneralSettingsBodyshop
          inspectorData={inspectorData}
          form={form}
          errors={errors}
          handleChangeInput={handleChangeInput}
          submitInfo={submitInfo}
          setVisibleLocation={setVisibleLocation}
          cancelInfo={cancelInfo}
        />
      ) : (
        <GeneralSettingsTechnician
          inspectorData={inspectorData}
          form={form}
          errors={errors}
          handleChangeInput={handleChangeInput}
          submitInfo={submitInfo}
          cancelInfo={cancelInfo}
        />
      )}
      <PasswordSettings
        inspectorData={inspectorData}
        form={form}
        handleChangeInput={handleChangeInput}
        submitPassword={submitPassword}
        cancelInfo={cancelInfo}
        messages={messages}
      />
      <WorkTimeAndDays inspectorData={inspectorData} />
      <Modal
        className="ap-modal"
        visible={visibleLocation}
        onOk={() => setVisibleLocation(false)}
        onCancel={() => setVisibleLocation(false)}
        footer={() => null}
      >
        <ModalContent setInspectorData={setInspectorData} inspectorData={inspectorData} setVisibleLocation={setVisibleLocation} />
      </Modal>
    </MyAccount>
  )
}

export default Setting
