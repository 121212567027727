import React from 'react'
import './Text.scss'

const Text = (props) => {
  let errorJsx = null
  const inputClasses = ['ep-input-email']

  if (props.validateStatus && props.validateStatus === 'error') {
    errorJsx = (
      <section className="ep-error-message">
        <section>{props.message}</section>
      </section>
    )

    inputClasses.push('ep-error')
  }

  return (
    <>
      <i className="ep-icon ep-icon-rect ep-icon-absolute" />
      <input className={inputClasses.join(' ')} type="text" placeholder={props.placeholder} onChange={props.onChange} value={props.value} />

      {errorJsx}
    </>
  )
}

export default Text
