import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'
import './Index.scss'
import actions from '../../../../../../../store/order/actions'

/**
 * Chose date component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ChoseDate = (props) => {
  const [dates, setDates] = useState([])
  const [clickCount, setClickCount] = useState(1)
  const isDisabledDate = (item) => {
    return props.inspection.shop && !props.inspection.shop.workDays?.includes(item.fullDayOfWeek)
  }

  useEffect(() => {
    const currentDate = moment()
    refreshDates(currentDate)
  }, [])

  /**
   * Next dates
   */
  const next = () => {
    const currentDate = moment(dates[6].date).add(1, 'days')

    if (clickCount == 4) {
      setClickCount(4)
    } else {
      setClickCount(clickCount + 1)
      refreshDates(currentDate)
    }
  }

  /**
   * Prev dates.
   */
  const prev = () => {
    const currentDate = moment(dates[0].date)
    currentDate.subtract(7, 'days')

    if (clickCount == 1) {
      setClickCount(1)
    } else {
      setClickCount(clickCount - 1)
      refreshDates(currentDate)
    }
  }

  /**
   * Refresh dates list.
   *
   * @param currentDate
   */
  const refreshDates = (currentDate) => {
    const listDates = []

    listDates.push({
      dayOfMonth: currentDate.format('Do'),
      dayOfWeek: currentDate.format('ddd'),
      fullDayOfWeek: currentDate.format('dddd'),
      date: currentDate.format('YYYY-MM-DD'),
      monthOfYear: currentDate.format('MMM'),
    })

    for (let i = 1; i < 7; i++) {
      currentDate.add(1, 'days')
      listDates.push({
        dayOfMonth: currentDate.format('Do'),
        dayOfWeek: currentDate.format('ddd'),
        fullDayOfWeek: currentDate.format('dddd'),
        date: currentDate.format('YYYY-MM-DD'),
        monthOfYear: currentDate.format('MMM'),
      })
    }

    setDates(listDates)
  }

  /**
   * Select date.
   *
   * @param date
   */
  const handleSelect = (date, day) => {
    if (!props.isBodyshop || (props.isBodyshop && props.inspection.shop?.workDays?.includes(day))) {
      props.setOrderInspection({
        ...props.inspection,
        date: date,
      })
    } else {
      return null
    }
  }

  return (
    <div className="ep-date-scroll-container">
      <div className="ep-date-title">Choose Date</div>
      <div className="ep-date-scroll">
        <div className={`ep-date-scroll-left ${clickCount == 1 && 'disabled'}`} onClick={prev}>
          <LeftOutlined />
        </div>
        <div className="ep-date-scroll-items">
          {dates.map((item, index) => {
            return (
              <div
                className={`ep-date-scroll-item ${props.inspection.date === item.date && 'active'} ${isDisabledDate(item) && 'disabledDate'}`}
                key={`date-${index}`}
                onClick={() => handleSelect(item.date, item.fullDayOfWeek)}
              >
                <div className="ep-date-month-of-year">{item.monthOfYear}</div>
                <div className="ep-date-date">{item.dayOfMonth}</div>
                <div className="ep-date-day-of-week">{item.dayOfWeek}</div>
                <div className="block-for-disabled">Sorry, inspection on this date is unlivable.</div>
              </div>
            )
          })}
        </div>
        <div className={`ep-date-scroll-right ${clickCount == 4 && 'disabled'}`} onClick={next}>
          <RightOutlined />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    inspection: state.order.orderReducer.inspection,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrderInspection: (payload) => dispatch(actions.setOrderInspection(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChoseDate)
