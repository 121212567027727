import React from 'react'
import InputMask from 'react-input-mask'
import './Phone.scss'

const Phone = (props) => {
  let errorJsx = null
  const inputClasses = ['ep-input-phone']

  if (props.validateStatus && props.validateStatus === 'error') {
    errorJsx = (
      <section className="ep-error-message">
        <section>{props.message}</section>
      </section>
    )

    inputClasses.push('ep-error')
  }

  return (
    <>
      <i className="ep-icon ep-icon-phone ep-icon-absolute" />

      <InputMask mask="999-999-9999" value={props.value} onChange={props.onChange}>
        {(inputProps) => <input className={inputClasses.join(' ')} type="tel" placeholder="Phone Number" onChange={props.onChange} value={props.value} />}
      </InputMask>

      {errorJsx}
    </>
  )
}

export default Phone
