import axios from 'axios'

/**
 * Get notifications.
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export const sendMessage = (subject, text) => {
  return new Promise((resolve, reject) => {
    let config = {}
    const auth = JSON.parse(localStorage.getItem('auth'))

    if (auth && typeof auth.access_token !== 'undefined') {
      config = {
        headers: {
          Authorization: `Bearer ${auth.access_token}`,
        },
      }

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/mail/send`,
          {
            subject,
            text,
          },
          config
        )
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    } else {
      reject({
        error: {
          code: 401,
          message: 'no authorization',
        },
      })
    }
  })
}
