import React from 'react'
import { Col, Row } from 'antd'
import './index.scss'

const TotalAndNotes = (props) => {
  return (
    <>
      <Row className="PaymentTableCheckoutRow">
        <Col span="6">
          <h2 style={{ color: 'white' }}>Enter promo code</h2>
          <input className={`ep-input-person-name`} type="text" placeholder="Promo code" onChange={props.onPromoChange}/>
        </Col>
        <Col span="10">
          <h2>Leave a note for the Inspector</h2>
          <textarea className={`ep-input-person-name`} type="text" onChange={props.onNotesChange}/>
        </Col>
      </Row>
      <Row className="PaymentTableCheckoutRow">
        <Col span="24">
          <div className="total-container">
            <div className="ep-input-total">
              <h4 className="price-title">Subtotal</h4>
              <span>{props.subtotal}$</span>
            </div>
            {props.discount && <div className="ep-input-total">
              <h4 className="price-title">Promo</h4>
              <span>{props.discount}%</span>
            </div>}
            {props.tax && <div className="ep-input-total">
              <h4 className="price-title">Estimated Tax</h4>
              <span>{props.tax}$</span>
            </div>}
            <div className="ep-input-total">
              <h4 className="price-title">Total Amount</h4>
              <span>{props.total}$</span>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default TotalAndNotes
