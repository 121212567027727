import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Col, message, Row, Result, Button } from 'antd'
import { Link } from 'react-router-dom'
import Validator from 'validatorjs'

import Container from '../Container'
import './signUp.scss'
import { registrationCustomer } from '../../../api/user'

import PersonNameSignUp from './personName/PersonNameSignUp'
import EmailSignIn from '../signIn/email/EmailSignIn'
import PasswordSignIn from '../signIn/password/PasswordSignIn'
import PhoneSingUp from './phone/PhoneSingUp'

const SignUp = () => {
  const history = useHistory()
  const [successful, setSuccessful] = useState(false)
  const [regForm, setRegForm] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: '',
    confirm_password: '',
    state_code: 'LA',
  })
  const [errorMessages, setErrorMessages] = useState({})

  /**
   * Handle change input.
   *
   * @param e
   * @param name
   */
  const handleChange = (e, name) => {
    setRegForm({ ...regForm, [name]: e.target.value })
    setErrorMessages({
      ...errorMessages,
      [name]: {},
    })
  }

  const handleSubmit = () => {
    const rules = {
      first_name: ['required', 'regex:/^[A-Za-z]+$/'],
      last_name: ['required', 'regex:/^[A-Za-z]+$/'],
      email: 'required|email',
      phone: ['required', 'regex:/^[0-9]{10}$/'],
      password: ['required', 'regex:/^[a-zA-Z0-9!@#$%^&*)(+=._-]{8,24}$/'],
      confirm_password: ['required', 'regex:/^[a-zA-Z0-9!@#$%^&*)(+=._-]{8,24}$/', 'same:password'],
    }

    const getOnlyDigits = (string) => {
      if (string !== null) {
        if (string.includes('-')) {
          return string.split('-').join('')
        } else {
          return string.split(' ').join('')
        }
      }
    }

    const data = { ...regForm, phone: getOnlyDigits(regForm.phone) }

    const customMessages = {
      required: 'The :attribute field is required',
      'regex.password': 'The password should be 8 to 24 chars long',
      'regex.first_name': 'Use only letters',
      'regex.last_name': 'Use only letters',
      'regex.phone': 'Use only 10 digits',
    }

    const validation = new Validator(data, rules, customMessages)

    if (validation.passes()) {
      const regData = { ...regForm, phone: getOnlyDigits(regForm.phone) }
      registrationCustomer(regData)
        .then(() => {
          setSuccessful(true)
        })
        .catch((err) => {
          for (let prop in err.response.data.error) {
            message.error(err.response.data.error[prop][0])
          }
        })
    } else if (validation.fails()) {
      const errors = validation.errors.all()
      let msg = {}

      for (let prop in errors) {
        msg[prop] = { message: errors[prop][0], validateStatus: 'error' }
      }
      setErrorMessages(msg)
    }
  }

  const goSignIn = () => {
    history.push('/sign-in')
  }

  const goHome = () => {
    history.push('/sign-in')
  }

  return (() => {
    if (successful) {
      return (
        <Result
          status="success"
          title="Successfully Registration!"
          subTitle="Thank you for registering!"
          extra={[
            <Button type="primary" key="sign-in" onClick={goSignIn}>
              Sign In
            </Button>,
            <Button key="home" onClick={goHome}>
              Go Home
            </Button>,
          ]}
        />
      )
    } else {
      return (
        <Container>
          <div className="reg-form" style={{ height: '950px' }}>
            <div className="ep-sign-up-form">
              <h2>Sign Up</h2>
              <Row>
                <Col span={24}>
                  <PersonNameSignUp
                    onChange={(firstName, lastName) => {
                      if (firstName) {
                        setRegForm({
                          ...regForm,
                          first_name: firstName,
                        })
                        setErrorMessages({
                          ...errorMessages,
                          first_name: {},
                        })
                      }

                      if (lastName) {
                        setRegForm({
                          ...regForm,
                          last_name: lastName,
                        })
                        setErrorMessages({
                          ...errorMessages,
                          last_name: {},
                        })
                      }
                    }}
                    validateLastStatus={errorMessages?.last_name?.validateStatus}
                    messageLast={errorMessages?.last_name?.message}
                    validateFirstStatus={errorMessages?.first_name?.validateStatus}
                    messageFirst={errorMessages?.first_name?.message}
                    firstNameErrors={errorMessages?.first_name?.validateStatus}
                    lastNameErrors={errorMessages?.last_name?.validateStatus}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <EmailSignIn
                    onChange={(e) => handleChange(e, 'email')}
                    message={errorMessages?.email?.message}
                    validateStatus={errorMessages?.email?.validateStatus}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <PhoneSingUp
                    onChange={(e) => handleChange(e, 'phone')}
                    message={errorMessages?.phone?.message}
                    validateStatus={errorMessages?.phone?.validateStatus}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <PasswordSignIn
                    placeholder="Password"
                    onChange={(e) => handleChange(e, 'password')}
                    errorMessage={errorMessages?.password?.message}
                    validateStatus={errorMessages?.password?.validateStatus}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <PasswordSignIn
                    placeholder="Confirm Password"
                    onChange={(e) => handleChange(e, 'confirm_password')}
                    errorMessage={errorMessages?.confirm_password?.message}
                    validateStatus={errorMessages?.confirm_password?.validateStatus}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: '25px' }}>
                <Col span={12}>
                  <Link to="/sign-in" className="label-primary" style={{ width: '78px' }}>
                    Sign In Now
                  </Link>
                </Col>

                <Col span={12}>
                  <Link to="/sign-in" className="label-default">
                    Already have an account?
                  </Link>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <button className="ep-button ep-sign-up" onClick={handleSubmit}>
                    Sign Up
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      )
    }
  })()
}

export default SignUp
