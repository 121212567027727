import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'antd'
import PersonName from '../../../../../../../components/inputs/personName'
import actions from '../../../../../../../store/order/actions'
import './Index.scss'
import CardData from './CardData'

const CardInfo = (props) => {
  const [messages, setMessages] = useState({})

  useEffect(() => {
    if (props.errors !== null) {
      setMessages({ ...props.errors })
    }
  }, [props.errors])

  const handleChangeCard = (attr, value) => {
    props.setErrors({
      ...props.errors,
      [attr]: {
        message: '',
        validateStatus: null,
      },
    })

    props.setOrderPayment({
      ...props.payment,
      [attr]: value,
    })
  }

  const handleChangeCVC = (e) => {
    props.setErrors({
      ...props.errors,
      cvc: {
        message: '',
        validateStatus: null,
      },
    })

    props.setOrderPayment({
      ...props.payment,
      cvc: e.target.value,
    })
  }

  useEffect(() => {
    if (props.sameApplicantInfo) {
      props.setOrderPayment({
        ...props.payment,
        firstName: props.applicantInfo.firstName,
        lastName: props.applicantInfo.lastName,
      })
    }
    if (!props.sameApplicantInfo && props.payment.firstName == props.applicantInfo.firstName) {
      props.setOrderPayment({
        ...props.payment,
        firstName: null,
        lastName: null,
      })
    }
  }, [props.sameApplicantInfo, props.applicantInfo.firstName, props.applicantInfo.lastName])

  return (
    <Row className="ep-payment-form">
      <Col span={24}>
        <div className="ep-card-container">
          {/*  <div>
                        <h2>Payment</h2>
                     <div className="ep-card-buttons">
                            <div className="ep-card-button"/>
                            <div className="ep-card-button"/>
                            <div className="ep-card-button"/>
    </div> 
                    </div>*/}
          <div>
            <Row>
              <Col span={24}>
                <PersonName
                  onChange={(firstName, lastName, same) => {
                    if (firstName && !same) {
                      handleChangeCard('firstName', firstName)
                    }

                    if (lastName && !same) {
                      handleChangeCard('lastName', lastName)
                    }
                  }}
                  messageFirst={messages.firstName ? messages.firstName.message : ''}
                  validateFirstStatus={messages.firstName ? messages.firstName.validateStatus : null}
                  messageLast={messages.lastName ? messages.lastName.message : ''}
                  validateLastStatus={messages.lastName ? messages.lastName.validateStatus : null}
                  firstNameErrors={messages.firstName ? true : false}
                  lastNameErrors={messages.lastName ? true : false}
                  firstName={''}
                  lastName={''}
                  firstNameValue={props.sameApplicantInfo ? props.applicantInfo.firstName : null}
                  lastNameValue={props.sameApplicantInfo ? props.applicantInfo.lastName : null}
                  sameApplicantInfo={props.sameApplicantInfo}
                />
              </Col>
            </Row>
            <CardData
              handleChangeCard={handleChangeCard}
              handleChangeCVC={handleChangeCVC}
              orderReducer={props.orderReducer}
              cvcErrors={messages.cvc ? true : false}
              yearErrors={messages.year ? true : false}
              monthErrors={messages.month ? true : false}
              messages={messages}
              validateCVCStatus={messages.cvc ? messages.cvc.validateStatus : null}
              messageCVC={messages.cvc ? messages.cvc.message : ''}
              validateYearStatus={messages.year ? messages.year.validateStatus : null}
              messageYear={messages.year ? messages.year.message : ''}
              validateMonthStatus={messages.month ? messages.month.validateStatus : null}
              messageMonth={messages.month ? messages.month.message : ''}
            />
          </div>
        </div>
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return {
    orderReducer: state.order.orderReducer,
    applicantInfo: state.order.orderReducer.applicantInfo,
    inspection: state.order.orderReducer.inspection,
    payment: state.order.orderReducer.payment,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrderInspection: (payload) => dispatch(actions.setOrderInspection(payload)),
    setOrderPayment: (payload) => dispatch(actions.setOrderPayment(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardInfo)
