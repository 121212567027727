import React from 'react'

import './index.scss'

const ListItem = ({ name = 'NO-NAME', location = 'NO-LOCATION', number = 'NO-NUMBER', isOpen, workTime = 'NO-TIME', onPress, id, isPicked }) => {
  return (
    <div id="list-item-container" onClick={() => onPress(id)} style={{ backgroundColor: isPicked ? '#fff' : 'whitesmoke' }}>
      <h1 id="bodyshop-name">{name}</h1>
      <small className="item-details">{location}</small>
      <small className="item-details">{number}</small>
      <div id="bodyshop-status-container">
        {isOpen !== null && (
          <strong id="bodyshop-status" style={{ color: isOpen ? 'green' : 'red' }}>
            {isOpen ? 'Open' : 'Closed'} <strong style={{ color: '#4f4f4f' }}>| {workTime}</strong>
          </strong>
        )}
      </div>
    </div>
  )
}

export default ListItem
