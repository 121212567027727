import React from 'react'
import './TextSelectCar.scss'

import TextField from '@material-ui/core/TextField'
import { Form } from 'antd'

const TextSelectCar = (props) => {
  return (() => {
    let errorJsx = null
    const inputClasses = ['ep-input-email']

    if (props.validateStatus && props.validateStatus === 'error') {
      errorJsx = (
        <section className="ep-error-message">
          <section>{props.message}</section>
        </section>
      )

      inputClasses.push('ep-error')
    }

    return (
      <section className={inputClasses.join(' ')}>
        <i className="ep-icon ep-icon-rect ep-icon-absolute" />
        <Form.Item className="email-inputSignIn">
          <TextField className="vin-input" id="mui-theme-provider-standard-input" label={props.placeholder} value={props.value} onChange={props.onChange} />
        </Form.Item>

        {errorJsx}
      </section>
    )
  })()
}

export default TextSelectCar
