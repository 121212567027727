import React, { useEffect, useState } from 'react'
import MyAccount from '../../myAccount'
import './contactSupport.scss'

import { Row, Col, Input, Button, notification, Select } from 'antd'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { sendMessage } from '../../../../../api/mail'
import { inspector } from 'api/inspector'

const ContactSupport = () => {
  const [email, setEmail] = useState(null)
  const [subject, setSubject] = useState('')
  const [text, setText] = useState('')
  const [buttonText, setButtonText] = useState('Send')

  useEffect(() => {
    inspector()
      .then((data) => {
        setEmail(data.data.email)
      })
      .catch((e) => console.log(e, '-error'))
  })

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }

  const handleSubject = (e) => {
    setSubject(e)
  }

  const send = () => {
    setButtonText('Please wait...')

    sendMessage(subject, text)
      .then(() => {
        notification.success({
          message: 'Successful',
          description: 'Thank you, your message was sent successfully',
          onClose: () => {
            setEmail('')
            setSubject('')
            setText('')
            setButtonText('Send')
          },
        })
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  return (
    <MyAccount>
      <section className="supp">
        <section className="supp-lines">
          <Row className="supp-row">
            <Col span="3">
              <button className="supp-descrip">From:</button>
            </Col>

            <Col span="21">
              <Input className="supp-input" disabled onChange={(e) => handleEmail(e, 'email')} value={email ? email : 'Email is loading...'} />
            </Col>
          </Row>

          <Row>
            <Col span="3">
              <button className="supp-descrip">Subject</button>
            </Col>
            <Col span="21">
              <Select
                dropdownStyle={{
                  backgroundColor: 'lightBlue',
                }}
                className="supp-select"
                placeholder="Please choose subject"
                onChange={(e) => handleSubject(e, 'subject')}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                <Select.Option value="Support">Support</Select.Option>
                <Select.Option value="Payment">Payment</Select.Option>
                <Select.Option value="General">General</Select.Option>
              </Select>
            </Col>
          </Row>
        </section>

        <Row className="supp-row">
          <Col span="24">
            <CKEditor
              editor={ClassicEditor}
              onChange={(event, editor) => {
                const data = editor.getData()
                setText(data)
              }}
              data={text}
            />
          </Col>
        </Row>

        <Row className="supp-btn">
          <Col span="24">
            <Button type="primary" onClick={send} disabled={buttonText === 'Please wait...'}>
              {buttonText}
            </Button>
          </Col>
        </Row>
      </section>
    </MyAccount>
  )
}

export default ContactSupport
