import React from 'react'
import { ReactComponent as CheckIcon } from '../../images/home/Check.svg'

const CheckItem = ({ children }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <CheckIcon height={20} width={20} />{' '}
      <div
        style={{
          marginLeft: '4px',
          fontFamily: 'Roboto Condensed',
          color: '#3f3f3f',
          fontSize: 14,
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default CheckItem