import React from 'react'
import './Footer.scss'

const Footer = () => {
  return (
    <div className="ep-order-footer">
      <div className="elementor-divider">
        <span className="elementor-divider-separator"></span>
      </div>
      <div className="ep-footer-container">
        <section className="elementor-element" data-id="e6ee12e" data-element_type="section">
          <div className="elementor-container">
            <div className="elementor-row">
              <div className="elementor-col-30">
                <div className="elementor-column-wrap  elementor-element-populated">
                  <div className="elementor-widget-wrap">
                    <div className="elementor-widget-image">
                      <div className="elementor-widget-container">
                        <div className="elementor-image">
                          <a href="https://caroinspection.com/">
                            <img width="2824" height="1290" src="https://caroinspection.com/wp-content/uploads/2020/01/caro_automotive.png" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-widget-spacer">
                      <div className="elementor-widget-container">
                        <div className="elementor-spacer">
                          <div className="elementor-spacer-inner"></div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-element elementor-element-77ef8a0 elementor-widget elementor-widget-text-editor">
                      <div className="elementor-widget-container">
                        <div className="elementor-text-editor elementor-clearfix">
                          <p>
                            9260 Towne Center Dr <br />
                            Suite 25
                            <br />
                            San Diego <br />
                            92121
                            <br />
                            <br />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-widget-heading copyright">
                      <div className="elementor-widget-container">
                        <div className="elementor-heading-title copyright-text">Caro Automotive LLC © Copyright 2020</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="elementor-col-20">
                <div className="elementor-column-wrap  elementor-element-populated">
                  <div className="elementor-widget-wrap">
                    <div className="elementor-element elementor-element-ccaa625 elementor-widget elementor-widget-heading">
                      <div className="elementor-widget-container">
                        <h4 className="elementor-heading-title elementor-size-default">Company</h4>
                      </div>
                    </div>
                    <div className="elementor-element elementor-element-fa7a6d5 elementor-widget elementor-widget-heading">
                      <div className="elementor-widget-container">
                        <span className="elementor-heading-title elementor-size-default">
                          <a href={`${process.env.REACT_APP_FRONTEND_URL}/services/`}>Services</a>
                        </span>{' '}
                      </div>
                    </div>
                    <div className="elementor-element elementor-element-78b02dc elementor-widget elementor-widget-heading">
                      <div className="elementor-widget-container">
                        <span className="elementor-heading-title elementor-size-default">
                          <a href={`${process.env.REACT_APP_FRONTEND_URL}/how-it-works/`}>How it Works</a>
                        </span>{' '}
                      </div>
                    </div>
                    <div className="elementor-element elementor-element-6e6e827 elementor-widget elementor-widget-heading">
                      <div className="elementor-widget-container">
                        <span className="elementor-heading-title elementor-size-default">
                          <a href={`${process.env.REACT_APP_FRONTEND_URL}/become-a-partner/`}>Become a partner</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="elementor-col-20">
                <div className="elementor-column-wrap  elementor-element-populated">
                  <div className="elementor-widget-wrap">
                    <div className="elementor-element elementor-element-a3b38dc elementor-widget elementor-widget-heading">
                      <div className="elementor-widget-container">
                        <h4 className="elementor-heading-title elementor-size-default">Contact Us</h4>
                      </div>
                    </div>
                    <div className="elementor-element elementor-element-b8d5f39 elementor-widget elementor-widget-heading">
                      <div className="elementor-widget-container">
                        <span className="elementor-heading-title elementor-size-default">
                          <a href={`${process.env.REACT_APP_FRONTEND_URL}/contact/`}>Contact Us</a>
                        </span>{' '}
                      </div>
                    </div>
                    <div className="elementor-element elementor-element-c41c5a2 elementor-widget elementor-widget-heading">
                      <div className="elementor-widget-container">
                        <span className="elementor-heading-title elementor-size-default">
                          <a href={`${process.env.REACT_APP_FRONTEND_URL}/privacy-policy/`}>Privacy Policy</a>
                        </span>{' '}
                      </div>
                    </div>
                    <div className="elementor-element elementor-element-934f20f elementor-widget elementor-widget-heading">
                      <div className="elementor-widget-container">
                        <span className="elementor-heading-title elementor-size-default">
                          <a href={`${process.env.REACT_APP_FRONTEND_URL}/termsandconditions/`}>Terms of Use</a>
                        </span>{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="elementor-col-20">
                <div className="elementor-column-wrap  elementor-element-populated">
                  <div className="elementor-widget-wrap">
                    <div className="elementor-element elementor-element-48b2d6c elementor-widget elementor-widget-heading">
                      <div className="elementor-widget-container">
                        <h4 className="elementor-heading-title elementor-size-default">Follow Us</h4>
                      </div>
                    </div>
                    <div className="elementor-element elementor-element-d359ffe elementor-shape-square elementor-widget elementor-widget-social-icons">
                      <div className="elementor-widget-container">
                        <div className="elementor-social-icons-wrapper">
                          <a
                            className="elementor-icon elementor-social-icon elementor-social-icon-facebook-f elementor-repeater-item-40063b8"
                            href="#"
                            target="_blank"
                          >
                            <span className="elementor-screen-only">Facebook-f</span>
                            <i className="fab fa-facebook-f"></i>{' '}
                          </a>
                          <a
                            className="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-repeater-item-81c8dd3"
                            href="#"
                            target="_blank"
                          >
                            <span className="elementor-screen-only">Twitter</span>
                            <i className="fab fa-twitter"></i>{' '}
                          </a>
                          {/* <a className="elementor-icon elementor-social-icon elementor-social-icon-linkedin elementor-repeater-item-3ce561c"
                                                href="#" target="_blank">
                                                <span className="elementor-screen-only">Linkedin</span>
    <i className="fab fa-linkedin"></i> </a> */}
                          <a
                            className="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-repeater-item-4f7c666"
                            href="#"
                            target="_blank"
                          >
                            <span className="elementor-screen-only">Instagram</span>
                            <i className="fab fa-instagram"></i>{' '}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="elementor-row">
              <div className="elementor-element elementor-element-6434554 elementor-hidden-phone elementor-column elementor-col-20 elementor-inner-column">
                <div className="elementor-column-wrap">
                  <div className="elementor-widget-wrap"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
export default Footer
