import { Col, Row, Select, DatePicker } from 'antd'
import React from 'react'
import moment from 'moment'
const { RangePicker } = DatePicker

const Filter = ({ handleFilter, filter, handleFilterInput, filterInput, onCalendarChange, dateRange }) => {
  const generateRangeValue = (range) => {
    let values = []

    for (let key in range) {
      range[key] && values.push(moment(range[key]))
    }
    return values
  }

  return (
    <Row className="filter" gutter={[15, 0]}>
      <Col span="6">
        <Select style={{ width: '100%', textAlign: 'left' }} placeholder="Filter" onChange={(e) => handleFilter(e)} defaultValue={filter}>
          <Select.Option value="Appointment">Appointment</Select.Option>
          <Select.Option value="Date">Date</Select.Option>
          <Select.Option value="Vehicle">Vehicle</Select.Option>
          <Select.Option value="Package">Package</Select.Option>
          <Select.Option value="All">All</Select.Option>
        </Select>
      </Col>
      <Col span="10">
        {(filter === 'Appointment' || filter === 'Vehicle') && (
          <input className="important-input" placeholder={filter} value={filterInput} onChange={(e) => handleFilterInput(e)} error="Error" />
        )}
        {filter === 'Package' && (
          <Select style={{ width: '100%', textAlign: 'left' }} placeholder={filter} onChange={(e) => handleFilterInput(e)}>
            <Select.Option value="Pre  Purchase Standart">Pre Purchase Standart</Select.Option>
            <Select.Option value="Pre Purchase Advance">Pre Purchase Advance</Select.Option>
          </Select>
        )}
        {filter === 'Date' && <RangePicker format="MM/DD/YYYY" value={generateRangeValue(dateRange)} onChange={onCalendarChange} />}
      </Col>
    </Row>
  )
}

export default Filter
