import React from 'react'
import { Col, Form, Row, Select } from 'antd'

import './notifsFilter.scss'

const NotifsFilter = ({ filterValue, filtersArray, onChange }) => {
  return (
    <div>
      <Row className="filterRow">
        <Col span={24}>
          <Form.Item>
            <Select onChange={onChange} value={filterValue.label}>
              {filtersArray.map((filter, index) => (
                <Select.Option key={`filter-${index}`} value={filter.label}>
                  {filter.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

export default NotifsFilter
