import React from 'react'
import { Col, Row, Form, Button } from 'antd'
import './setting.scss'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import CaroPassword from 'components/inputs/caroPassword/CaroPassword'

const PasswordSettings = ({ inspectorData, form, messages, handleChangeInput, submitPassword, cancelInfo }) => {
  return (
    <Form className="inspector-form inspection" initialValues={inspectorData} form={form} style={{ marginBottom: '10px', padding: '0px 20px' }}>
      <Row>
        <Col span="24">
          <Row>
            <Col span="8">
              <h1 className="form-title">Password settings</h1>
            </Col>
          </Row>

          <Row gutter={[15, 0]}>
            <Col span="8">
              <FormControl name="old_password">
                <InputLabel htmlFor="old-password">Current password</InputLabel>
                <CaroPassword
                  id="old-password"
                  value={inspectorData.old_password}
                  onChange={(e) => handleChangeInput(e, 'old_password')}
                  error={messages?.old_password?.message !== ''}
                  helperText={messages?.old_password?.message}
                />
              </FormControl>
            </Col>

            <Col span="8">
              <FormControl name="password">
                <InputLabel htmlFor="new-password">New password</InputLabel>
                <CaroPassword
                  id="new-password"
                  value={inspectorData.password}
                  onChange={(e) => handleChangeInput(e, 'password')}
                  error={messages?.password?.message !== ''}
                  helperText={messages?.password?.message}
                />
              </FormControl>
            </Col>

            <Col span="8">
              <FormControl name="req_password">
                <InputLabel htmlFor="req-password">Confirm password</InputLabel>
                <CaroPassword
                  id="req-password"
                  value={inspectorData.req_password}
                  onChange={(e) => handleChangeInput(e, 'req_password')}
                  error={messages?.req_password?.message !== ''}
                  helperText={messages?.req_password?.message}
                />
              </FormControl>
            </Col>
          </Row>

          <Row style={{ marginBottom: 50 }} justify="end" gutter={[15, 0]}>
            <Col>
              <Button type="primary" onClick={() => cancelInfo('password')} className="setting-btn setting-btn-cancel">
                Cancel
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={submitPassword} className="setting-btn setting-btn-save">
                Save changes
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}

export default PasswordSettings
