import React from 'react'
import './PasswordSignIn.scss'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import PasswordField from 'material-ui-password-field'

const PasswordSignIn = (props) => {
  let errorJsx = null
  const inputClasses = ['input-email']

  if (props.errorMessage) {
    errorJsx = (
      <section className="ep-error-message error-msg-password">
        <section>{props.errorMessage}</section>
      </section>
    )

    inputClasses.push('ep-error')
  }

  return (
    <section className={inputClasses.join(' ')}>
      <i className="ep-icon ep-icon-key ep-icon-absolute"></i>
      <FormControl className="password" name="password">
        <InputLabel htmlFor="password">{props.placeholder}</InputLabel>
        <PasswordField id="password" onChange={props.onChange} />
      </FormControl>

      {errorJsx}
    </section>
  )
}

export default PasswordSignIn
