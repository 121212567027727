import React, { useEffect, useState } from 'react'
import { Table, Badge, Modal, Button } from 'antd'
import moment from 'moment'

import './currentAppointments.scss'
import MyAccount from '../../myAccount'
import ModalContent from './modalContent'
import { appointmentsHistoryCurrent, statuses } from '../../../../../api/customer'
import { getAppointment, getStatus } from '../../../../../api/appointment'
import WheelLoader from 'components/loaders/WheelLoader'
import { getPeriodString } from 'helper/periodHelper'
import noImage from 'images/inspection/noimage-car.jpeg'

const CurrentAppointments = () => {
  const [data, setData] = useState([])
  const [loadingData, setLoadingData] = useState(false)

  const [selectedAppointment, setSelectedAppointment] = useState({
    status: 0,
    src_car_info: null,
    date: '-',
    time: '-',
    technician: '-',
    technician_phone: '-',
    is_our_location: -1,
    location: {},
    note: '-',
    address: '-',
    photo: null,
    name: '-',
    report: '-',
  })

  const [visibleDetail, setVisibleDetail] = useState(false)

  const columns = [
    {
      title: 'Appointment',
      dataIndex: 'appointment',
      key: 'appointment',
      render: (text) => <>{text}</>,
    },

    {
      title: 'Technician',
      dataIndex: 'technician',
      key: 'technician',
      render: (text) => <>{text.length > 10 ? text.slice(0, 11) + '...' : text}</>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <Badge color={text === 'Awaiting Execution' ? 'green' : 'yellow'} text={text} />,
    },
    {
      title: 'Appointment сreation date',
      dataIndex: 'application_date',
      key: 'application_date',
      render: (text) => (
        <>
          <div className="div-1">{moment(text).format('YYYY/MM/DD')}</div>
          <div className="div-2">{moment(text).format('h:mm A')}</div>
        </>
      ),
    },
    {
      title: 'Inspection date',
      dataIndex: 'date_time',
      key: 'date_time',
      render: (text) => (
        <>
          <div>{text.date}</div> <div>{text.time}</div>
        </>
      ),
    },
    {
      title: 'Detail',
      dataIndex: 'view',
      key: 'view',
      render: (id) => (
        <Button type="dashed">
          <a href="#" onClick={() => handleView(id)}>
            Click for Details
          </a>
        </Button>
      ),
    },
  ]

  useEffect(() => {
    setLoadingData(true)

    appointmentsHistoryCurrent()
      .then((data) => {
        const rows = []

        data.data.map((row, index) => {
          let inspectionTime = ''

          if (row.inspection_time_from && row.inspection_time_end) {
            inspectionTime = getPeriodString(row.inspection_time_from, row.inspection_time_end)
          } else {
            inspectionTime = moment(row.inspection_date_time).format('hh:mm A')
          }

          rows.push({
            appointment: row.id,
            technician: row.technician ? `${row.technician.user.first_name} ${row.technician.user.last_name}` : '-',
            application_date: row.application_date,
            date_time: {
              date: moment(row.inspection_date_time).format('DD MMM YYYY'),
              time: inspectionTime,
            },
            status: row.status === 4 ? statuses[1] : statuses[row.status],
            view: row.id,
            key: index,
          })
        })

        const rowsReverse = [...rows].reverse()

        setData(rowsReverse)
        setLoadingData(false)
      })
      .catch(() => setLoadingData(false))
  }, [])

  const handleView = (id) => {
    setVisibleDetail(true)
    getAppointment(id).then((response) => {
      let technician = '-'
      let technician_phone = '-'
      let company = '-'
      let address = '-'
      let photo = null
      let name = '-'

      // if (response.data.inspection_time_from && response.data.inspection_time_end) {
      //   inspectionTime = getPeriodString(response.data.inspection_time_from, response.data.inspection_time_end)
      // } else {
      //   inspectionTime = moment(response.data.inspection_date_time).format("hh:mm A")
      // }

      if (response.data.technician) {
        technician = response.data.technician.user.first_name + ' ' + response.data.technician.user.last_name
        technician_phone = response.data.technician.user.phone
      }

      if (response.data.company) {
        company = response.data.company.name
      }

      if (response.data.is_our_location === 1 && response.data.technician) {
        address = response.data.technician.city + ', ' + response.data.technician.street
      } else {
        address = response.data.location.address
      }

      if (response.data.src_car_info) {
        photo = response.data.src_car_info?.photos[0]?.url || noImage
        name =
          response.data.src_car_info.attributes.make +
          ' ' +
          response.data.src_car_info.attributes.model +
          ' ' +
          response.data.src_car_info.attributes.year +
          ' ' +
          `(${response.data.src_car_info.attributes.type})`
      }

      const data = {
        status: getStatus(response.data.status),
        src_car_info: response.data.src_car_info,
        date: moment(response.data.application_date).format('MM/DD/YYYY'),
        created_at: moment(response.data.created_at).format('MM/DD/YYYY HH:mm A'),
        time:
          response.data.inspection_time_from === null
            ? moment(response.data.inspection_date_time).format('HH:mm A')
            : getPeriodString(response.data.inspection_time_from, response.data.inspection_time_end),
        technician: technician,
        technician_phone: technician_phone,
        company: company,
        is_our_location: -1,
        location: {},
        address: address,
        note: response.data.note ? response.data.note : '-',
        photo: photo,
        name: name,
      }

      setSelectedAppointment(data)
    })
  }

  return (
    <>
      <MyAccount>
        {loadingData ? (
          <WheelLoader />
        ) : (
          <Table
            pagination={{ pageSize: 7 }}
            columns={columns}
            dataSource={data}
            onRow={() => {
              return {
                onClick: () => {}, // click row
                onDoubleClick: () => {}, // double click row
                onContextMenu: () => {}, // right button click row
                onMouseEnter: () => {}, // mouse enter row
                onMouseLeave: () => {}, // mouse leave row
              }
            }}
            className="current-table"
          />
        )}

        <Modal
          className="ap-modal"
          visible={visibleDetail}
          onOk={() => setVisibleDetail(false)}
          onCancel={() => setVisibleDetail(false)}
          footer={[
            <Button key="submit" type="primary" onClick={() => setVisibleDetail(false)}>
              Close
            </Button>,
          ]}
        >
          <ModalContent selectedAppointment={selectedAppointment} />
        </Modal>
      </MyAccount>
    </>
  )
}

export default CurrentAppointments
