import React from 'react'
import { useLocation } from 'react-router-dom'
import './Steps.scss'

const inspectionRoutes = ['/order/inspection/select-car', '/order/location/select-location-type', '/order/location/select-shop']
const packageRoutes = ['/order/inspection/select-package']
const paymentRoutes = ['/order/payment']

const Steps = () => {
  const { pathname } = useLocation()

  return (
    <ul className="ep-order-steps-container" id="progress">
      <li className={`ep-order-step step-${inspectionRoutes.includes(pathname) ? 'active' : ''}`}>Inpection</li>
      <li className={`ep-order-step step-${packageRoutes.includes(pathname) ? 'active' : ''}`}>Package</li>
      <li className={`ep-order-step step-${paymentRoutes.includes(pathname) ? 'active' : ''}`}>Payment</li>
    </ul>
  )
}

export default Steps
