import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import MobileMenu from './MobileMenu'
import logo from '../images/logo.jpg'
import { getAuthData } from '../helper/auth'
import actions from 'store/order/actions'
import './Container.scss'

const Header = (props) => {
  const [auth, setAuth] = useState({})
  const history = useHistory()

  useEffect(() => {
    setAuth(getAuthData())
  }, [])

  const logout = (e) => {
    e.preventDefault()
    props.setOrderApplicantInfo({
      firstName: null,
      lastName: null,
      email: '',
      phone: null,
    })
    localStorage.removeItem('auth')
    history.push('/sign-in')
  }

  return (
    <Layout.Header>
      <div className="logo-menu">
        <div className="caro-logo">
          <a href={`${process.env.REACT_APP_FRONTEND_URL}/`}>
            <img src={logo} alt="Logo" />
          </a>
        </div>
        <MobileMenu auth={auth} />
        <div className="ep-menu ourMenu">
          <ul className="nav">
            <li>
              <a href={`${process.env.REACT_APP_FRONTEND_URL}/services/`}>Services</a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_FRONTEND_URL}/how-it-works/`}>How it Works</a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_FRONTEND_URL}/become-a-partner/`}>Become a Partner</a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_FRONTEND_URL}/faq/`}>FAQ</a>
            </li>
            <li>
              <a href={`${process.env.REACT_APP_FRONTEND_URL}/contact/`}>Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="elementor-widget-wrap ourMenu">
        {(auth?.customer || !auth) && (
          <a href="/order/inspection/select-car" className="elementor-button-link" role="button">
            <span className="elementor-button-text">Request an Inspection</span>
          </a>
        )}
        <a
          href={auth?.customer ? '/customer/my-account/current-appointments' : '/inspector/my-account/statistic'}
          className="elementor-button-link"
          role="button"
        >
          <span className="elementor-button-text">My Account</span>
        </a>
        {(auth?.customer || auth?.technician) && (
          <a href="#" className="elementor-button-link" onClick={logout}>
            Log Out
          </a>
        )}
      </div>
    </Layout.Header>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrderApplicantInfo: (payload) => dispatch(actions.setOrderApplicantInfo(payload)),
  }
}

export default connect(null, mapDispatchToProps)(Header)
