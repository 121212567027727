import React from 'react'
import Container from '../Container'
import { Row, Col } from 'antd'

import './styles.scss'

const TermsOfUse = () => {
  return (
    <Container>
      <Row>
        <Col span={24}>
          <div className="page-header-title">Terms of Use</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            Tinted windows may be cool in a car, but when it comes to our terms and conditions, we believe in complete, untinted visibility.
            <br />
            <br />
            So we took some Windex and a roll of paper towel - along with a special software program that scrubs a document of legalese - and created a &quot;Terms
            of Use&quot; document that meets Caro&apos;s own terms for clarity and simplicity.
            <br />
            <br />
            If you have any questions, please give us a call at (888) 542-3374 or contact us. If you don&apos;t have any questions, but simply want to heap praise on
            us for how joyful Caro makes you, feel free to do that, too.
            <br />
            <br />
            <b>Caro Terms & Conditions of Use (effective November 1,2014)</b>
            <br />
            Welcome to Caro.com, which is the website for Caro, Inc. (&quot;Caro&quot;, &quot;we&quot;, &quot;us&quot; or &quot;our&quot;).The terms and conditions set forth herein are hereinafter
            referred to as the &quot;Agreement&quot; and govern all use of the Caro.com website and mobile application and all content, services, features, activities and
            products available at or through the website or mobile application (collectively, the &quot;Services&quot;). This Agreement sets forth the legally binding
            terms and conditions for your use of the website, mobile application, and the Services.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">1. Summary</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            Caro is a service that primarily facilitates sales between private sellers and buyers. As a buyer, Caro offers you many services and protections,
            which are described further throughout this Agreement. For example, all vehicles offered for sale to buyers have undergone Caro&apos;s multipart
            pre-purchase vehicle inspection and meet the safety, registration, and smog requirements imposed by California upon delivery. Subject to certain
            restrictions, all Caro vehicles come with a 10-day money back guaranty so that you can return a vehicle for any reason and receive a full refund.
            <br />
            <br />
            Caro offers many services to sellers as well. In particular, Caro will professionally inspect and photograph your vehicle before it is listed. Once
            a buyer agrees to purchase your vehicle, Caro will pick up the vehicle, pay you, and handle delivery to the buyer. You will retain that price even
            if a buyer returns the vehicle to Caro for any reason.
            <br />
            <br />
            These features are offered subject to certain restrictions, which are described further throughout this Agreement. Moreover, your use of Caro&apos;s
            Services imposes certain obligations on you, which are described further below. Caro strongly encourages you to read this Agreement in its entirety
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">2. General Provisions</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            <b>A. Registration / Modification</b>
            <br />
            By registering for and/or using the Services in any manner, including but not limited to visiting or browsing the Caro.com website, you agree that
            you have read and accepted the terms and conditions of this Agreement. The Agreement consists of all terms and conditions set forth in the
            Agreement, as well as those set forth in Caro&apos;s Privacy Policy (https://www.Caro.com/legal/privacy) and any Retail Installment Contract entered into
            between Caro, on the one hand, and a buyer, on the other hand, all of which are incorporated by reference herein in their entirety. All Services
            available at any time on this website are subject to this Agreement. Caro reserves the right to modify this Agreement at any time. Any such
            modification will be evidenced by a new effective date. All users of this website, including those who have previously acknowledged their acceptance
            of the Agreement, will be notified of any modification. Caro may, in its unilateral discretion, change, restrict access to, suspend, or discontinue
            this website, or any portion thereof.
            <br />
            <br />
            <b>B. Intended Use</b>
            <br />
            This website is intended for use only by residents of the United States of America (excluding unincorporated territories, Puerto Rico and Guam),
            eighteen (18) years of age or older, who have the capacity to enter into a valid contract; by accepting this Agreement, you represent and affirm
            that you meet these requirements. If you are accepting this Agreement on behalf of an organization, you represent that you have the authority to do
            so.
            <br />
            In compliance with the Children&apos;s Online Privacy Protection Act, Caro does not knowingly or intentionally solicit or collect information from
            minors, and the Services are not directed at minors.
            <br />
            <br />
            <b>C. Arbitration and Class Action Waiver</b>
            <br />
            In accordance with the terms set forth in Section 10 below, you agree that disputes between you and us will be resolved by binding, individual
            arbitration and you waive your right to participate in a class action lawsuit or class-wide arbitration.
            <br />
            <br />
            <b>D. Sale By Private Sellers / Non-Interference</b>
            <br />
            This website serves primarily to facilitate sales between private sellers and buyers of used vehicles. With certain exceptions addressed at Section
            4(A) below, the vehicles offered for sale on the website are offered for sale by the private seller, and not by Caro. By accepting this Agreement,
            you represent and affirm that you will not (i) communicate directly with buyers and/or sellers regarding the listed vehicles; (ii) recruit, solicit,
            or encourage any other user to use third party services or websites that are competitive to Caro; (iii) use the website or mobile application to
            find a buyer or listed vehicle and then complete a transaction independent of the website, mobile application, or Services in order to circumvent
            the obligation to pay any fees related to the Services; or (iv) otherwise interfere with vehicle listings without authorization from Caro.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">3. Access and Use of Services</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            In order to use this website and access the Services, you will be required to register for an account on the Caro website or mobile application (the
            “Account”). You must provide accurate information and keep your Account information updated. You shall not: (i) select or use as a username a name
            of another person with the intent to impersonate that person; (ii) use as a username a name subject to any rights of a person other than you without
            appropriate authorization; or (iii) use, as a username, a name that is otherwise offensive, vulgar or obscene. You are solely responsible for the
            activity that occurs on your Account, and for keeping your Account password secure. You may never use another person’s user account or registration
            information for the Services without permission. You must notify us immediately of any change in your eligibility to use the Services, breach of
            security or unauthorized use of your Account. By registering an Account using your Facebook login credentials, you give us permission to use and
            publish Facebook information in accordance with the settings you have selected with us and with Facebook.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">4. Buying and Selling Vehicles</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            Caro is licensed by the California Department of Motor Vehicles as a used car dealer. As explained more fully below, once a buyer has agreed to
            purchase a particular vehicle offered for sale on this website, Caro purchases that vehicle from the seller (if Caro does not otherwise own the
            vehicle). Thereafter, Caro delivers the vehicle, title and, within California, registration, to the buyer. At the time of delivery, (i) Caro gives
            all required disclosures and notifications to the buyer, (ii) Caro and the buyer mutually execute all required purchase and sale documents, and
            (iii) buyer pays Caro for the vehicle.
            <br />
            <br />
            We may use a third-party payment processor (the “Payment Processor”) to bill you or compensate you through a payment account linked to your Caro
            account. The processing of payments will be subject to the terms, conditions, and privacy policies of the Payment Processor in addition to this
            Agreement. We are not responsible for error by the Payment Processor. By choosing to consummate a transaction with Caro, you agree to pay us,
            through the Payment Processor, all charges in accordance with the applicable payment terms and you authorize us, through the Payment Processor, to
            charge or credit your chosen payment provider.
            <br />
            <br />
            <b>A. Selling a Vehicle on this Website</b>
            <br />
            With certain exceptions addressed at Section 4(B) below, the vehicles listed for sale on this website are advertised and offered for sale by private
            sellers, and not by Caro. To list a vehicle for sale on the Caro website, the seller must have possession of the vehicle to be sold, and (i) the
            ability to transfer title, or (ii) if there is a lien on the vehicle, must have advised Caro of the lien and must have provided Caro with the
            information Caro would need to satisfy the lien. Seller acknowledges that if he or she provides Caro with any false, incomplete, or inaccurate
            information, including but not limited to vehicle or personal information, any agreement entered into between seller and Caro for the purchase of a
            vehicle by Caro is null and void and of no force of effect and seller will be responsible to compensate Caro for any costs, losses or damages
            resulting from any false, incomplete, or inaccurate information.
            <br />
            In order to list a vehicle for sales on this website, the website will ask you, as a prospective seller, to provide basic information regarding the
            vehicle, including make, model, year, style and mileage. You will then be asked to schedule an inspection with a Caro inspector, and to fill in
            certain identifying information, including your name and the home or office address at which the vehicle will be inspected. During the registration
            process, Caro will provide you, as a prospective seller, with the the price Caro will pay you for the vehicle if a buyer agrees to buy it (the
            &quot;Guaranteed Price&quot;).
            <br />
            Once you have filled in the required information and scheduled an inspection, Caro will send one of its inspectors to your home or office to
            photograph the vehicle inside and out, and to conduct a pre-purchase inspection, including a test drive of the vehicle. The inspector will also
            verify the vehicle’s make, model, year, style and mileage, and title and registration, and confirm that the seller is the owner of the vehicle. The
            inspection will be conducted by the Caro inspector in accordance with Caro’s multipart pre-purchase vehicle inspection process.
            <br />
            Upon completing the inspection, if Caro chooses to allow you to advertise and offer your vehicle for sale on Caro.com, Caro will, within two (2)
            business days, email you the Guaranteed Price. Prices are fixed and not subject to negotiation, and are based on a variety of factors, including the
            market value of the vehicle at the time of inspection as assessed by a third-party appraisal. Caro will provide a prospective seller with a trade in
            value for the vehicle that takes into account the results of Caro’s inspection. Caro is paid a commission in the event a vehicle is sold. The sale
            price advertised on Caro.com is the Guaranteed Price plus Caro&apos;s comission in the event the car is sold. Caro’s commission is determined on a
            car-by-car basis, and takes into consideration the value of the car at the time of inspection. Caro’s commission is not negotiable.
            <br />
            Once the vehicle passes inspection, if the private seller decides to advertise and offer his or her vehicle for sale on the website, the vehicle
            will be advertised and offered for sale on the website by the private seller at the Guaranteed Price plus Caro&apos;s commission. In offering a vehicle
            for sale on this website, the seller (i) represents that he or she has physical possession of the vehicle to be sold and either (a) that he or she
            has the ability to transfer title, or (b) that he or she has fully disclosed any lien on the vehicle to Caro, and has provided Caro with sufficient
            information to enable Caro to satisfy the lien, and (ii) agreesw that, unless or until seller advises Caro that the vehicle has been sold, or to
            cancel his or her listing on the website, the vehicle is available for sale and, as long as the seller is paid the Guaranteed Price, seller will
            consummate the sale transaction. The seller is free to continue to use the vehicle unless or until a buyer agrees to purchase the vehicle and a sale
            to that buyer is consummated. The seller may also continue to list the vehicle for sale through other outlets, but it is the seller’s responsibility
            to inform Caro immediately if the vehicle is sold. It is also the seller’s responsibility, to inform Caro of any times during which the vehicle will
            be unavailable for pickup. For example, the seller must notify Caro if he or she is going on vacation and will be unavailable to transfer title and
            possession while away.
            <br />
            <br />
            Caro may revise the Guaranteed Price in the event of any post-inspection damage. In such a situation, Caro may – but is not required to – make the
            seller a new offer on different terms. It is the seller’s responsibility to inform Caro immediately if any damage occurs to the vehicle after the
            inspection by contacting help@Caro.com. Should the seller fail to timely inform Caro of such post-listing damage, Caro may, at its sole discretion,
            remove the listing for the vehicle from this website. The seller also agrees to reimburse Caro for any costs, losses, or damages suffered as a
            result, including but not limited to the cost of repair and the cost of any necessary inspection or re-inspection. The nature and extent of any
            post-incident damage will be determined solely by Caro, or a third-party working on Caro’s behalf.
            <br />
            <br />
            If a buyer agrees to purchase a vehicle advertised and offered for sale on the website, Caro will, as soon as reasonably practicable, (1) secure the
            vehicle title from the seller, (2) secure all necessary signatures from the seller to accomplish transfer of title, (3) pay the seller for the
            vehicle by way of a check issued by Caro, and (4) pick up the vehicle from the seller. Caro may remove the seller’s listing from this website if
            Caro cannot secure from the seller the title, the vehicle, or the necessary signatures. Assuming the car has not suffered any post-inspection
            damage, the price paid by Caro to the seller will be the Guaranteed Price. Should the buyer return the vehicle to Caro for any reason, Caro will
            retain title and possession of the vehicle and the seller shall retain the price paid to seller. To the extent such a returned vehicle is offered
            for sale on this website, it will be offered by sale by Caro. Caro may also offer for sale on this website vehicles on its premises.
            <br />
            <br />
            Caro cannot guarantee that any vehicle listed on the website will be sold. Nor can Caro guarantee the length of time it will take for any car to
            sell. Caro may, in its sole discretion, remove a listing from this website if the car is not sold within thirty (30) days . Alternatively, Caro may
            make a new offer to the seller at a revised price point. Caro agrees to purchase any vehicle that is not sold within thirty (30) days of it is
            initial posting on the website, at a price determined in Caro&apos;s sole discretion.
            <br />
            <br />
            <b>B. Buying a Vehicle on this Website</b>
            <br />
            Following registration for access and use of the Services, a prospective buyer is free to browse the website. For each vehicle offered for sale, the
            website displays professional photographs and certain characteristics of the vehicle. The photographs displayed are not exemplars, but photos of the
            actual vehicle offered for sale.
            <br />
            <br />
            Each of the vehicles offered for sale on this website is certified by Caro pursuant to its multistep pre-purchase certification process. For each
            vehicle offered for sale, the website displays a completed inspection report reciting the steps conducted in the certification process, including
            each of the components inspected, and the status of the vehicle with respect to each step and component.
            <br />
            <br />
            With respect to any vehicle Caro sells, Caro will have complied with all requirements of the California Vehicle Code with respect to (1) checking
            and making operational certain vehicle parts, (2) odometer disclosure, and (3) smog inspection. Caro will also make all disclosures required under
            California and federal law, including under the Federal Buyer’s Guide and California Vehicle Code section 9990. Caro will publish on this website
            any third party report it has secured with respect to an individual vehicle. Caro disclaims any responsibility for the accuracy of any such report.
            <br />
            <br />
            The sale price at which the seller offers a vehicle for sale on this website is fixed and not subject to negotiation. The prices on the website
            include title and registration for California residents, the cost of delivery within a 100 mile radius of Palo Alto, California, California smog and
            document fees and Caro’s commission. Prices on the website do not include sales or usage tax, delivery outside of a 100 mile radius of Palo Alto,
            California, non-California title and registration fees, financing charges or extra purchases, including but not limited to an extended warranty.
            <br />
            <br />
            At the time a vehicle (along with title and, within California, registration) is delivered to the buyer for consummation of a sale, the buyer and
            Caro will mutually execute a Retail Installment Contract memorializing the terms of the sale. A buyer can pay for the vehicle by check, credit card,
            or bank transfer. Payment is due at the time of executing the Retail Installment Contract, and the buyer’s account will be charged immediately. All
            vehicles delivered to a buyer will be free and clear of any liens previously attached to the vehicle. At the time of delivery, Caro will give all
            required notifications and disclosures.
            <br />
            <br />
            While Caro expects to be able to offer delivery within several days of most purchases, there are certain circumstances outside of Caro’s control
            that may delay delivery. For example, if the seller is out of town, Caro may be unable to obtain the vehicle until the seller returns. Accordingly,
            Caro cannot offer a guaranteed delivery timeframe at the time of purchase.
            <br />
            <br />
            Each vehicle sold by Caro comes with a 10-day money back guaranty. This means that, as long as (i) the vehicle has not been driven more than a total
            of 1,000 miles or 100 miles for each day the vehicle was in buyer’s possession, whichever is less (the “Mileage Limit”), and (ii) the vehicle is in
            the same condition it was in at the time of delivery (collectively the “Money Back Guaranty Conditions”), a buyer can, within 10 days of delivery,
            return the vehicle to Caro and receive a full refund. As long as the Money Back Guaranty Conditions are satisfied, the buyer may return the vehicle
            for any reason. Caro does not offer partial refunds and will not renegotiate the purchase price if the buyer is not satisfied with the vehicle. In
            the event the buyer is not satisfied with the vehicle for any reason, the buyer may, as long as the Money Back Guaranty Conditions are satisfied,
            return the vehicle within ten days of purchase and, subject to excess mileage charges described below, receive a full refund.
            <br />
            <br />
            To the extent the vehicle has been driven beyond the Mileage Limit, the refund given to the buyer will be reduced by $3.00 (three dollars) per mile.
            To the extent the vehicle has been damaged or is otherwise in worse condition than it was at the time of delivery, Caro, in its sole discretion, may
            refuse to refund any portion of the purchase price, or may choose to reduce the refunded amount by the amount required to return the vehicle to its
            condition at the time of delivery to buyer. The amount of the deduction will be determined by Caro in its sole discretion, and Caro will provide an
            accounting to the buyer reflecting the amount of any deductions and the reason for each deduction. In the event Caro refunds the purchase price to a
            buyer, Caro, as the owner of that vehicle, may itself offer the vehicle for sale on this website.
            <br />
            <br />
            All Caro vehicles come with the 10-day money back guarantee described above.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">5. User Generated Content</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            <b>A. Prohibited Actions</b>
            <br />
            <br />
            You are solely responsible for your communications on and your use of the Services. You agree not to do any of the following: (A) post or transmit
            any libelous, defamatory, indecent, obscene, fraudulent, deceptive, abusive or pornographic message, data, image, content or program; (B) post or
            transmit any message, data, image, content or program that would violate any property rights of others; (C) use the Services to threaten, harass, or
            otherwise violate the legal rights (including rights of privacy and publicity) of others; (D) upload or download files that contain software, marks,
            logos, data, images, content or other material protected by intellectual property laws, rights of privacy or publicity, or any other applicable law,
            unless you own or control the rights to such files or material or have received all necessary consents; (E) upload files that contain a virus or
            corrupted data; (F) falsify the source or origin of software or other material contained in a file that you upload to the Services; (G) falsely
            purport to be an employee or agent of Caro; (H) act, in your use of the Services, in a manner that is contrary to applicable law or regulation; or
            (I) circumvent, disable or otherwise interfere with security-related features of the Services or features that prevent or restrict use or copying of
            any content or enforce limitations on use of the Services or the content.
            <br />
            <br />
            <b>B. Caro’s Exclusive Right to Manage the User Submissions</b>
            <br />
            <br />
            You acknowledge that any materials and other information (including, without limitation, ideas contained therein for new or improved products or
            services) you post, upload, or submit to the Services, including but not limited to comments, forum messages, reviews, text, video, audio,
            photographs, computer code and applications (each, a “User Submission”) may be edited, removed, deleted, modified, published, transmitted, and
            displayed by Caro in its sole discretion and without your permission, and you waive any rights you may have (including any moral rights) in
            preventing the material from being altered or changed in a manner not agreeable to you. You expressly agree that we may remove, disable or restrict
            access to or the availability of any User Submissions from the Services at any time, for any reason or for no reason at all. Caro reserves the right
            to treat User Submissions as content stored at the direction of users for which Caro will not exercise control except to block or remove content
            that comes to Caro’s attention and is offensive, obscene, lewd, lascivious, filthy, violent, harassing, threatening, abusive, illegal, libelous,
            defamatory, fraudulent, deceptive, misleading or otherwise objectionable to Caro, or to enforce the rights of third parties or the content
            restrictions set forth below in this Agreement when notice of their violation comes to Caro’s attention. However, Caro shall not be responsible for
            controlling or editing any content, and Caro has no contractual obligation to remove inappropriate or unlawful content. Under no circumstances will
            we be held liable for removing, disabling or restricting access to or the availability of content.
            <br />
            <br />
            <b>C. License to Caro of Your User Submissions</b>
            <br />
            <br />
            You hereby grant to Caro, and you agree to grant to Caro, a perpetual, royalty-free, non-exclusive, irrevocable, unrestricted, unconditional,
            unlimited, worldwide and cost-free license to use, copy, record, disclose, sell, re-sell, sublicense, reproduce, distribute, redistribute, modify,
            adapt, publish, edit, translate, transmit, create derivative works of, broadcast, publicly perform, display or otherwise exploit in any manner
            whatsoever, all or any portion of your User Submissions (and derivative works thereof), for any purpose whatsoever in all formats, on or through any
            media, software, formula, or technology whether by any means and in any media now known or hereafter developed and to sublicense such rights through
            multiple tiers of sublicenses, and to advertise, market and promote the same. In order to further effect the rights and license that you grant to
            Caro to your User Submissions, you also hereby grant to Caro, and agree to grant to Caro, the unconditional, perpetual, irrevocable right to use and
            exploit your name, persona, image, photograph, and likeness that you provide in connection with any User Submission, without any obligation or
            remuneration to you. Except as prohibited by law, you hereby waive, and you agree to waive, any moral rights (including attribution and integrity)
            that you may have in any User Submissions, even if your User Submissions are altered or changed in a manner not agreeable to you. You agree that you
            shall have no recourse against Caro for any alleged or actual infringement or misappropriation of any proprietary right in your User Submissions.
            You further acknowledge and agree that no compensation will be paid with respect to the use of your User Submissions or any of the rights granted in
            this section.
            <br />
            <br />
            <b>D. Representations and Warranties Related to Your User Submissions</b>
            <br />
            <br />
            Each time you submit a User Submission, you represent and warrant that, as to that User Submission, (A) you are the sole author and owner of the
            intellectual property and other rights to the User Submission, or you have a lawful right to submit the User Submission and grant Caro the rights to
            it that you are granting by this Agreement, all without any obligation to obtain consent of any third party and without creating any obligation or
            liability of Caro; (B) the User Submission is accurate; (C) the User Submission does not and, as to Caro’s permitted uses and exploitation set forth
            in this Agreement, will not infringe any intellectual property or other right of any third party; (D) the User Submission will not violate this
            Agreement, or cause injury or harm to any person; and (E) the User Submission complies with all applicable laws and regulations.
            <br />
            <br />
            <b>E. Responsibility of Users</b>
            <br />
            <br />
            You are entirely responsible for the content of, and any harm resulting from, your User Submissions. Caro does not assume any responsibility or
            liability for any User Submissions posted on the Services or any website linked to the Services and makes no express or implied warranty or
            guarantee about the accuracy, copyright compliance, legality, or any other aspect of the User Submissions.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">6. Accuracy of Website Information</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            Caro designed this website to meet your used car purchase and sale needs by providing you with accurate and up-to-date information about Caro&apos;s
            inventory. Despite our best efforts, however, it is inevitable that some inaccuracies may be present. Additionally, photographs of automobiles found
            on the Caro website are photographs of the actual vehicles; however, their color and general appearance may appear differently based on your monitor
            and color settings. Caro will not be responsible for errors found on this website, including but not limited to pricing errors or an incorrect
            statement of accessories on a particular vehicle.
            <br />
            <br />
            Caro reserves the right to change product pricing without notice.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">7. Ownership and Proprietary Rights</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            All information contained in this website, unless otherwise stated, is owned solely and exclusively by Caro. You acknowledge and agree that all
            contents and materials available on this website are protected by copyrights, trademarks, service marks, patents, trade secrets, and/or other
            proprietary or intellectual property rights and laws. By permitting you to access and use this website, Caro has not granted you a license of any
            kind to use this information for any purpose (including, but not limited to, commercial purposes) without the express written consent of Caro;
            however, vehicle information may be printed for your non-commercial, personal use only. You may not otherwise amend, copy, create derivative works
            from, reverse engineer, display, distribute, edit, license, modify, reproduce, rent, sell, and/or transmit any part of this website. You may not use
            any meta tags or any other &quot;hidden text&quot; utilizing Caro&apos;s name or trademarks without the express written consent of Caro. In addition, deep linking,
            direct linking, framing, page-jacking, spoofing, hacking, data-mining and using a robot, spider, or automated device of any kind to monitor or copy
            our website is strictly prohibited.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">8. Intellectual Property</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            Caro is a registered trademark or service mark of Caro, Inc. Any unauthorized use, replication, or other violations of trademark law will be
            prosecuted to the maximum extent possible. All other trademarks not owned by Caro or its subsidiaries that appear on this site are property of their
            respective owners, who may or may not be affiliated with, connected to, or sponsored by Caro.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">9. Warranty Disclaimer</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            Caro MAKES NO REPRESENTATIONS ABOUT THE SUITABILITY OF THE INFORMATION AND RELATED GRAPHICS CONTAINED IN THIS WEBSITE FOR ANY PURPOSE WHATSOEVER.
            ALL INFORMATION PROVIDED ON OUR WEBSITE IS PROVIDED &quot;AS IS&quot;, WITH ALL FAULTS AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. Caro
            DISCLAIMS ALL WARRANTIES, EXPRESS AND IMPLIED, INCLUDING, WITHOUT LIMITATION, THOSE OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
            NON-INFRINGEMENT AND ANY WARRANTIES ARISING FROM COURSE OF PERFORMANCE, COURSE OF DEALING, OR USAGE IN TRADE. Caro SHALL NOT BE LIABLE FOR ANY
            INDIRECT, SPECIAL, CONSEQUENTIAL, INCIDENTAL, OR PUNITIVE DAMAGES, INCLUDING, WITHOUT LIMITATION, LOST PROFITS OR REVENUES, COSTS OF REPLACEMENT OF
            GOODS, LOSS OR DAMAGE TO DATA ARISING OUT OF THE USE OR INABILITY TO USE THE Caro WEBSITE, OR FOR DAMAGE RESULTING FROM THE USE OF OR RELIANCE ON
            THE INFORMATION CONTAINED HEREIN. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS OR EXCLUSION OR LIMITATIONS ON RELIEF
            SUCH AS INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">10. Dispute Resolution / Jurisdiction / Agreement to Arbitrate</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            <b>A. In General</b>
            <br />
            <br />
            Certain portions of this section are deemed to be a “written agreement to arbitrate” pursuant to the Federal Arbitration Act. You and Caro agree
            that we intend that this section satisfies the “writing” requirement of the Federal Arbitration Act.
            <br />
            <br />
            <b>B. First -- Try to Resolve Disputes and Excluded Disputes. </b>
            <br />
            <br />
            If any controversy, allegation, or claim arises out of or relates to the Services, the content available through the Services, your User
            Submissions, or this Agreement (collectively, “Dispute”), then you and we agree to send a written notice to the other providing a reasonable
            description of the Dispute, along with a proposed resolution of it. Our notice to you will be sent to you based on the most recent contact
            information that you provide us. But if you have failed to provide us with this information or if such information is not current or accurate, then
            we have no obligation under this section. Your notice to us must be sent to: hello@Caro.com. For a period of 60 days from the date of receipt of
            notice from the other party, Caro and you will engage in a good faith dialogue in order to attempt to resolve the Dispute, though nothing will
            require either you or Caro to resolve the Dispute on terms with respect to which you and Caro, in each party’s sole discretion, are not comfortable.
            <br />
            <br />
            <b>C. Forums for Alternative Dispute Resolution</b>
            <br />
            <br />
            If we cannot resolve a Dispute as set forth in above within 60 days of receipt of the notice (or, where no notice address exists for you, after such
            Dispute arises), then either you or we may submit the Dispute to formal arbitration in accordance with this section.
            <br />
            <br />
            Upon expiration of the applicable 60-day period and to the fullest extent permitted by applicable law, a Dispute will be resolved solely by binding
            arbitration in accordance with the then current Commercial Arbitration Rules of the American Arbitration Association (“AAA”). If the Dispute has a
            claimed value of not more than $250,000, then the arbitration will be heard and determined by a single neutral arbitrator who is a retired judge or
            a lawyer with not less than 10 years’ experience as a practicing member of the bar in the substantive practice area related to the Dispute, who will
            administer the proceedings in accordance with the AAA’s Supplementary Procedures for Consumer Related Disputes. If the Dispute has a claimed value
            of more than $250,000, or if Caro elects in its sole discretion to bear the costs of arbitration in excess of those that would occur for a
            proceeding before a single neutral arbitrator, then the arbitration will be heard and determined by a three member panel, with one member to be
            selected by each party and the third (who will be chair of the panel) selected by the two party appointed members or by the AAA in accordance with
            the Commercial Arbitration Rules. The arbitrator or arbitration panel, as the case may be, will apply applicable law and the provisions of this
            Agreement, will determine any Dispute according to the applicable law and facts based upon the record and no other basis, and will issue a reasoned
            award.
            <br />
            <br />
            If a party properly submits the Dispute to the AAA for formal arbitration and the AAA is unwilling or unable to set a hearing date within 60 days of
            the filing of a “demand for arbitration,” then either party can elect to have the arbitration administered by the Judicial Arbitration and Mediation
            Services Inc. (“JAMS”) using JAMS’ streamlined Arbitration Rules and Procedures, or by any other arbitration administration service that you and an
            officer of Caro consents to in writing. The substantive practice area requirements for the arbitrator and the $250,000 threshold for the number of
            arbitrators assigned to the Dispute set forth in the paragraph above for the AAA arbitration will also apply to any such arbitration under JAMS or
            another arbitration service.
            <br />
            <br />
            You can obtain AAA and JAMS procedures, rules, and fee information as follows:
            <br />
            <br />
            AAA: (800) 778-7879 / http://www.adr.org/ JAMS: (949) 224-1810 / http://www.jamsadr.com
            <br />
            <br />
            <b>D. Nature, Limitations, and Location of Alternative Dispute Resolution</b>
            <br />
            <br />
            In arbitration, as with a court, the arbitrator must honor the terms of this Agreement and can award the prevailing party damages and other relief
            (including attorneys’ fees). However, WITH ARBITRATION (i) THERE IS NO JUDGE OR JURY, (ii) THE ARBITRATION PROCEEDINGS AND ARBITRATION OUTCOME ARE
            SUBJECT TO CERTAIN CONFIDENTIALITY RULES, AND (iii) JUDICIAL REVIEW OF THE ARBITRATION OUTCOME IS LIMITED. All parties to the arbitration will have
            the right, at their own expense, to be represented by an attorney or other advocate of their choosing. If an in-person arbitration hearing is
            required, then it will be conducted in San Mateo, California; but if the applicable arbitration rules or laws require the arbitration to be
            conducted in the “metropolitan statistical area” (as defined by the U.S. Census Bureau) where you are a resident at the time the Dispute is
            submitted to arbitration, Caro shall have the right to elect to proceed to arbitration in such location. You and we will pay the administrative and
            arbitrator’s fees and other costs in accordance with the applicable arbitration rules; but if applicable arbitration rules or laws require Caro to
            pay a greater portion or all of such fees and costs in order for this section to be enforceable, then Caro will have the right to elect to pay the
            fees and costs and proceed to arbitration. Discovery will be permitted pursuant to the applicable arbitration rules. The arbitrator’s decision must
            consist of a written statement stating the disposition of each claim of the Dispute, and must provide a statement of the essential findings and
            conclusions on which the decision and any award (if any) is based. Judgment on the arbitration decision and award (if any) may be entered in or by
            any court that has jurisdiction over the parties pursuant to Section 9 of the Federal Arbitration Act.
            <br />
            <br />
            <b>E. Limited Time to File Claims</b>
            <br />
            <br />
            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IF YOU OR WE WANT TO ASSERT A DISPUTE AGAINST THE OTHER, THEN YOU OR WE MUST COMMENCE IT (BY
            DELIVERY OF WRITTEN NOTICE AS SET FORTH ABOVE, WHERE FEASIBLE) WITHIN 1 YEAR AFTER THE DISPUTE ARISES -- OR IT WILL BE FOREVER BARRED.
            <br />
            <br />
            <b>F. Injunctive Relief</b>
            <br />
            <br />
            The foregoing provisions of this section will not apply to any legal action taken by Caro to seek an injunction or other equitable relief in
            connection with any loss, cost, or damage (or any potential loss, cost, or damage) relating to the Services, the content available through the
            Services, your User Submissions and/or Caro’s intellectual property rights, Caro’s operations, and/or Caro’s products or services.
            <br />
            <br />
            <b>G. Small Claims Matters Are Excluded from Arbitration Requirement</b>
            <br />
            <br />
            Notwithstanding the foregoing, either of us may bring a qualifying claim of Disputes in small claims court.
            <br />
            <br />
            <b>H. No Class Action Matters</b>
            <br />
            <br />
            You and we expressly agree that any Dispute is personal to us, and any such Dispute shall only be resolved by an individual arbitration. Neither
            party agrees to class arbitration, or an arbitrationwhere a person brings a Dispute as a representative of any other person or persons. Neither you
            nor we agree that a Dispute can be brought as a class or representative action outside of arbitration, or on behalf of any other person or persons.
            The parties agree that a Dispute may only be resolved through an individual arbitration and shall not be brought as a class arbitration, a class
            action, or any other representative proceeding.
            <br />
            <br />
            <b>I. Federal and State Courts</b>
            <br />
            <br />
            Except to the extent that arbitration is required above, any action or proceeding relating to any Dispute may only be instituted in state court in
            San Mateo County, California or federal court in San Francisco, California. Accordingly, you and Caro consent to the exclusive personal jurisdiction
            and venue of such courts for such matters.
            <br />
            <br />
            <b>J. Applicable Law </b>
            <br />
            <br />
            This Agreement will be governed by and construed in accordance with, and any Dispute and Excluded Dispute will be resolved in accordance with, the
            laws of the State of California, without regard to its conflict of law provisions.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">11. Usage and Ownership of Customer Information</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            All information submitted to Caro through our website becomes and remains the property of Caro. Consequently, we may use any information you submit
            for any purpose we deem appropriate in accordance with this Agreement and our Privacy Policy.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">12. Violations of the Agreement</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            In the event you violate or attempt to violate any part of this Agreement, Caro reserves the right to terminate your access to this website
            immediately, at our sole discretion, with or without notice of any kind. We also reserve the right to involve and cooperate with law enforcement
            agencies, and to pursue a civil lawsuit in accordance with the section above or criminal prosecution for any and all alleged or actual illegal
            activities involving this website.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">13. Indemnification</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            You agree to indemnify, defend, and hold harmless Caro from and against any and all claims, proceedings, damages, injuries, losses, costs, and
            expenses (including reasonable attorneys&apos; fees), arising out of or relating to your use of this website that leads to any claim against us by a
            third-party, regardless of the nature of the cause of action or claim.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">14. Severability and Integration</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            In the event any part of this Agreement is found to be unenforceable, the remainder shall continue in full force and effect. You understand and
            agree that this Agreement, including all documents incorporated herein by reference, represents the entire agreement governing your use of this
            website, the mobile app, or the Services. This Agreement may not be modified, either expressly or by implication, except as set forth below in
            Section 15.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">15. Modification / Termination</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            Our employees are not authorized to vary the terms of this Agreement. This Agreement may be modified only (A) by obtaining our written consent in a
            notarized agreement signed by an officer of Caro; or (B) as set forth in the next paragraph.
            <br />
            <br />
            You agree that we may modify the terms of this Agreement from time to time, and that your right to access the Services is conditioned on an ongoing
            basis with your compliance with the then-current version of this Agreement. We will notify you of any material revisions or modifications to this
            Agreement by: (1) posting a notice on the Caro.com website for thirty (30) days following any revisions or modifications to this Agreement and/or by
            posting a notice on Caro.com the first time that you visit Caro.com following such revisions or modifications; or (2) through a direct communication
            to you by email, if you have provided an email address to us. If you have not already done so, please register the appropriate email address at
            http://www.Caro.com/Dashboard. You will be deemed to have agreed to the new terms and conditions if you continue to access the Services after having
            been notified of such revisions or modifications by email or, if you have not been notified by email, after the passage of thirty (30) days from the
            time the revised terms and conditions are first posted on Caro.com (whichever is sooner). If you do not provide us with a valid email address to
            send you information concerning revisions or modifications to this Agreement, then you agree that you will not receive notice by email. If you
            change email accounts, it will be your responsibility to notify us of a new valid email address in order to receive email notice. We assume no
            responsibility for your failure to actually receive notice. It is your responsibility to review this page for possible modifications.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">16. Website Material and Information</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            This site includes materials and information collected from and provided by third parties that we may not have evaluated or reviewed. Unless
            specifically stated otherwise, we make no guarantees regarding the accuracy, completeness, timeliness, or reliability of any of the materials or
            information on this website, and you should not rely on it without independent verification. Please bring to our attention any materials or
            information that you believe to be inaccurate by forwarding to hello@Caro.com both the information you believe to be inaccurate, and the basis for
            that belief.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">17. Links to Third Party Websites</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            The Services may contain links to third party websites (“Third Party Websites”). Access to Third Party Websites is at your own risk, and Caro is not
            responsible for the accuracy, availability or reliability of any information, goods, data, opinions, advice or statements made available on Third
            Party Websites. These links may also lead to Third Party Websites containing information that some people may find inappropriate or offensive. The
            Third Party Websites are not under the control of Caro and, as such, Caro is not liable for any damage or loss caused or alleged to be caused by or
            in connection with use of or reliance on any Third Party Website. The inclusion of any links to Third Party Websites on Caro does not imply an
            endorsement or recommendation by Caro. Caro is not responsible for any form of transmission received from any link, nor is Caro responsible if any
            of these links are not working appropriately. You are responsible for viewing and abiding by any privacy statements and terms of use posted in
            connection with Third Party Websites, and these Third Party Websites are not governed by this Agreement.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">18. Reservation of Rights</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">Any and all rights not expressly granted to you in this Agreement are hereby reserved by Caro.</div>
        </Col>
      </Row>
    </Container>
  )
}

export default TermsOfUse