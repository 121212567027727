import React, { useState, useEffect } from 'react'
import './CollapseList.scss'

const CollapseList = (props) => {
  const [openItems, setOpenItems] = useState(props.list.items)

  useEffect(() => {}, [])

  const open = (index) => {
    const items = [...openItems]
    console.log('items', index)
    console.log('items', items)
    console.log('items', items[index])
    items[index].show = !items[index].show

    setOpenItems(items)
  }

  return (
    <div className="ep-collapse-list">
      <h2>{props.list.header}</h2>
      {props.list.items.map((item, index) => {
        return (
          <div key={index} className="ep-list-item">
            <h3 onClick={() => open(index)}>
              <i className="ep-cors-icon" />
              {item.header}
            </h3>
            <p className={openItems[index].show ? `ep-open-item` : ``}>{item.text}</p>
          </div>
        )
      })}
    </div>
  )
}

export default CollapseList
