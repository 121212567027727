import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Checkbox, Col, Image, Row } from 'antd'
import Validator from 'validatorjs'
import Steps from 'components/steps'
import mechanicShop from 'images/inspection/bodyshops-1.jpg'
import inspectorImg from 'images/inspection/inspectors.jpg'
import DriveToYourLocation from './components/DriveToYourLocation'
import DriveToOurLocation from './components/DriveToOurLocation'
import ContinueBackCmp from 'components/buttons/ContinueBackCmp'
import actions from 'store/order/actions'
import './Index.scss'
import { customer } from 'api/customer'

const SelectLocType = (props) => {
  const history = useHistory()
  const [messages, setMessages] = useState({})

  useEffect(() => {
    props?.addressMessages && setMessages(props.addressMessages)
  }, [props.addressMessages])

  useEffect(() => {
    const authJson = window.localStorage.getItem('auth')

    if (authJson) {
      const auth = JSON.parse(authJson)

      if (auth?.technician) {
        history.push('/inspector/my-account/statistic')
      }

      if (auth?.customer) {
        customer().then((data) => {
          props.setOrderApplicantInfo({
            ...props.applicantInfo,
            lastName: data.data.last_name,
            firstName: data.data.first_name,
            phone: data.data.phone,
          })
        })
      } else {
        props.setOrderApplicantInfo({
          ...props.applicantInfo,
          lastName: auth.user.last_name,
          firstName: auth.user.first_name,
          phone: auth.user.phone,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeYourCheckbox = () => {
    props.setOrderInspection({
      ...props.orderReducer.inspection,
      isShopLocation: false,
    })
  }

  const handleChangeOurCheckbox = () => {
    props.setOrderInspection({
      ...props.orderReducer.inspection,
      isShopLocation: true,
    })
  }

  useEffect(() => {
    props.setOrderInspection({
      ...props.orderReducer.inspection,
      shop: null,
      location: {
        ...props.orderReducer.inspection.location,
        zip: '',
        address: '',
        apartment: '',
      },
    })
  }, [])

  return (() => {
    return (
        <div className="ep-order-inspection-container" style={{ marginTop: '50px' }}>
          <Row className="SelectLocTypeStyle">
            {/* <Col span={12}>
              <div className="ep-order-inspection-block ep-right">
                <Checkbox
                  className="ep-location-checkbox"
                  onChange={handleChangeOurCheckbox}
                  value={true}
                  checked={props.orderReducer.inspection.isShopLocation === true}
                />
                <h2 className="location-h2">Drive to our location</h2>
                <Image src={mechanicShop} preview={false} />
                <p>
                  We have a large network of local mechanic shops. Once you enter a zip code you will be able to select the inspection location of your
                  preference.
                </p>
              </div>
            </Col> */}
            <Col span={24}>
              <div className="ep-order-inspection-block_ ep-left">
                {/* <Checkbox
                  className="ep-location-checkbox"
                  onChange={handleChangeYourCheckbox}
                  value={false}
                  checked={props.orderReducer.inspection.isShopLocation === false}
                /> */}

                <h2 className="location-h2_">Technician drives to you</h2>
                <Image src={inspectorImg} preview={false} />
                <p>CARO inspectors are experienced and certified professionals you can trust and can perform auto inspection at any given location.</p>
              </div>
            </Col>
          </Row>
          <Row
            style={{
              display: !props.orderReducer.inspection.isShopLocation ? 'flex' : 'none',
              justifyContent: 'center',
              marginTop: 81,
            }}
          >
            <Col span={20}>
              <DriveToYourLocation
                messageAddress={messages.address ? messages.address.message : ''}
                validateStatusAddress={messages.address ? messages.address.validateStatus : null}
                messageApartment={messages.apartment ? messages.apartment.message : ''}
                validateStatusApartment={messages.apartment ? messages.apartment.validateStatus : null}
                errorsAddress={messages.address ? true : false}
                errorsApartment={messages.apartment ? true : false}
                setAddressMessages={props.setAddressMessages}
                addressMessages={props.addressMessages}
              />
            </Col>
          </Row>
          <Row
            style={{
              display: props.orderReducer.inspection.isShopLocation === true ? 'block' : 'none',
            }}
          >
            <Col span={24}>
              <DriveToOurLocation
                message={messages.zip ? messages.zip.message : ''}
                validateStatus={messages.zip ? messages.zip.validateStatus : null}
                errors={messages.zip ? true : false}
              />
            </Col>
          </Row>
        </div>
    )
  })()
}

const mapStateToProps = (state) => {
  return {
    orderReducer: state.order.orderReducer,
    applicantInfo: state.order.orderReducer.applicantInfo,
    inspection: state.order.orderReducer.inspection,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrderInspection: (payload) => dispatch(actions.setOrderInspection(payload)),
    setOrderApplicantInfo: (payload) => dispatch(actions.setOrderApplicantInfo(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectLocType)
