import React from 'react'
import Filters from './filters'
import { ReactComponent as CarIcon } from '../../../../../images/inspector/car.svg'

const InspectedStatistic = (props) => {
  return (
    <div className={`statistic-card statistic-card-inspected`} onClick={() => props.setSelectedStatistic('Inspected')}>
      <h1>Inspected</h1>
      <Filters handleDateChange={props.handleDateChange} selectedDate={props.selectedDate} setPeriodType={props.setPeriodType} />
      <div className="inspected-length">
        <div className="inspected-length-label">
          <CarIcon />
          Inspected
        </div>
        <div className="inspected-length-value">{props.assignedListPrevious.length + props.acceptedListPrevious.length}</div>
      </div>
    </div>
  )
}

export default InspectedStatistic
