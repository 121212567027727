import axios from 'axios'

/**
 * Car info.
 *
 * @param vin
 * @returns {Promise<AxiosResponse<T>>}
 */
export const carInfo = (vin) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/car/info`, {
    params: {
      vin,
    },
  })
}

/**
 * Car info by model.
 * @param make
 * @param model
 * @param year
 * @returns {Promise<AxiosResponse<T>>}
 */
export const carInfoByModel = (make, model, year) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/car/info-by-model`, {
    params: {
      make,
      model,
      year,
    },
  })
}

/**
 * Car makes.
 * @returns {Promise<AxiosResponse<T>>}
 */
export const carMakes = () => {
  return axios.get(`${process.env.REACT_APP_API_URL}/car/makes`)
}

/**
 * Car models.
 * @param make
 * @returns {Promise<AxiosResponse<T>>}
 */
export const carModels = (make) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/car/models`, {
    params: {
      make: make,
    },
  })
}

/**
 * Car years.
 * @param make
 * @param model
 * @returns {Promise<AxiosResponse<T>>}
 */
export const carYears = (make, model) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/car/years`, {
    params: {
      make: make,
      model: model,
    },
  })
}
