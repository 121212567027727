import axios from 'axios'

export const getUpcoming = () => {
  let config = {}
  const auth = JSON.parse(localStorage.getItem('auth'))

  if (auth && typeof auth.access_token !== 'undefined') {
    config = {
      headers: {
        Authorization: `Bearer ${auth.access_token}`,
      },
    }
  }

  return axios.get(`${process.env.REACT_APP_API_URL}/inspector/appointments/of-customer`, config)
}
