import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Checkbox, Col, Form, Row, Select, Modal, Button } from 'antd'
import Text from 'components/inputs/text'
import Container from '../../../../Container'
import { getAuthData } from 'helper/auth'
import { carInfo, carInfoByModel, carMakes, carModels, carYears } from 'api/car'
import Validator from 'validatorjs'
import actions from 'store/order/actions'
import './Index.scss'
import { customer } from 'api/customer'
import StepsForCarPrice from 'components/stepsForCarPrice/Steps'
import moment from 'moment'

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const GetInstantCarPrice = (props) => {
  const history = useHistory()
  const [errorModal, setErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [messages, setMessages] = useState({
    email: {
      message: '',
      validateStatus: null,
    },
    vin: {
      message: '',
      validateStatus: null,
    },
  })
  const refForm = useRef()

  useEffect(() => {
    const auth = getAuthData()

    if (auth?.customer) {
      // history.push("/order/inspection/select-car")
      customer()
        .then((data) => {
          props.setOrderApplicantInfo({
            ...props.applicantInfo,
            email: data.data.email,
          })
        })
        .catch((e) => console.log(e, '-error'))
    }

    if (auth?.technician) {
      history.push('/inspector/my-account/statistic')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    props.setOrderCar({ ...props.car, vin: '' })
  }, [])

  const handleSubmit = () => {
    const data = {
      vin: props.car.vin,
    }

    const rules = {
      vin: 'required'
    }


    const validation = new Validator(data, rules)

    if (validation.fails()) {
      const errors = validation.errors.all()
      let msg = { ...messages }
      for (let prop in errors) {
        errors[prop].map((error) => {
          // message.error(error)
          msg[prop] = { message: error, validateStatus: 'error' }
          // console.log('msg', msg);
        })
      }

      setMessages(msg)
    }


    if (props.car.vin.length) {
      carInfo(props.car.vin)
        .then((response) => {
          props.setOrderCar({
            ...props.car,
            detail: response.data,
          })

          if (validation.passes()) {
            props.setOrderInspection({
              ...props.inspection,
              inspectionTimeStart: Date.now()
            })
            history.push('/car-price')
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data.error.message)
          setErrorModal(true)
        })
    }
  }

  /**
   * Handle change input.
   *
   * @param e
   * @param name
   */
  const handleChange = (e, name) => {
    setMessages({
      ...messages,
      [name]: {
        message: '',
        validateStatus: null,
      },
    })

    if (name === 'vin') {
      props.setOrderCar({ ...props.car, [name]: e.target.value })
    }
  }


  return (
    <Container>
      <StepsForCarPrice style={{ marginTop: '15px' }}/>
      <div className="ep-form">
        <div className="ep-order-inspection-form" ref={refForm}>
          <h2 style={{ marginTop: '27px' }}>Order Inspection</h2>
          <Row
            style={{
              marginTop: '20px',
              display: 'block',
            }}
          >
            <Col span={24}>
              <Text
                placeholder="VIN number"
                onChange={(e) => handleChange(e, 'vin')}
                value={props.car.vin}
                message={messages.vin.message}
                validateStatus={messages.vin.validateStatus}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col span={24}>
              <button className="ep-button ep-sign-in" onClick={handleSubmit}>
                <span>Continue</span>
              </button>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        style={{ display: 'flex', justifyContent: 'center' }}
        visible={errorModal}
        onOk={() => setErrorModal(false)}
        onCancel={() => setErrorModal(false)}
        footer={[
          <Button key="submit" type="primary" onClick={() => setErrorModal(false)}>
            Ok
          </Button>,
        ]}
      >
        <span style={{ display: 'block', margin: '20px 100px' }}>{errorMessage}</span>
      </Modal>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    applicantInfo: state.order.orderReducer.applicantInfo,
    car: state.order.orderReducer.car,
    inspection: state.order.orderReducer.inspection
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrderApplicantInfo: (payload) => dispatch(actions.setOrderApplicantInfo(payload)),
    setOrderCar: (payload) => dispatch(actions.setOrderCar(payload)),
    setOrderInspection: (payload) => dispatch(actions.setOrderInspection(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GetInstantCarPrice)
