/*global google*/
import React, { useState, useEffect, useRef } from 'react'
import Script from 'react-load-script'
import actions from 'store/order/actions'
import { connect } from 'react-redux'
import { Input, Select } from 'antd'
import './DriveToYourLocation.scss'
import 'antd/dist/antd.css'

const { Option } = Select

const DriveToYourLocation = (props) => {
  const [query, setQuery] = useState(null)
  const [service, setService] = useState(null)
  const [predictionsList, setPredictionsList] = useState([])
  const [optionsList, setOptionsList] = useState([])
  const [stateOfUS, setStateOfUS] = useState('')
  const refSearch = useRef()
  const [searchValue, setSearchValue] = useState('')
  const [coords, setCoords] = useState({
    lat: 32.71575,
    lng: -117.1610914,
  })

  useEffect(() => {
      props.setOrderInspection({
        ...props.inspection,
        location: {
          ...props.inspection.location,
          geo_coordinates: coords,
          selected_state: stateOfUS,
        },
      })
  }, [stateOfUS, coords])

  useEffect(() => {
    const options = []

    predictionsList.forEach((prediction) => {
      options.push(prediction.description)
    })

    setOptionsList(options)
  }, [predictionsList])

  return (() => {
    const { inspection, setOrderInspection } = props

    const displaySuggestions = (predictions) => {
      const filteredPredictions = []
      predictions &&
        predictions.forEach((prediction) => {
          const item = prediction.structured_formatting.secondary_text ? prediction.structured_formatting.secondary_text : ''

          if (item.includes('USA') || item.includes('США')) {
            filteredPredictions.push(prediction)
          }
        })
      setPredictionsList(filteredPredictions)
    }

    const handleScriptLoad = () => {
      const service = new google.maps.places.AutocompleteService()
      setService(service)
    }

    const handleSearch = (value) => {
      if (service) {
        const request = {
          location: new google.maps.LatLng(37, -120),
          radius: 750000,
          input: value,
        }
        setSearchValue(value)

        service.getQueryPredictions(request, displaySuggestions)
      }
    }

    const handleChange = (value) => {
      setQuery(value)

      let geocoder = new google.maps.Geocoder()
      geocoder.geocode({ address: value }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          if (value) {
            setCoords({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            })
          }

          results[0] && results[0].address_components.forEach(component => {
            if (component.types.includes('administrative_area_level_1')) {
              setStateOfUS(component.short_name)
              return;
            }
          })
        }
      })

      setOrderInspection({
        ...inspection,
        isShopLocation: false,
        location: {
          ...inspection.location,
          address: value,
          zip: '',
        },
      })
      setSearchValue(value)
    }

    const onSearchFocus = () => {
      window.scroll(500, 500)
      setQuery(searchValue)
    }

    const handleChangeApartment = (e) => {
      setOrderInspection({
        ...inspection,
        location: {
          ...inspection.location,
          apartment: e.currentTarget.value,
        },
      })
    }

    let errorAddressJsx = null
    let errorApartmentJsx = null
    const inputClassesAddress = []
    const inputClassesApartment = []

    if (props.validateStatusAddress && props.validateStatusAddress === 'error' && props.errorsAddress) {
      errorAddressJsx = (
        <div className="ep-error-message">
          <div>{props.messageAddress}</div>
        </div>
      )

      inputClassesAddress.push('ep-error')
    }

    if (props.validateStatusApartment && props.validateStatusApartment === 'error' && props.errorsApartment) {
      errorApartmentJsx = (
        <div className="ep-error-message">
          <div>{props.messageApartment}</div>
        </div>
      )

      inputClassesApartment.push('ep-error')
    }

    return (
      <div className="ep-search-address">
        <Script url={`https://maps.googleapis.com/maps/api/js?&key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`} onLoad={handleScriptLoad} />
        <div className="search-container" id="search-select">
          <Select
            ref={refSearch}
            showSearch
            placeholder="Please provide your address"
            value={query}
            allowClear
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            onFocus={onSearchFocus}
            notFoundContent={null}
            className={`DriveToOurLocationAd ${inputClassesAddress.join(' ')}`}
          >
            {optionsList.map((item) => (
              <Option key={item}>{item}</Option>
            ))}
          </Select>

          {errorAddressJsx}
        </div>

        <div>
          <Input
            placeholder="Apartment"
            className={`DriveToOurLocationSt ${inputClassesApartment.join(' ')}`}
            onChange={handleChangeApartment}
            style={{ height: '47px' }}
          />
          {errorApartmentJsx}
        </div>
      </div>
    )
  })()
}

const mapStateToProps = (state) => {
  return {
    orderReducer: state.order.orderReducer,
    applicantInfo: state.order.orderReducer.applicantInfo,
    inspection: state.order.orderReducer.inspection,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrderApplicantInfo: (payload) => dispatch(actions.setOrderApplicantInfo(payload)),
    setOrderInspection: (payload) => dispatch(actions.setOrderInspection(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DriveToYourLocation)
