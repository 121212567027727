import React, { useEffect, useState } from 'react'
import { Pagination } from 'antd'

import './notifications.scss'
import MyAccount from '../../myAccount'
import { getNotifications } from '../../../../../api/notification'
import NotifsFilter from './notifsFilter/NotifsFilter'
import AppointmentNotifications from './AppointmentNotifications'

const Notifications = () => {
  const [notifications, setNotifications] = useState([])
  const [uniqueIds, setUniqueIds] = useState([])
  const [sortedNotifs, setSortedNotifs] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [displayNotifications, setDisplayNotifications] = useState([])
  const [filteredNotifs, setFilteredNotifs] = useState([])

  const [filterValue, setfilterValue] = useState({
    label: 'All appointments',
    message: '',
  })

  useEffect(() => {
    getNotifications()
      .then((res) => {
        setNotifications(res.data)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }, [pageNumber])

  const filtersArray = [
    {
      label: 'Inspected',
      message: 'Inspection сompleted',
    },
    {
      label: 'Ready to go',
      message: 'The inspector went to you',
    },
    {
      label: 'Scheduled',
      message: 'Inspection scheduled',
    },
    {
      label: 'Created and scheduled',
      firstMessage: 'Inspection scheduled',
      secondMessage: 'Created',
    },
    {
      label: 'Created',
      message: 'Created',
    },
    {
      label: 'All appointments',
      message: '',
    },
  ]

  const notificationsPerPage = 7
  const pagesVisited = (pageNumber - 1) * notificationsPerPage

  useEffect(() => {
    let appointment_ids = []

    notifications.map((item) => {
      appointment_ids.push(item.appointment_id)
    })

    setUniqueIds([...new Set(appointment_ids)])
  }, [notifications])

  useEffect(() => {
    let sortedNotifs_ = []
    uniqueIds.map((id) => {
      let notifs = notifications.filter((n) => n.appointment_id === id)

      return sortedNotifs_.push({
        appointment_id: id,
        appointment_notifs: [...notifs],
      })
    })

    setSortedNotifs(sortedNotifs_)
  }, [uniqueIds])

  const onHandleFilterChange = (e) => {
    filtersArray.map((obj) => {
      if (obj.label === e) {
        setfilterValue(obj)
        setPageNumber(1)
      }
    })
  }

  useEffect(() => {
    setFilteredNotifs(
      sortedNotifs.filter((obj) => {
        const lastStatusNotif = obj.appointment_notifs[obj.appointment_notifs.length - 1].message

        if (filterValue.label === 'All appointments') {
          return obj
        }

        if (filterValue.message === lastStatusNotif || filterValue.firstMessage === lastStatusNotif || filterValue.secondMessage === lastStatusNotif) {
          return obj.appointment_notifs
        }
      })
    )
  }, [sortedNotifs])

  useEffect(() => {
    setDisplayNotifications(
      filteredNotifs.slice(pagesVisited, pagesVisited + notificationsPerPage).map((notif) => {
        return <AppointmentNotifications notif={notif} pageNumber={pageNumber}/>
      })
    )
  }, [filteredNotifs, pageNumber])

  return (
    <MyAccount>
      <NotifsFilter filterValue={filterValue} filtersArray={filtersArray} onChange={onHandleFilterChange} />
      {displayNotifications}

      {notifications.length === 0 ? (
        'No notifications'
      ) : (
        <Pagination
          style={{ margin: 20 }}
          defaultCurrent={pageNumber}
          defaultPageSize={notificationsPerPage}
          onChange={setPageNumber}
          total={filteredNotifs.length}
        />
      )}
    </MyAccount>
  )
}

export default Notifications
