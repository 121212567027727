export default {
  collapseList_0: {
    items: [
      {
        header: 'What is Caro, exactly?',
        text: "Caro is exactly what the sleepi pre-owned market has needed for decades. We are what's called a “peer-to-peer” marketplace, which is a fancy way to describe a place that connects buyers and sellers, in an organized, efficient and safe environment. Sellers list their cars, and once they pass our comprehensive pre-purchase inspection process and become Caro-certified, they are made available to buyers. We've eliminated the heavy (and expensive) footprint – perhaps we should say tireprint – of the dealer. We have no salespeople to pay, no real estate costs for huge lots and showrooms, no costly (it adds up) single-serve coffee machines in the lobby. Because of that, we guarantee that sellers get more than at any dealer, and buyers pay less than at any dealer. That combination of a win/win is something we proudly describe as a “Caron' miracle.” Caro also takes care of all the transactional drudgery – we arrange for the transfer of title, and we even pick up the car and deliver it. Finally, if for some reason buyers change their minds, we give them ten days to do it for a full refund. No questions asked. Sellers - don't worry. If buyers want to return the car, we take it ourselves. Once you've sold your car - it's sold. We won't return it to you, and the money is yours to keep.",
        show: false,
      },
      {
        header: 'What happens after my car passes the Caro inspection? ',
        text: "Caro is exactly what the sleepi pre-owned market has needed for decades. We are what's called a “peer-to-peer” marketplace, which is a fancy way to describe a place that connects buyers and sellers, in an organized, efficient and safe environment. Sellers list their cars, and once they pass our comprehensive pre-purchase inspection process and become Caro-certified, they are made available to buyers. We've eliminated the heavy (and expensive) footprint – perhaps we should say tireprint – of the dealer. We have no salespeople to pay, no real estate costs for huge lots and showrooms, no costly (it adds up) single-serve coffee machines in the lobby. Because of that, we guarantee that sellers get more than at any dealer, and buyers pay less than at any dealer. That combination of a win/win is something we proudly describe as a “Caron' miracle.” Caro also takes care of all the transactional drudgery – we arrange for the transfer of title, and we even pick up the car and deliver it. Finally, if for some reason buyers change their minds, we give them ten days to do it for a full refund. No questions asked. Sellers - don't worry. If buyers want to return the car, we take it ourselves. Once you've sold your car - it's sold. We won't return it to you, and the money is yours to keep.",
        show: false,
      },
      {
        header: 'How long does it take to sell my car on Caro?',
        text: "Caro is exactly what the sleepi pre-owned market has needed for decades. We are what's called a “peer-to-peer” marketplace, which is a fancy way to describe a place that connects buyers and sellers, in an organized, efficient and safe environment. Sellers list their cars, and once they pass our comprehensive pre-purchase inspection process and become Caro-certified, they are made available to buyers. We've eliminated the heavy (and expensive) footprint – perhaps we should say tireprint – of the dealer. We have no salespeople to pay, no real estate costs for huge lots and showrooms, no costly (it adds up) single-serve coffee machines in the lobby. Because of that, we guarantee that sellers get more than at any dealer, and buyers pay less than at any dealer. That combination of a win/win is something we proudly describe as a “Caron' miracle.” Caro also takes care of all the transactional drudgery – we arrange for the transfer of title, and we even pick up the car and deliver it. Finally, if for some reason buyers change their minds, we give them ten days to do it for a full refund. No questions asked. Sellers - don't worry. If buyers want to return the car, we take it ourselves. Once you've sold your car - it's sold. We won't return it to you, and the money is yours to keep.",
        show: false,
      },
      {
        header: 'Does Caro charge me any fees?',
        text: "Caro is exactly what the sleepi pre-owned market has needed for decades. We are what's called a “peer-to-peer” marketplace, which is a fancy way to describe a place that connects buyers and sellers, in an organized, efficient and safe environment. Sellers list their cars, and once they pass our comprehensive pre-purchase inspection process and become Caro-certified, they are made available to buyers. We've eliminated the heavy (and expensive) footprint – perhaps we should say tireprint – of the dealer. We have no salespeople to pay, no real estate costs for huge lots and showrooms, no costly (it adds up) single-serve coffee machines in the lobby. Because of that, we guarantee that sellers get more than at any dealer, and buyers pay less than at any dealer. That combination of a win/win is something we proudly describe as a “Caron' miracle.” Caro also takes care of all the transactional drudgery – we arrange for the transfer of title, and we even pick up the car and deliver it. Finally, if for some reason buyers change their minds, we give them ten days to do it for a full refund. No questions asked. Sellers - don't worry. If buyers want to return the car, we take it ourselves. Once you've sold your car - it's sold. We won't return it to you, and the money is yours to keep.",
        show: false,
      },
      {
        header: "Can I sell my car elsewhere while it's on Caro?",
        text: "Caro is exactly what the sleepi pre-owned market has needed for decades. We are what's called a “peer-to-peer” marketplace, which is a fancy way to describe a place that connects buyers and sellers, in an organized, efficient and safe environment. Sellers list their cars, and once they pass our comprehensive pre-purchase inspection process and become Caro-certified, they are made available to buyers. We've eliminated the heavy (and expensive) footprint – perhaps we should say tireprint – of the dealer. We have no salespeople to pay, no real estate costs for huge lots and showrooms, no costly (it adds up) single-serve coffee machines in the lobby. Because of that, we guarantee that sellers get more than at any dealer, and buyers pay less than at any dealer. That combination of a win/win is something we proudly describe as a “Caron' miracle.” Caro also takes care of all the transactional drudgery – we arrange for the transfer of title, and we even pick up the car and deliver it. Finally, if for some reason buyers change their minds, we give them ten days to do it for a full refund. No questions asked. Sellers - don't worry. If buyers want to return the car, we take it ourselves. Once you've sold your car - it's sold. We won't return it to you, and the money is yours to keep.",
        show: false,
      },
      {
        header: 'How you decide how to price my car?',
        text: "Caro is exactly what the sleepi pre-owned market has needed for decades. We are what's called a “peer-to-peer” marketplace, which is a fancy way to describe a place that connects buyers and sellers, in an organized, efficient and safe environment. Sellers list their cars, and once they pass our comprehensive pre-purchase inspection process and become Caro-certified, they are made available to buyers. We've eliminated the heavy (and expensive) footprint – perhaps we should say tireprint – of the dealer. We have no salespeople to pay, no real estate costs for huge lots and showrooms, no costly (it adds up) single-serve coffee machines in the lobby. Because of that, we guarantee that sellers get more than at any dealer, and buyers pay less than at any dealer. That combination of a win/win is something we proudly describe as a “Caron' miracle.” Caro also takes care of all the transactional drudgery – we arrange for the transfer of title, and we even pick up the car and deliver it. Finally, if for some reason buyers change their minds, we give them ten days to do it for a full refund. No questions asked. Sellers - don't worry. If buyers want to return the car, we take it ourselves. Once you've sold your car - it's sold. We won't return it to you, and the money is yours to keep.",
        show: false,
      },
      {
        header: 'I have a loan/lease. Can I still sell my car on Caro.com?',
        text: "Caro is exactly what the sleepi pre-owned market has needed for decades. We are what's called a “peer-to-peer” marketplace, which is a fancy way to describe a place that connects buyers and sellers, in an organized, efficient and safe environment. Sellers list their cars, and once they pass our comprehensive pre-purchase inspection process and become Caro-certified, they are made available to buyers. We've eliminated the heavy (and expensive) footprint – perhaps we should say tireprint – of the dealer. We have no salespeople to pay, no real estate costs for huge lots and showrooms, no costly (it adds up) single-serve coffee machines in the lobby. Because of that, we guarantee that sellers get more than at any dealer, and buyers pay less than at any dealer. That combination of a win/win is something we proudly describe as a “Caron' miracle.” Caro also takes care of all the transactional drudgery – we arrange for the transfer of title, and we even pick up the car and deliver it. Finally, if for some reason buyers change their minds, we give them ten days to do it for a full refund. No questions asked. Sellers - don't worry. If buyers want to return the car, we take it ourselves. Once you've sold your car - it's sold. We won't return it to you, and the money is yours to keep.",
        show: false,
      },
      {
        header: 'What if I sold my car, but the buyer returns it afterwards? Will you ask for my money back?',
        text: "Caro is exactly what the sleepi pre-owned market has needed for decades. We are what's called a “peer-to-peer” marketplace, which is a fancy way to describe a place that connects buyers and sellers, in an organized, efficient and safe environment. Sellers list their cars, and once they pass our comprehensive pre-purchase inspection process and become Caro-certified, they are made available to buyers. We've eliminated the heavy (and expensive) footprint – perhaps we should say tireprint – of the dealer. We have no salespeople to pay, no real estate costs for huge lots and showrooms, no costly (it adds up) single-serve coffee machines in the lobby. Because of that, we guarantee that sellers get more than at any dealer, and buyers pay less than at any dealer. That combination of a win/win is something we proudly describe as a “Caron' miracle.” Caro also takes care of all the transactional drudgery – we arrange for the transfer of title, and we even pick up the car and deliver it. Finally, if for some reason buyers change their minds, we give them ten days to do it for a full refund. No questions asked. Sellers - don't worry. If buyers want to return the car, we take it ourselves. Once you've sold your car - it's sold. We won't return it to you, and the money is yours to keep.",
        show: false,
      },
      {
        header: 'Who are the Caro inspectors?',
        text: "Caro is exactly what the sleepi pre-owned market has needed for decades. We are what's called a “peer-to-peer” marketplace, which is a fancy way to describe a place that connects buyers and sellers, in an organized, efficient and safe environment. Sellers list their cars, and once they pass our comprehensive pre-purchase inspection process and become Caro-certified, they are made available to buyers. We've eliminated the heavy (and expensive) footprint – perhaps we should say tireprint – of the dealer. We have no salespeople to pay, no real estate costs for huge lots and showrooms, no costly (it adds up) single-serve coffee machines in the lobby. Because of that, we guarantee that sellers get more than at any dealer, and buyers pay less than at any dealer. That combination of a win/win is something we proudly describe as a “Caron' miracle.” Caro also takes care of all the transactional drudgery – we arrange for the transfer of title, and we even pick up the car and deliver it. Finally, if for some reason buyers change their minds, we give them ten days to do it for a full refund. No questions asked. Sellers - don't worry. If buyers want to return the car, we take it ourselves. Once you've sold your car - it's sold. We won't return it to you, and the money is yours to keep.",
        show: false,
      },
      {
        header: "Can you inspect my car at home? At work? At Tom Brady's mansion? ",
        text: "Caro is exactly what the sleepi pre-owned market has needed for decades. We are what's called a “peer-to-peer” marketplace, which is a fancy way to describe a place that connects buyers and sellers, in an organized, efficient and safe environment. Sellers list their cars, and once they pass our comprehensive pre-purchase inspection process and become Caro-certified, they are made available to buyers. We've eliminated the heavy (and expensive) footprint – perhaps we should say tireprint – of the dealer. We have no salespeople to pay, no real estate costs for huge lots and showrooms, no costly (it adds up) single-serve coffee machines in the lobby. Because of that, we guarantee that sellers get more than at any dealer, and buyers pay less than at any dealer. That combination of a win/win is something we proudly describe as a “Caron' miracle.” Caro also takes care of all the transactional drudgery – we arrange for the transfer of title, and we even pick up the car and deliver it. Finally, if for some reason buyers change their minds, we give them ten days to do it for a full refund. No questions asked. Sellers - don't worry. If buyers want to return the car, we take it ourselves. Once you've sold your car - it's sold. We won't return it to you, and the money is yours to keep.",
        show: false,
      },
      {
        header: 'Do I need to be present for the inspection? Can you do it without me?',
        text: "Caro is exactly what the sleepi pre-owned market has needed for decades. We are what's called a “peer-to-peer” marketplace, which is a fancy way to describe a place that connects buyers and sellers, in an organized, efficient and safe environment. Sellers list their cars, and once they pass our comprehensive pre-purchase inspection process and become Caro-certified, they are made available to buyers. We've eliminated the heavy (and expensive) footprint – perhaps we should say tireprint – of the dealer. We have no salespeople to pay, no real estate costs for huge lots and showrooms, no costly (it adds up) single-serve coffee machines in the lobby. Because of that, we guarantee that sellers get more than at any dealer, and buyers pay less than at any dealer. That combination of a win/win is something we proudly describe as a “Caron' miracle.” Caro also takes care of all the transactional drudgery – we arrange for the transfer of title, and we even pick up the car and deliver it. Finally, if for some reason buyers change their minds, we give them ten days to do it for a full refund. No questions asked. Sellers - don't worry. If buyers want to return the car, we take it ourselves. Once you've sold your car - it's sold. We won't return it to you, and the money is yours to keep.",
        show: false,
      },
      {
        header: "What if I don't have 30 days to wait, but still want to sell my car?",
        text: "Caro is exactly what the sleepi pre-owned market has needed for decades. We are what's called a “peer-to-peer” marketplace, which is a fancy way to describe a place that connects buyers and sellers, in an organized, efficient and safe environment. Sellers list their cars, and once they pass our comprehensive pre-purchase inspection process and become Caro-certified, they are made available to buyers. We've eliminated the heavy (and expensive) footprint – perhaps we should say tireprint – of the dealer. We have no salespeople to pay, no real estate costs for huge lots and showrooms, no costly (it adds up) single-serve coffee machines in the lobby. Because of that, we guarantee that sellers get more than at any dealer, and buyers pay less than at any dealer. That combination of a win/win is something we proudly describe as a “Caron' miracle.” Caro also takes care of all the transactional drudgery – we arrange for the transfer of title, and we even pick up the car and deliver it. Finally, if for some reason buyers change their minds, we give them ten days to do it for a full refund. No questions asked. Sellers - don't worry. If buyers want to return the car, we take it ourselves. Once you've sold your car - it's sold. We won't return it to you, and the money is yours to keep.",
        show: false,
      },
    ],
  },
  collapseList_1: {
    header: 'More info for Buyers',
    items: [
      {
        header: 'How do I pay for my wonderful vehicle?',
        text: 'Credit Card: We accept every major credit card, and we allow you to combine any five of them. Wire Transfer: Transfer funds directly from your bank account.',
        show: false,
      },
      {
        header: 'Can I meet the owner? ',
        text: 'Credit Card: We accept every major credit card, and we allow you to combine any five of them. Wire Transfer: Transfer funds directly from your bank account.',
        show: false,
      },
      {
        header: 'I love the Internet and all, but I still want to test drive the car?',
        text: 'Credit Card: We accept every major credit card, and we allow you to combine any five of them. Wire Transfer: Transfer funds directly from your bank account.',
        show: false,
      },
    ],
  },
}
