import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Validator from 'validatorjs'

import { Upload, Col, Row, message, Form, Button } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { useForm } from 'antd/es/form/Form'

import InputMask from 'react-input-mask'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'

import MyAccount from '../../myAccount'
import './setting.scss'
import { customer } from '../../../../../api/customer'
import { changeInfo, changePassword } from '../../../../../api/user'
import CaroPassword from 'components/inputs/caroPassword/CaroPassword'

const Setting = () => {
  const [form] = useForm()
  const [imageUrl, setImageUrl] = useState()
  const [loading, setLoading] = useState(false)

  const [customerData, setCustomerData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    old_password: '',
    password: '',
    req_password: '',
    image: '',
  })

  const [errors, setErrors] = useState({
    phone: '',
    last_name: '',
    first_name: '',
    email: '',
  })

  const [messages, setMessages] = useState({
    old_password: {
      message: '',
      validateStatus: null,
    },
    password: {
      message: '',
      validateStatus: null,
    },
    req_password: {
      message: '',
      validateStatus: null,
    },
  })

  useEffect(() => {
    customer().then((data) => {
      const _customer = { ...customerData }
      _customer.first_name = data.data.first_name
      _customer.last_name = data.data.last_name
      _customer.email = data.data.email
      _customer.phone = data.data.phone

      setCustomerData(_customer)

      form.setFieldsValue(_customer)
    })
  }, [])

  const getBase64 = (img, callback) => {
    const _customer = { ...customerData }
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
    const data = new FormData()
    data.append('image', img, 'game.png')

    axios
      .post(`${process.env.REACT_APP_API_URL}/image-upload`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((success) => {
        _customer.image = success.data.path
        setCustomerData(_customer)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (_imageUrl) => {
        setImageUrl(_imageUrl)
        setLoading(false)
      })
    }
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  const uploadButton = () => {
    return (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    )
  }

  const handleChangeInput = (e, name) => {
    const _customer = { ...customerData }
    _customer[name] = e.target.value
    setCustomerData(_customer)

    setErrors({
      ...errors,
      [name]: '',
    })

    setMessages({
      ...messages,
      [name]: {
        message: '',
        validateStatus: null,
      },
    })
  }

  const submitPassword = () => {
    const { old_password, password, req_password } = customerData

    const data = {
      req_password,
      password,
      old_password,
    }

    const rules = {
      old_password: ['required', 'regex:/^[a-zA-Z0-9!@#$%^&*)(+=._-]{8,24}$/'],
      password: ['required', 'regex:/^[a-zA-Z0-9!@#$%^&*)(+=._-]{8,24}$/'],
      req_password: ['required', 'regex:/^[a-zA-Z0-9!@#$%^&*)(+=._-]{8,24}$/', 'same:password'],
    }

    const customMessages = {
      required: 'The :attribute field is required',
      'required.old_password': 'Input your current password',
      'required.password': 'Input your new password',
      'required.req_password': 'Confirm your new password',

      min: 'At least 8 chars',
      max: 'A maximum of 24 chars',
    }

    const validation = new Validator(data, rules, customMessages)

    if (validation.passes()) {
      changePassword({ old_password, password, req_password })
        .then(() => {
          message.success('Your password changed')
        })
        .catch((err) => {
          message.error(err.response.data.error.message)
        })
    }

    if (validation.fails()) {
      const errors = validation.errors.all()
      let msg = { ...messages }

      for (let prop in errors) {
        msg = {
          ...msg,
          [prop]: { message: errors[prop][0], validateStatus: 'error' },
        }
      }
      setMessages(msg)
    }
  }

  const submitInfo = () => {
    const { first_name, last_name, email, phone, image } = customerData

    const data = {
      first_name,
      last_name,
      email,
      phone: phone.replace(/\s/g, ''),
    }

    const rules = {
      phone: 'required|digits:10',
      email: 'required|email',
      last_name: ['required', 'regex:/^[A-Za-z]+$/'],
      first_name: ['required', 'regex:/^[A-Za-z]+$/'],
    }

    const customMessages = {
      'required.first_name': 'Input your first name',
      'required.last_name': 'Input your last name',

      'required.phone': 'Input your phone',
      'required.email': 'Input your email',

      regex: 'Use only letters',
      'digits.phone': 'Use only 10 digits',
    }

    const validation = new Validator(data, rules, customMessages)

    if (validation.passes()) {
      changeInfo({ first_name, last_name, email, phone, image })
        .then(() => {
          message.success('Your information changed')
        })
        .catch((err) => {
          message.error(err.response.data.message)
        })
    }

    if (validation.fails()) {
      const errs = validation.errors.all()
      for (let prop in errs) {
        setErrors({
          ...errors,
          [prop]: errs[prop][0],
        })
      }
    }
  }

  return (
    <MyAccount>
      <Form initialValues={customerData} form={form} style={{ marginBottom: '40px' }}>
        <Row justify="space-between">
          <Col span="8">
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
          </Col>

          <Col span="7">
            <Row>
              <Col span="24">
                <Form.Item
                  className="textField"
                  name="first_name"
                  validateStatus={errors.first_name.length && 'error'}
                  help={errors.first_name.length ? errors.first_name : null}
                >
                  <TextField
                    id="mui-theme-provider-standard-input"
                    label="First name"
                    value={customerData.first_name}
                    onChange={(e) => handleChangeInput(e, 'first_name')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span="24">
                <Form.Item
                  className="textField"
                  name="last_name"
                  validateStatus={errors.last_name.length && 'error'}
                  help={errors.last_name.length ? errors.last_name : null}
                >
                  <TextField
                    id="mui-theme-provider-standard-input"
                    label="Last Name"
                    value={customerData.last_name}
                    onChange={(e) => handleChangeInput(e, 'last_name')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span="24">
                <Form.Item className="textField" name="email" validateStatus={errors.email.length && 'error'} help={errors.email.length ? errors.email : null}>
                  <TextField
                    id="mui-theme-provider-standard-input"
                    label="Email"
                    value={customerData.email}
                    onChange={(e) => handleChangeInput(e, 'email')}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span="24">
                <Form.Item className="textField" name="phone" validateStatus={errors.phone.length && 'error'} help={errors.phone.length ? errors.phone : null}>
                  <InputMask
                    type="tel"
                    mask="999 999 9999"
                    className="phone-input"
                    initialvalues={customerData.phone}
                    onChange={(e) => handleChangeInput(e, 'phone')}
                  >
                    {() => <TextField label="Phone" />}
                  </InputMask>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span="24">
                <Button type="primary" onClick={submitInfo}>
                  Change info
                </Button>
              </Col>
            </Row>
          </Col>

          <Col span="7">
            <Row>
              <Col span="24">
                <FormControl name="old_password">
                  <InputLabel htmlFor="old-password">Current password</InputLabel>
                  <CaroPassword
                    id="old-password"
                    value={customerData.old_password}
                    onChange={(e) => {
                      handleChangeInput(e, 'old_password')
                    }}
                    error={messages?.old_password?.message !== ''}
                    helperText={messages?.old_password?.message}
                  />
                </FormControl>
              </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <Col span="24">
                <FormControl name="password">
                  <InputLabel htmlFor="password">New password</InputLabel>
                  <CaroPassword
                    id="new-password"
                    value={customerData.password}
                    onChange={(e) => handleChangeInput(e, 'password')}
                    error={messages?.password?.message !== ''}
                    helperText={messages?.password?.message}
                  />
                </FormControl>
              </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <Col span="24">
                <FormControl name="req_password">
                  <InputLabel htmlFor="req-password">Confirm password</InputLabel>
                  <CaroPassword
                    id="req-password"
                    value={customerData.req_password}
                    onChange={(e) => handleChangeInput(e, 'req_password')}
                    error={messages?.req_password?.message !== ''}
                    helperText={messages?.req_password?.message}
                  />
                </FormControl>
              </Col>
            </Row>

            <Row style={{ marginTop: 20 }}>
              <Col span="24">
                <Button type="primary" onClick={submitPassword}>
                  Change password
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </MyAccount>
  )
}

export default Setting
