import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'antd'
import './Index.scss'
import actions from '../../../../../../../store/order/actions'
import InputMask from 'react-input-mask'

const ApplicantInfo = (props) => {
  const [messages, setMessages] = useState({})

  let errorFirstNameJsx = null
  let errorLastNameJsx = null
  let errorPhoneJsx = null
  let errorEmailJsx = null

  const inputFirstNameClasses = []
  const inputPhoneClasses = []
  const inputLastNameClasses = []
  const inputEmailClasses = []

  useEffect(() => {
    if (props.errors !== null) {
      setMessages({ ...props.errors })
    }
  }, [props.errors])

  if (messages.first_name && messages.first_name.validateStatus === 'error' && messages.first_name) {
    errorFirstNameJsx = (
      <div className="ep-error-message">
        <div>{messages.first_name.message}</div>
      </div>
    )

    inputFirstNameClasses.push('ep-first-name-error')
  }

  if (messages.last_name && messages.last_name.validateStatus === 'error' && messages.last_name) {
    errorLastNameJsx = (
      <div className="ep-error-message">
        <div>{messages.last_name.message}</div>
      </div>
    )

    inputLastNameClasses.push('ep-last-name-error')
  }

  if (messages.phone && messages.phone.validateStatus === 'error' && messages.phone) {
    errorPhoneJsx = (
      <div className="ep-error-message">
        <div>{messages.phone.message}</div>
      </div>
    )

    inputPhoneClasses.push('ep-phone-error')
  }

  if (messages.email && messages.email.validateStatus === 'error' && messages.email) {
    errorEmailJsx = (
      <div className="ep-error-message">
        <div>{messages.email.message}</div>
      </div>
    )

    inputEmailClasses.push('ep-email-error')
  }

  const handleChange = (e, name) => {
    let rename

    if (name === 'firstName') {
      rename = 'first_name'
    } else if (name === 'lastName') {
      rename = 'last_name'
    } else {
      rename = name
    }

    props.setErrors({
      ...props.errors,
      [rename]: {
        message: '',
        validateStatus: null,
      },
    })

    props.setOrderApplicantInfo({
      ...props.applicantInfo,
      [name]: e.target.value,
    })
  }

  const auth = localStorage.getItem('auth')

  return (
    <>
      <div className="ep-applicant-info-title">Applicant Information</div>
      <div className="ep-form-personal-info">
        <div className="ep-tech-form">
          <Row className="ApplicantRowPayment">
            <Col span={24}>
              <div className="ep-input-person-name-container">
                <i className="ep-icon ep-icon-person ep-icon-absolute" />
                <div className="ep-input-person-name-inputs">
                  <div>
                    <input
                      className={`ep-input-person-name ${inputFirstNameClasses.join(' ')}`}
                      type="text"
                      placeholder="First Name"
                      defaultValue={props.applicantInfo.firstName}
                      onChange={(e) => handleChange(e, 'firstName')}
                    />
                    {errorFirstNameJsx}
                  </div>
                  <div>
                    <input
                      className={`ep-input-person-name ${inputLastNameClasses.join(' ')}`}
                      type="text"
                      placeholder="Last Name"
                      defaultValue={props.applicantInfo.lastName}
                      onChange={(e) => handleChange(e, 'lastName')}
                    />
                    {errorLastNameJsx}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="ApplicantRowPayment">
            <Col span={24}>
              <input
                className={`ep-input-email ${inputEmailClasses.join(' ')}`}
                type="email"
                placeholder="Email Address"
                defaultValue={props.applicantInfo.email}
                onChange={(e) => handleChange(e, 'email')}
                readOnly={auth ? true : false}
              />
              {errorEmailJsx}
            </Col>
          </Row>
          <Row className="ApplicantRowPayment">
            <Col span={24}>
              <InputMask
                className={`ep-input-phone ${inputPhoneClasses.join(' ')}`}
                type="tel"
                placeholder="Phone Number"
                mask="999 999 9999"
                defaultValue={props.applicantInfo.phone}
                onChange={(e) => handleChange(e, 'phone')}
              />
              {errorPhoneJsx}
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    applicantInfo: state.order.orderReducer.applicantInfo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrderApplicantInfo: (payload) => dispatch(actions.setOrderApplicantInfo(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantInfo)
