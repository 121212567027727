/**
 * Get auth information from local storage.
 * @returns {null|any}
 */
export const getAuthData = () => {
  const authStr = localStorage.getItem('auth')

  if (authStr !== null) {
    const authJSON = JSON.parse(authStr)
    if (typeof authJSON.access_token !== 'undefined') {
      return authJSON
    }
  }

  return null
}
