import axios from 'axios'

/**
 * Store appointment.
 *
 * @param data
 * @param token
 * @returns {Promise<AxiosResponse<T>>}
 */
export const store = (data, token = null) => {
  let config = {}

  if (token) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  }

  return axios.post(`${process.env.REACT_APP_API_URL}/customer/appointments`, data, config)
}

/**
 * Get appointment.
 *
 * @param id
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getAppointment = (id) => {
  let config = {}
  const auth = JSON.parse(localStorage.getItem('auth'))

  if (auth && typeof auth.access_token !== 'undefined') {
    config = {
      headers: {
        Authorization: `Bearer ${auth.access_token}`,
      },
    }
  }

  return axios.get(`${process.env.REACT_APP_API_URL}/appointments/${id}`, config)
}

/**
 * Get appointment.
 *
 * @param inspection_date_time
 * @returns {string}
 */
export const getAppointmentType = (inspection_date_time) => {
  return inspection_date_time ? 'customer' : 'inspector'
}

/**
 * Get status
 * @param statusId
 * @returns {string}
 */
export const getStatus = (statusId) => {
  switch (statusId) {
    case 0:
      return 'Awaiting approve'
    case 1:
      return 'Awaiting execution'
    case 2:
      return 'Inspected'
    case 3:
      return 'Canceled'
    default:
      return '-'
  }
}
