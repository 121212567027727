import React from 'react'
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts'

const TiltedAxisTick = (props) => {
  const { x, y, payload } = props

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-90)">
        {payload.value}
      </text>
    </g>
  )
}

const Chart = (props) => {
  return (
    <div className="statistic-chart">
      <LineChart width={850} height={200} data={props.data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={<TiltedAxisTick />} interval={0} />
        <YAxis interval={1} />
        <Tooltip />
        <Line type="monotone" dataKey="count" stroke="#f3cb4f" activeDot={{ r: 8 }} />
      </LineChart>
    </div>
  )
}

export default Chart
