import { dealer } from 'api/dealer'
import { inspector } from 'api/inspector'
import { customer } from 'api/customer'
import React, { useEffect, useState } from 'react'

const UserWelcome = () => {
  const styles = [
    {
      color: '#1890ff',
      paddingRight: '15px',
      textAling: 'right',
    },
    {
      textAlign: 'right',
      paddingRight: '20px',
      paddingTop: '15px',
    },
  ]

  const [name, setName] = useState('User User')

  useEffect(() => {
    const userData = localStorage.getItem('auth')
    const userDataJSON = JSON.parse(userData)

    if (userDataJSON) {
      if (userDataJSON.customer) {
        customer().then((res) => {
          setName(`${res.data.first_name} ${res.data.last_name}`)
        })
      } else if (userDataJSON.technician) {
        inspector().then((res) => {
          setName(`${res.data.first_name} ${res.data.last_name}`)
        })
      } else if (userDataJSON.user.role_id === 6) {
        dealer().then((res) => {
          setName(`${res.data.name}`)
        })
      } else {
        setName(`${userDataJSON.user.first_name} ${userDataJSON.user.last_name}`)
      }
    }
  }, [])

  return (
    <div>
      <p style={styles[1]}>
        Hello, <span style={styles[0]}>{name}</span>
      </p>
    </div>
  )
}

export default UserWelcome
