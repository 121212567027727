import React from 'react'
import Container from '../Container'
import Line from './components/Line'
import './Inspection-1.scss'
import { Col, Row } from 'antd'
import EpButton from '../../components/buttons/Button'

const Inspection = () => {
  return (
    <Container>
      <div>
        <Row>
          <Col span={24}>
            <div className="ep-header" style={{ marginTop: '138px' }}>
              The Caro Inspection Checklist
            </div>
            <p className="ep-sub-header">
              When is a long, boring list a great thing? When it&apos;s a gigundo list of dozens of individual steps we take to make sure that every car is up to
              Beepi&apos;s elevated standards before we offer it to our community. Every car that we offer for sale is not only certified - it&apos;s Beepi Certified!
            </p>
          </Col>
        </Row>
        <Row style={{ marginTop: '39px' }}>
          <Col span={24}>
            <EpButton type="primary" style={{ marginRight: '9px' }}>
              Start Inspection
            </EpButton>
            <EpButton>Contact Us</EpButton>
          </Col>
        </Row>
        <i className="icon-heart"></i>
        <i className="icon-like"></i>
        <i className="icon-like-blue"></i>
      </div>

      <div className="ep-blocks-container">
        <div className="ep-block-a-0">
          <h3>
            The Basics: <br />
            Visual and Functional Assessment
          </h3>
          <div className="ep-col-container-0">
            <div className="ep-col">
              <div className="ep-list-0">
                <ul>
                  <li>Make/Model</li>
                  <li>Paint / Trim Color</li>
                  <li>Body Type</li>
                  <li>Mileage</li>
                  <li>Title & Registration</li>
                  <li>Insurance Card</li>
                  <li>Smog Receipt</li>
                  <li>No Open Recalls</li>
                </ul>
              </div>
            </div>
            <div className="ep-col">
              <div className="block-img-a-0">
                <Line width={232} rotate={48} point={{ x: 386, y: 147 }} label="VIN matching" />
                <Line width={232} rotate={319} point={{ x: 602, y: 229 }} label="All parts can be operated and started" />
              </div>
            </div>
          </div>
        </div>
        <div className="ep-block-a-1">
          <h2>Checking the exterior with a fine-tooth comb</h2>
          <div className="ep-col-container-1">
            <div className="ep-col">
              <div className="block-img-a-1-0">
                <Line width={87} rotate={137} point={{ x: 129, y: 30 }} label="Roof" />
                <Line width={87} rotate={168} point={{ x: 178, y: 35 }} label="Windshield" />
                <Line width={87} rotate={168} point={{ x: 264, y: 115 }} label="Headlights" />
              </div>
            </div>
            <div className="ep-col">
              <div className="block-img-a-1-1">
                <Line width={227} rotate={20} point={{ x: 420, y: 43 }} label="Rear Window" labelPosition={{ left: -164, top: 0 }} />
                <Line width={246} rotate={66} point={{ x: 521, y: 139 }} label="Trunk Lid / Hatch / Tailgate" />
                <Line width={212} rotate={279} point={{ x: 436, y: 85 }} label="Tail Lights Rear Bumper Pillars" />
                <Line width={251} rotate={303} point={{ x: 345, y: 90 }} label="Fuel Filler Door" />
                <Line
                  width={166}
                  rotate={323}
                  point={{ x: 193, y: 202 }}
                  label={(() => {
                    return (
                      <div style={{ textAlign: 'left' }}>
                        Door Skins
                        <br />
                        Rocker Sills
                        <br />
                        Body Alignment
                        <br />
                        No Signs of Collision
                        <br />
                        Damage
                        <br />
                      </div>
                    )
                  })()}
                  labelPosition={{ left: -120, top: 0 }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="ep-block-a-2">
          <h2>Does Everything Work the Way It Should?</h2>
          <div className="ep-col-container-2">
            <div className="ep-col">
              <div className="ep-list-2">
                <ul>
                  <li>Lights</li>
                  <li>Headlights</li>
                  <li>Brake Lights</li>
                  <li>Hazard Flashers</li>
                  <li>Backup Lights</li>
                  <li>Convertible Top (if applicable)</li>
                </ul>
              </div>
            </div>
            <div className="ep-col">
              <div className="block-img-a-2">
                <Line width={281} rotate={20} point={{ x: 505, y: 5 }} label="Trunk Handle" labelPosition={{ left: -185, top: 0 }} />
                <Line width={200} rotate={254} point={{ x: 589, y: 168 }} label="Door Handles" labelPosition={{ left: -120, top: 0 }} />
                <Line width={195} rotate={244} point={{ x: 469, y: 157 }} label="Mirrors" />
                <Line width={358} rotate={209} point={{ x: 260, y: 237 }} label="Turn Signals" labelPosition={{ left: -220, top: 0 }} />
                <Line width={248} rotate={205} point={{ x: 179, y: 278 }} label="Headlights" labelPosition={{ left: -160, top: 0 }} />
                <Line width={166} rotate={209} point={{ x: 221, y: 336 }} label="License Plate Light" labelPosition={{ left: -120, top: 0 }} />
              </div>
            </div>
          </div>
        </div>
        <div className="ep-block-a-3">
          <h2>
            Thorough Visual Inspection <br />
            (More In Depth than the TSA)
          </h2>
          <div className="ep-col-container-3">
            <div className="ep-col">
              <div className="ep-list-3">
                <ul>
                  <li>Door Panels</li>
                  <li>Carpet / Floor Maths</li>
                  <li>Cup Holders</li>
                  <li>Seats and Seat Belts</li>
                  <li>Headliner and Pillars</li>
                  <li>Trunk Interior</li>
                  <li>Trunk Tools</li>
                  <li>Trunk Floor Plan</li>
                </ul>
              </div>
            </div>
            <div className="ep-col">
              <div className="block-img-a-3">
                <Line width={385} rotate={307} point={{ x: 110, y: 249 }} label="Door Weather Seal" />
                <Line width={380} rotate={311} point={{ x: 325, y: 266 }} label="Dash and Vents" />
                <Line
                  width={384}
                  rotate={288}
                  point={{ x: 395, y: 191 }}
                  label={(() => {
                    return (
                      <>
                        Trim Pieces:
                        <ul style={{ textAlign: 'left' }}>
                          <li>Dash</li>
                          <li>Center Console</li>
                          <li>Doors</li>
                        </ul>
                      </>
                    )
                  })()}
                  labelPosition={{ left: -248, top: -40 }}
                />
                <Line width={251} rotate={293} point={{ x: 552, y: 331 }} label="Glovebox and Console Compartments" />
              </div>
            </div>
          </div>
        </div>
        <div className="ep-block-a-4">
          <h2>What&apos;s Inside Has to Look Good and Work Great</h2>
          <div className="ep-col-container-4">
            <div className="ep-col">
              <div className="ep-list-4">
                <ul>
                  <li>Scan the On board</li>
                  <li>Computer</li>
                  <li>Interior Lights</li>
                  <li>Windows and Sunroof</li>
                  <li>Mirrors</li>
                  <li>Horn</li>
                  <li>Turn and Light Switches</li>
                  <li>Cup Holders</li>
                  <li>Glovebox</li>
                  <li>Seats</li>
                  <li>Seat Belts</li>
                  <li>No Doagnostic / Airbag</li>
                  <li>Codes Set</li>
                </ul>
              </div>
            </div>
            <div className="ep-col">
              <div className="block-img-a-4">
                <Line width={123} rotate={90} point={{ x: 94, y: 280 }} label="Steering Wheel Controls " />
                <Line width={119} rotate={146} point={{ x: 422, y: 229 }} label="Heating / Air Conditioning" />
                <Line width={336} rotate={146} point={{ x: 247, y: 387 }} label="Audio / Navigation" />
                <Line width={89} rotate={248} point={{ x: 434, y: 367 }} label="Speakers" />
                <Line width={89} rotate={248} point={{ x: 248, y: 391 }} label="Gear Selector" />
              </div>
            </div>
          </div>
        </div>
        <div className="ep-block-a-5">
          <h2>Under-The-Hood</h2>
          <div className="ep-col-container-5">
            <div className="ep-col">
              <div className="ep-list-5">
                <ul>
                  <li>Coolant Hoses</li>
                  <li>Belts / Tensioners/ Pulleys</li>
                  <li>P/S Fluid Level and Condition</li>
                  <li>Condition</li>
                  <li>Carrying Rails and Firewall</li>
                  <li>No Signs of Body Dust</li>
                  <li>Panels Aligment</li>
                  <li>Factory Painted Bolts and Body</li>
                  <li>Hinges</li>
                  <li>Engine Odors</li>
                </ul>
              </div>
            </div>
            <div className="ep-col">
              <div className="block-img-a-5">
                <Line width={176} rotate={106} point={{ x: 446, y: 176 }} label="Battery / Battery Cables" />
                <Line
                  width={366}
                  rotate={216}
                  point={{ x: 260, y: 160 }}
                  label={(() => {
                    return (
                      <ul>
                        <li>Engine</li>
                        <li>Coolant</li>
                        <li>Oil</li>
                        <li>P/S</li>
                        <li>A/C</li>
                      </ul>
                    )
                  })()}
                  labelPosition={{ left: -232, top: -100 }}
                />
                <Line width={267} rotate={240} point={{ x: 304, y: 214 }} label="Oil Level and Condition " />
                <Line width={212} rotate={316} point={{ x: 144, y: 214 }} label="Coolant Level and Condition" />
              </div>
            </div>
          </div>
        </div>
        <div className="ep-block-a-6">
          <h2>Wheel Assembly: Round and Round We Go</h2>
          <div className="ep-col-container-6">
            <div className="ep-col">
              <div className="ep-list-6">
                <ul>
                  <li>Tires Match</li>
                  <li>Wheel Looks and Key</li>
                  <li>Spare Tire</li>
                  <li>Brake Lining check</li>
                  <li>Brake Hose check</li>
                  <li>Brake Lining (LF / RF / LR / RR)</li>
                  <li>Tire Pressures / Depth (LF / RF / LR / RR)</li>
                </ul>
              </div>
            </div>
            <div className="ep-col">
              <div className="block-img-a-6">
                <Line width={69} rotate={296} point={{ x: 78, y: 194 }} label="Rims" />
                <Line width={68} rotate={228} point={{ x: 140, y: 206 }} label="Tire Condition" />
                <Line width={233} rotate={328} point={{ x: 505, y: 124 }} label="Suspension Bounce test" labelPosition={{ left: -98, top: 17 }} />
                <Line width={84} rotate={295} point={{ x: 551, y: 167 }} label="Lug Nuts / Bolts" />
              </div>
            </div>
          </div>
        </div>
        <div className="ep-block-a-7">
          <h2>We Never Overlook What&apos;s Under</h2>
          <div className="ep-col-container-7">
            <div className="ep-col">
              <div className="ep-list-7">
                <ul>
                  <li>Undercarriage</li>
                  <li>Transfercase</li>
                  <li>Axles</li>
                  <li>Shocks</li>
                  <li>Control Arms</li>
                  <li>Tie Rods</li>
                  <li>Exhaust System</li>
                </ul>
              </div>
            </div>
            <div className="ep-col">
              <div className="block-img-a-7">
                <Line width={161} rotate={328} point={{ x: 103, y: 225 }} label="Suspension Components" />
                <Line width={75} rotate={295} point={{ x: 171, y: 249 }} label="Differential" />
                <Line width={52} rotate={291} point={{ x: 395, y: 265 }} label="Engine" />
              </div>
            </div>
          </div>
        </div>
        <div className="ep-block-a-8">
          <h2>Finally, We Get Behind the Wheel</h2>
          <div className="ep-col-container-8">
            <div className="ep-col">
              <div className="ep-list-8">
                <ul>
                  <li>Cold Engine performance</li>
                  <li>Throttle performance during cold start</li>
                  <li>Vehicle performance during warm-up conditions</li>
                  <li>Engine fan(s) performance</li>
                  <li>No abnormal noise or vibration from engine</li>
                </ul>
              </div>
            </div>
            <div className="ep-col">
              <div className="block-img-a-8">
                <Line width={330} rotate={344} point={{ x: 293, y: 125 }} label="Engine Performance" />
                <Line width={215} rotate={331} point={{ x: 245, y: 203 }} label="Operating Temperature" />
              </div>
            </div>
          </div>
        </div>
        <div className="ep-block-a-9">
          <h2>
            Vehicle Road Test: Performance, <br />Transmission, Steering & Suspension
          </h2>
          <div className="ep-col-container-9">
            <div className="ep-col">
              <div className="ep-list-9">
                <ul>
                  <li>Transmission / Clutch performance</li>
                  <li>Automatic Transmission performance during cold / warm up temperature</li>
                  <li>Steering has normal feel in lock-to-lock turning</li>
                  <li>Vehicle drives straight on level surface</li>
                  <li>Vehicle does not pull to the side when applying brakes</li>
                  <li>Steering wheel centered</li>
                  <li>No abnormal noise or vibration from suspension</li>
                  <li>No abnormal tire noise / road noise</li>
                  <li>No abnormal squeaks and rattles from interior</li>
                </ul>
              </div>
            </div>
            <div className="ep-col">
              <div className="block-img-a-9"></div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Inspection
