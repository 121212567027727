import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import Steps from 'components/steps'
import { Col, Row, Modal, Button } from 'antd'
import ContinueBackCmp from 'components/buttons/ContinueBackCmp'
import Container from '../../../../Container'
import { getPackages } from 'api/packages'
import actions from 'store/order/actions'
import './Index.scss'
import StepsForCarPrice from 'components/stepsForCarPrice/Steps'

const SelectPackage = (props) => {
  const history = useHistory()
  const [packages, setPackages] = useState([])
  const [activeId, setActiveId] = useState('')
  const [modal, setModal] = useState(false)
  const beforeCarPrice = useLocation()?.state?.beforeCarPrice;

  useEffect(() => {
    window.scrollTo(0, 0)

    if (props.applicantInfo.email === '') {
      history.push('/order/inspection/select-car')
    }

    let auth = localStorage.getItem('auth')
    auth = auth && JSON.parse(auth)

    if (auth?.technician) {
      history.push('/inspector/my-account/statistic')
    }

    getPackages().then((_packages) => {
      setPackages(_packages.data)
    })

    if (props.inspection.inspectionPackage.name && props.inspection.inspectionPackage.name.length > 0) {
      setActiveId(props.inspection.inspectionPackage.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePackage = (id) => {
    const selectedPackage = packages.find((item) => item.id === id)

    props.setOrderInspection({
      ...props.inspection,
      inspectionPackage: { ...selectedPackage },
    })

    setActiveId(id)
  }

  const handleSelectCategory = (e, categoryId) => {
    props.setOrderInspection({ ...props.inspection, categoryId })
  }

  const onHandleContinue = () => {
    if (!props.inspection.inspectionPackage.name) {
      setModal(true)
    }
  }

  return (
    <Container>
      {!beforeCarPrice && <Steps style={{ marginTop: '35px' }}/>}
      {beforeCarPrice && <StepsForCarPrice style={{ marginTop: '35px' }}/>}
      <div className="ep-order-inspection-container" style={{ marginTop: '50px' }}>
        <h2>Select Inspection Package</h2>

        <div className="packages-container">
          {packages.length > 0 &&
            packages.map((item, index) => {
              return (
                <div
                  key={`package-${index}`}
                  className={`package-item ${activeId === item.id && `package-item-active`}`}
                  onClick={() => handleChangePackage(item.id)}
                >
                  <div className={`package-name-and-price ${activeId === item.id && `package-name-and-price-active`}`}>
                    <span className="ep-label">{item.name}</span>
                    <span className="ep-price">${item.price}</span>
                  </div>
                  <div className={`package-descr ${activeId === item.id && `package-descr-active`}`}>
                    <span>{item.description}</span>
                  </div>
                </div>
              )
            })}
        </div>

        <Row>
          <Col span={24}>
            <h3>Inspections Included</h3>
            <div className="ep-inspection-included-container">
              <div className={`ep-menu-item active`} onClick={(e) => handleSelectCategory(e, 1)}>
                <i className={`icon-1 active`} />
                <div className="ep-menu-title">Exterior</div>
              </div>
              <div className={`ep-menu-item active`} onClick={(e) => handleSelectCategory(e, 2)}>
                <i className={`icon-2 active`} />
                <div className="ep-menu-title">Interior</div>
              </div>
              <div className={`ep-menu-item ${(activeId === 2 || activeId === '') && 'active'}`} onClick={(e) => handleSelectCategory(e, 3)}>
                <i className={`icon-3 ${(activeId === 2 || activeId === '') && 'active'}`} />
                <div className="ep-menu-title">Frame</div>
              </div>
              <div className={`ep-menu-item active`} onClick={(e) => handleSelectCategory(e, 4)}>
                <i className={`icon-4 active`} />
                <div className="ep-menu-title">Mechanical</div>
              </div>
              <div className={`ep-menu-item active`} onClick={(e) => handleSelectCategory(e, 5)}>
                <i className={`icon-5 active`} />
                <div className="ep-menu-title">Electric</div>
              </div>
              <div className={`ep-menu-item active`} onClick={(e) => handleSelectCategory(e, 6)}>
                <i className={`icon-6 active`} />
                <div className="ep-menu-title">Tires</div>
              </div>
              <div className={`ep-menu-item active`} onClick={(e) => handleSelectCategory(e, 7)}>
                <i className={`icon-7 active`} />
                <div className="ep-menu-title">Test Drive</div>
              </div>
              <div className={`ep-menu-item ${activeId === '' && 'active'}`} onClick={(e) => handleSelectCategory(e, 8)}>
                <i className={`icon-8 ${activeId === '' && 'active'}`} />
                <div className="ep-menu-title">Video</div>
              </div>
              <div className={`ep-menu-item active`} onClick={(e) => handleSelectCategory(e, 9)}>
                <i className={`icon-9 active`} />
                <div className="ep-menu-title">History</div>
              </div>
              <div className={`ep-menu-item ${activeId === '' && 'active'}`}>
                <i className={`icon-10 ${activeId === '' && 'active'}`} />
                <div className="ep-menu-title">Caro Score</div>
              </div>
            </div>
          </Col>
        </Row>
        <ContinueBackCmp
          route={
            props.inspection.inspectionPackage.name && props.inspection.inspectionPackage.name.length > 0
              ? '/order/payment'
              : '/order/inspection/select-package'
          }
          pageName="select package"
          onClick={onHandleContinue}
          beforeCarPrice={beforeCarPrice}
        />
      </div>

      <Modal
        style={{ display: 'flex', justifyContent: 'center' }}
        visible={modal}
        onOk={() => setModal(false)}
        onCancel={() => setModal(false)}
        footer={[
          <Button key="submit" type="primary" onClick={() => setModal(false)}>
            Ok
          </Button>,
        ]}
      >
        <span style={{ display: 'block', margin: '20px 100px' }}>PLEASE, SELECT PACKAGE!</span>
      </Modal>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    inspection: state.order.orderReducer.inspection,
    applicantInfo: state.order.orderReducer.applicantInfo,
    car: state.order.orderReducer.car,
    srcCarInfo: state.order.orderReducer.car.detail.src_car_info,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrderInspection: (payload) => dispatch(actions.setOrderInspection(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectPackage)
