import React from 'react'
import { Route, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom'
import Inspection from './pages/inspection'
import GetApp from './pages/getApp'
import Faq from './pages/FAQ'
import TermsOfUse from './pages/TermsOfUse'
import PrivacyPolicy from './pages/PrivacyPolicy'
import BecamePartner from './pages/BecamePartner'
import Support from './pages/Support'

import CustomerInspectionReportPage1 from './pages/customer/inspection/report/page1'
import CustomerInspectionReportPage2 from './pages/customer/inspection/report/page2'
import CustomerInspectionReportPage3 from './pages/customer/inspection/report/page3'
import CustomerInspectionReportPage4 from './pages/customer/inspection/report/page4'

import CustomerMyAccountSetting from './pages/customer/myAccount/pages/setting'
import CustomerMyAccountPreviousAppointment from './pages/customer/myAccount/pages/previousAppointment'
import CustomerMyAccountNotifications from './pages/customer/myAccount/pages/notifications'
import CustomerMyAccountCurrentAppointments from './pages/customer/myAccount/pages/currentAppointments'
import CustomerMyAccountContactSupport from './pages/customer/myAccount/pages/contactSupport'

import InspectorMyAccountSetting from './pages/_inspector/myAccount/pages/setting'
import InspectorMyAccountStatistic from './pages/_inspector/myAccount/pages/statistic'
import InspectorMyAccountScheduled from './pages/_inspector/myAccount/pages/scheduled'
import InspectorMyAccountInspected from './pages/_inspector/myAccount/pages/previous'
import InspectorMyAccountContactSupport from './pages/_inspector/myAccount/pages/contactSupport'

import SignIn from './pages/customer/signIn'
import SignUpPicker from './pages/SignUpPicker'
import CustomerSignUp from './pages/customer/signUp'
import InspectorSignUp from './pages/inspector/signUp'
import InspectorCleanSignUp from './pages/inspector/signUp/form'
import MechanicShopSignUp from './pages/MechanicShop/signUp'
import MechanicShopCleanSignUp from './pages/MechanicShop/signUp/form'
import CustomerForgotPassword from './pages/customer/forgotPassword'
import RestorePassword from './pages/customer/restorePassword'

import SelectCar from './pages/customer/inspection/newOrder/Inspection/SelectCar/Index'
import GetInstantCarPrice from './pages/customer/inspection/newOrder/Inspection/GetInstantCarPrice/Index'
import ShowCarPrice from './pages/customer/inspection/newOrder/Inspection/ShowCarPrice/Index'
import SelectPackage from './pages/customer/inspection/newOrder/Inspection/SelectPackage/Index'

import SelectLocationType from './pages/customer/inspection/newOrder/Location/SelectLocationType/Index'
import SelectShop from './pages/customer/inspection/newOrder/Location/SelectShop/Index'

import Payment from './pages/customer/inspection/newOrder/Payment/Index'
import Success from './pages/customer/inspection/newOrder/Success/Index'

import Test from './pages/test'
import 'antd/dist/antd.css'
import './App.scss'

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <SelectCar />
        </Route>
        <Route exact path="/get-instant-car-price">
          <GetInstantCarPrice />
        </Route>
        <Route exact path="/car-price">
          <ShowCarPrice />
        </Route>
        <Route exact path="/inspection">
          <Inspection />
        </Route>
        <Route exact path="/terms-of-use" component={TermsOfUse} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/get-an-app" component={GetApp} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/became-a-partner" component={BecamePartner} />
        <Route exact path="/support" component={Support} />
        <Route exact path="/test" component={Test} />

        <Route exact path="/customer/inspection/report/page-1" component={CustomerInspectionReportPage1} />
        <Route exact path="/customer/inspection/report/page-2" component={CustomerInspectionReportPage2} />
        <Route exact path="/customer/inspection/report/page-3" component={CustomerInspectionReportPage3} />
        <Route exact path="/customer/inspection/report/page-4" component={CustomerInspectionReportPage4} />

        <Route exact path="/customer/my-account/setting" component={CustomerMyAccountSetting} />
        <Route exact path="/customer/my-account/previous-appointment" component={CustomerMyAccountPreviousAppointment} />
        <Route exact path="/customer/my-account/notifications" component={CustomerMyAccountNotifications} />
        <Route exact path="/customer/my-account/current-appointments" component={CustomerMyAccountCurrentAppointments} />
        <Route exact path="/customer/my-account/contact-support" component={CustomerMyAccountContactSupport} />

        <Route exact path="/inspector/my-account/setting" component={InspectorMyAccountSetting} />
        <Route exact path="/inspector/my-account/statistic" component={InspectorMyAccountStatistic} />
        <Route exact path="/inspector/my-account/scheduled" component={InspectorMyAccountScheduled} />
        <Route exact path="/inspector/my-account/inspected" component={InspectorMyAccountInspected} />
        <Route exact path="/inspector/my-account/contactSupport" component={InspectorMyAccountContactSupport} />

        <Route exact path="/sign-in" render={() => (localStorage.getItem('auth') ? <Redirect to="/" /> : <SignIn />)} />
        <Route exact path="/sign-up" component={SignUpPicker} />
        <Route exact path="/customer/sign-up" render={() => (localStorage.getItem('auth') ? <Redirect to="/" /> : <CustomerSignUp />)} />
        <Route exact path="/inspector/sign-up" component={InspectorSignUp} />
        <Route exact path="/mechanic-shop/sign-up" component={MechanicShopSignUp} />

        <Route exact path="/inspector/clean/sign-up" component={InspectorCleanSignUp} />
        <Route exact path="/mechanic-shop/clean/sign-up" component={MechanicShopCleanSignUp} />

        <Route exact path="/customer/forgot-password" render={() => (localStorage.getItem('auth') ? <Redirect to="/" /> : <CustomerForgotPassword />)} />
        <Route exact path="/reset/password/:token" component={RestorePassword} />
        <Route exact path="/order/inspection/select-car" component={SelectCar} />
        <Route exact path="/order/inspection/select-package" component={SelectPackage} />
        <Route exact path="/order/location/select-location-type" component={SelectLocationType} />
        <Route exact path="/order/location/select-shop" component={SelectShop} />
        <Route exact path="/order/payment" component={Payment} />
        <Route exact path="/order/success" component={Success} />
      </Switch>
    </Router>
  )
}

export default App
