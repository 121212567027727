import React from 'react'
import Container from '../Container'
import { Col, Row } from 'antd'

import CheckItem from './checkItem'

import './styles.scss'

const Faq = () => {
  return (
    <Container>
      <Row>
        <Col span={24}>
          <div className="page-header-title">Privacy Policy</div> <br />
          <div className="subtitle-pp">Your Privacy is Important to Us</div>
          <div className="subtitle-pp">
            This Privacy Policy is part of Caro&apos;s Terms of Use (https://www.caro.com/legal/terms) and covers the treatment of user information, including
            Personally Identifiable Information (defined below), obtained by Caro, including information obtained when you access or log in to Caro, register to
            receive content, and/or take any steps to make a purchase or sale through Caro. Among other things, this Privacy Policy describes the type of user
            information Caro obtains either from you, your browser, or third parties that partner with Caro, explains how Caro uses this information, identifies
            the types of third parties that may receive or collect user information from or through Caro, and explains how you may review and modify certain
            information that Caro collects. <br />
            <br />
            By visiting Caro, viewing our content, registering for an account, and/or using any of our services, you acknowledge that you accept the practices
            and policies outlined in this Privacy Policy. In addition, you agree that Caro has fulfilled any obligation to provide you with notice of its data
            use, storage, and sharing practices, and that Caro will fulfill any ongoing obligation to provide you with notice of its these practices by
            maintaining a current version of this Privacy Policy at https://www.Caro.com/legal/privacy. <br />
            <br />
            This Privacy Policy does not apply to the practices of companies that Caro does not own or control, or to individuals whom Caro does not employ or
            manage, including any of the third parties to which Caro may disclose user information as set forth in this Policy.
            <br />
            <br />
            As used in this Privacy Policy, “Caro” refers to all websites, mobile sites, applications, and other properties or services owned or operated by
            Caro, Inc.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">1. What Types of User Information Does Caro Obtain?</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            Caro may obtain the following types of information from you or concerning your computer or device (“Information”), which may include information
            that can be used to identify you as specified below (“Personally Identifiable Information”):
            <br />
            <br />
            <CheckItem>Name</CheckItem>
            <CheckItem>Email Address </CheckItem>
            <CheckItem>Mailing Address</CheckItem>
            <CheckItem>Telephone Number(s)</CheckItem>
            <CheckItem>Demographic Information (e.g.,age, gender, interests, and preferences)</CheckItem>
            <CheckItem>Information Received from Third Parties (detailed below)</CheckItem>
            <CheckItem>Credit Card Information</CheckItem>
            <CheckItem>Social Networking Handle or Username</CheckItem>
            <CheckItem>Photo </CheckItem>
            <CheckItem>Vehicle Information (e.g., make, model, color, condition)</CheckItem>
            <CheckItem>Activities or Content Viewedon Caro</CheckItem>
            <CheckItem>Information Collected Automatically (detailed below)</CheckItem>
            <br />
            <br />
            In certain cases, we may request that you provide some of the Information identified above in order to obtain specific products or services (such as
            registering for a Caro account or making a purchase or sale on Caro). If you choose not to provide the Information we request, you may still use
            Caro, but you may be unable to access certain features or services. <br />
            <br />
            When you register to receive any products or services from Caro or provide information to Caro in any other manner, you agree to provide only true,
            accurate, current and complete information.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">2. Information Collected Automatically</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            Caro may automatically receive and log certain types of information when you visit or interact with our websites, services, mobile applications or
            other products, including:
            <br />
            <br />
            <CheckItem>Browser Information</CheckItem>
            <CheckItem>Operating System Information</CheckItem>
            <CheckItem>Mobile Device Information (e.g., device identifier, mobile operating system, etc.)</CheckItem>
            <CheckItem>IP Address</CheckItem>
            <CheckItem>Content Viewed</CheckItem>
            <CheckItem>Geographic Location</CheckItem>
            <CheckItem>Connection Speed</CheckItem>
            <CheckItem>Social Networking Handle or Username</CheckItem>
            <CheckItem>Time of Visit</CheckItem>
            <CheckItem>Referring Site, Application, or Service</CheckItem>
            <CheckItem>Registration Time</CheckItem>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">3. Information Received From Third Parties</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            Consumer Reporting Agencies <br /> If you purchase or sell a car through Caro, we may receive Information about you from a credit bureau such as
            Experian to help us verify your identity, which may include certain Personally Identifying Information identified above.
            <br />
            <br />
            Automobile Analysts <br /> If you list a car for sale through Caro, we may receive Information from our service providers, including pre-purchase
            inspectors and vehicle analysts (e.g., AutoCheck and Edmunds), about you and the value and/or characteristics of your car, which may include certain
            Personally Identifying Information identified above.
            <br />
            <br />
            Mail Service Providers <br /> If you list or purchase a car through Caro, we may receive Information about you from FedEx, the United States Postal
            Service, or other mail service providers, about the address at which Caro will pick up or deliver your car. This Information may include certain
            Personally Identifying Information identified above.
            <br />
            <br />
            Advertisers <br /> Caro uses third parties to advertise its products and services. If you provide information to these advertisers, including by
            clicking on or otherwise responding to an advertisement or information request form, we may receive Information about you, which may include certain
            Personally Identifying Information identified above.
            <br />
            <br />
            Third Party Websites/Social Networking Sites <br /> If you choose to use any third party website or service that is integrated with Caro, including
            a third party social networking, such as Facebook, Twitter, or Google Plus (“Third Party Websites”) we may receive Information, including Personally
            Identifying Information, from such Third Party Websites, including, but not limited to:
            <br />
            <br />
            <CheckItem>Name</CheckItem>
            <CheckItem>Nickname or Username</CheckItem>
            <CheckItem>Unique Third Party Website Identifier (e.g., Facebook User ID)</CheckItem>
            <CheckItem>Biographical or Demographic Information (e.g., professional title, gender, college attended, etc.) </CheckItem>
            <CheckItem>Likes and Interests</CheckItem>
            <CheckItem>Contacts / Friends / Associations</CheckItem>
            <CheckItem>Any Information that is Publicly Available on Third Party Websites</CheckItem>
            <CheckItem>Social Networking Handle or Username</CheckItem>
            <CheckItem>Photo </CheckItem>
            <br />
            <br />
            For example, Caro may offer the ability to use certain Third Party Websites to facilitate your registration on Caro. We may also use information
            about your profile and connections on Third Party Websites to allow you to identify purchasers and/or sellers with whom you have a connection or to
            personalize your experience. In addition, Caro offers “plug ins” and “widgets” from various Third Party Websites that allow you to share Caro
            content off of our service, as discussed below.
            <br />
            <br />
            Your decision to use, or share with, a Third Party Website when accessing or using Caro products and services is completely voluntary. Caro is not
            responsible for compliance with the policies or practices of any Third Party Website. You should ensure that you are comfortable with the
            information such Third Party Websites may make available to Caro by reviewing those Websites’ privacy policies and service terms, and by modifying
            your privacy settings and preferences on those Services.
            <br />
            <br />
            Any information we receive from Third Party Websites will be governed by this Privacy Policy.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">4. How Do We Use This Information?</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            We use the Information we obtain from or concerning you or your computer or device to provide Caro products and services, fulfill your requests,
            improve our products and services, personalize and tailor your experience on Caro, operate our business, communicate with you regarding our
            business, and understand how users are engaging with Caro.
            <br />
            <br />
            In addition, we may use Information from or concerning you or your computer or device to facilitate the delivery of content or advertisements that
            we believe may be of interest to you, or to communicate with you about new or existing products and services or special offers. For example, we may
            periodically send promotional materials or notifications related to our products and services to the email address associated with your account.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">5. Caro&apos;s Use of Cookies</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            Caro may place “cookies” on your computer or device that may contain anonymous Information that is associated with you. Cookies are small text files
            a website uses to recognize users, and are typically placed on your hard disk by a web server. Cookies contain information that can later be read by
            a web server in the domain (here, Caro) that issued the cookie to you or that, in some cases, can be read by a third party domain.
            <br />
            <br />
            Our cookies may record Information about your use of Caro so that we can provide a continuous and more personalized experience for you, including
            for one or more of the following purposes: to help identify visitors; assess usage patterns; identify preferences; diagnose problems with our
            servers; gather demographic information; analyze trends and usage patterns; conduct research; record registration and personalized information; and
            otherwise administer Caro products and services. Caro does not alter its behavior or change its services upon receiving Do Not Track requests from a
            user’s browser.
            <br />
            <br />
            If you do not wish to have Caro place and use cookies on your computer, you should set your browser preferences to refuse cookies before accessing
            Caro. Caro is not responsible for any failure by you or your browser to accurately implement or communicate your browser preferences or settings. If
            you choose to decline cookies, you may not be able to sign in or use other interactive features of Caro that depend on cookies.
            <br />
            <br />
            <b>Caro does not control and is not responsible for the use of cookies by its third party partners. </b> The use of cookies by some of these third
            parties is discussed below (see “What Types of Information Do Third Parties Collect?”) These third parties may collect information about your online
            activities, including personally identifying information, over time and across different websites.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">6. Reviewing and Modifying Your Personally Identifying Information</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            If you have an online account with Caro, you can log in and update or change your account information here:
            <br />
            <a href="http://www.Caro.com/Dashboard">http://www.Caro.com/Dashboard</a>
            <br />
            You may unsubscribe to any of our e-mail updates or products by following the unsubscribe instructions in the body of any message (see E-mail
            Communications below) <br />
            You may control the information collected from your Facebook account by changing your Facebook account settings. <br />
            You may contact us at any time at hello@Caro.com
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">7. Disclosure of Information to Third Parties</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            Caro may disclose Information (including Personally Identifying Information) from or concerning you or your computer or device to third parties in
            the circumstances described below. By visiting, accessing, or using Caro’s products or services you consent to the disclosure of your Information to
            certain categories of third parties in the manner described below. This Privacy Policy does not apply to the policies or practices of any of these
            third parties.
            <br />
            <br />
            <b>Service Providers</b>
            <br />
            Caro relies on third party vendors and service providers to provide support and assistance for its products and services (“Service Providers”), and
            we may disclose your Information (including Personally Identifying Information) to these Service Providers to the extent necessary to support our
            products and services or as otherwise set forth below. Payment Processors Caro uses third party payment processors to process financial payments for
            certain Caro services. When you make payments to Caro and/or obtain certain Caro products or services, Caro may transfer certain Information
            (including Personally Identifying Information) to these payment processors for the sole purpose of facilitating the payment transaction, such as
            your Name, Address, and Credit Card Information.
            <br />
            <br />
            <b>Vehicle Inspectors</b>
            <br />
            Caro may use third parties to conduct pre-purchase inspections on vehicles listed for sale on Caro.Caro may provide your Information (including
            Personally Identifying Information) to such inspectors when you apply to list a vehicle for sale on Caro.
            <br />
            <br />
            <b>Vehicle History Providers</b>
            <br />
            Caro uses third parties, such as Edmunds and Auto Check, to obtain information necessary to value vehicles listed for sale on Caro. Caro may provide
            your Information (including Personally Identifying Information) to such providers when you apply to list a vehicle for sale on Caro.
            <br />
            <br />
            <b>Shipping Providers</b>
            <br />
            Caro uses third parties, such as FedEx, United States Postal Service and vehicle delivery services, to deliver certain products or information to
            you. Caro may provide your Information (including Personally Identifying Information) to such providers when you request to receive Caro’s products
            or services.
            <br />
            <br />
            <b>Web Hosts</b>
            <br />
            Caro’s website services are hosted by Rackspace, a cloud data storage platform. All Information obtained by Caro’s websites is processed and stored
            on servers that are owned and operated by Rackspace.
            <br />
            <br />
            <b>Third Party Websites</b>
            <br />
            Caro offers the ability to share Caro content on Third Party Websites, including social networking and blogging services such as Facebook, Twitter,
            and Google Plus, through “plugins” or “widgets” (such as Facebook’s “Like” button). When you elect to share content and information with others on
            Third Party Websites, Caro may transmit your Information (including Personally Identifying Information) to such Third Party Websites. This
            Information may be visible on the Third Party Website to anyone who has access to that content or Information. Your decision to share information in
            this manner is completely voluntary. You should ensure that you are comfortable with how Third Party Websites may share your Information by
            reviewing their privacy policies and/or modifying your privacy settings directly on those Third Party Websites.
            <br />
            <br />
            Caro may advertise vehicles listed for sale through Caro on other websites, such as Craigslist or eBay. Caro may provide certain Information, such
            as the vehicle characteristics and general geographic location of the vehicle, to these websites.
            <br />
            <br />
            <b>URL/Referrer</b>
            <br />
            Header Information When you visit Caro websites, certain information about the content or activities you have engaged in on Caro may be reflected in
            the Uniform Resource Locator (“URL”) for the page you are viewing on Caro (i.e., the Internet “address,” commonly displayed in the browser window).
            For example, the certain information about a vehicle you are viewing may appear in the URL for that page. In addition, if you are logged into a Caro
            account, certain account information (may be visible in the URL when you visit your account on Caro. If you leave Caro to visit a third party
            website, including when you share information through a social plug-in (like the Facebook “share” button), the information contained in the URL may
            be transmitted to the third party website by your browser.
            <br />
            <br />
            <b>Analytics & Advertising Services</b>
            <br />
            As discussed below, Caro uses third party advertisers, including ad networks, to provide advertising services to Caro. Caro also uses analytics
            products to obtain information about how users are using and interacting with Caro. These third parties may receive Information (including
            Personally Identifying Information) from Caro.
            <br />
            <br />
            <b>Law Enforcement/Legal</b>
            <br />
            Process We may disclose your Information (including Personally Identifying Information) when we believe in good faith that disclosure is necessary
            to comply with any law, regulation or legal request; enforce, apply, or investigate breaches of our terms of use and other agreements; respond to
            public requests; or protect the rights, property, user experience or safety of Caro, our employees, our users, or others. This can include
            exchanging information with other companies and organizations for fraud protection and credit risk reduction.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">8. What Types of Information Do Third Parties Collect?</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            Caro may allow third parties to collect information from users in order to process your requests or improve Caro’s website, products, services, or
            marketing efforts. We may use third parties such as Experian to obtain, compile and analyze Information (that may include Personally Identifying
            Information) about users for identity verification purposes.
            <br />
            <br />
            Caro may allow third party advertising serving companies, including ad networks (“Advertisers”), to provide other advertising services to Caro. We
            may provide Information (including Personally Identifying Information) to these advertising companies so we can offer you products, promotions, and
            offers in the future. Advertisers may use the information collected to display advertisements that are tailored to your interests or background
            and/or associate such information with your subsequent visits, purchases or other activities on other websites. Advertisers may also share this
            information with their clients or other third parties.
            <br />
            <br />
            Caro may also use third party analytics providers and products to obtain, compile and analyze Information (that may include Personally Identifying
            Information) about how users are using and interacting with Caro. Caro may provide Information (including Personally Identifying Information) to
            these analytics providers for the purpose of obtaining statistics and other information about how users are using and interacting with Caro.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}><div className="title">9. Caro has no responsibility for the technologies, tools or practices of the third parties identified in this section that provide services on or through Caro.</div></Col>
        <Col span={24}>
          <div className="subtitle-pp">
            Caro may allow third parties to collect information from users in order to process your requests or improve Caro’s website, products, services, or
            marketing efforts. We may use third parties such as Experian to obtain, compile and analyze Information (that may include Personally Identifying
            Information) about users for identity verification purposes. Caro may allow third party advertising serving companies, including ad networks
            (“Advertisers”), to provide other advertising services to Caro. We may provide Information (including Personally Identifying Information) to these
            advertising companies so we can offer you products, promotions, and offers in the future. Advertisers may use the information collected to display
            advertisements that are tailored to your interests or background and/or associate such information with your subsequent visits, purchases or other
            activities on other websites. Advertisers may also share this information with their clients or other third parties. Caro may also use third party
            analytics providers and products to obtain, compile and analyze Information (that may include Personally Identifying Information) about how users
            are using and interacting with Caro. Caro may provide Information (including Personally Identifying Information) to these analytics providers for
            the purpose of obtaining statistics and other information about how users are using and interacting with Caro.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">10. Comments and Public Posts; Sharing on Third party Websites</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            Caro encourages active participation by users, including through user-generated comments or content on Caro. However, please be aware that if you
            voluntarily disclose Information, personal or otherwise, on Caro, that Information can be viewed, collected and used by others, including other
            users, search engines, advertisers, third party applications developers, and anyone with access to Caro. Caro has no control over the use,
            collection or disclosure of any Information that you post publicly on Caro. information about how users are using and interacting with Caro.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">11. Change of Control</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            If Caro, or substantially all of its assets, is acquired by another company or party, user Information will be one of the assets transferred or
            acquired by the purchaser. You acknowledge that such transfers may occur, and that any purchaser of Caro or its assets may continue to collect, use
            and disclose your Information as set forth in this Policy.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">12. Security and Protection of Personally Identifying Information</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            We have implemented reasonable security measures and practices in an effort to protect the confidentiality of your Personally Identifying
            Information (other than Personally Identifying Information that you have elected to disclose to others). However, no security system is
            impenetrable. It may be possible for third parties to intercept or access information you provide to Caro. Caro cannot guarantee the security of
            your Personally Identifying Information, nor can we guarantee that information you supply will not be intercepted while being transmitted to Caro.
            It is important for you to protect against unauthorized access to your password(s) and computer or device, and to log off when using any shared
            computer.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">13. Email Communications</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            We may contact you by e-mail or other equivalent electronic communications if you access Caro products or services or other content or services
            provided by Caro. By visiting or using Caro content, products or services, you specifically consent to the receipt of these e-mail message
            communications. If you do not want to receive emails or other communications from us, please notify us by email at hello@Caro.com and include
            sufficient information for us to identify your account, including your Name, Email Address and the specifics of your request. You may also
            unsubscribe through the instructions included in the e-mail communication. If you unsubscribe, Caro may still contact you via email for
            administrative purposes or concerning any products, services or events for which you have registered or are using.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">14. Children&apos;s Privacy</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">Caro does not knowingly collect personal information from children under 13 years of age.</div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">15. Changes to the Privacy Policy</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            Caro encourages active participation by users, including through user-generated comments or content on Caro. However, please be aware that if you
            voluntarily disclose Information, personal or otherwise, on Caro, that Information can be viewed, collected and used by others, including other
            users, search engines, advertisers, third party applications developers, and anyone with access to Caro. Caro has no control over the use,
            collection or disclosure of any Information that you post publicly on Caro. information about how users are using and interacting with Caro.
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">16. How to Contact Us</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">
            Caro welcomes any questions or comments you may have regarding this Privacy Policy or its implementation. Any such questions or comments should be
            submitted via email to hello@Caro.com or directed to the address below. We will undertake reasonable efforts to resolve or address your concern.
            <br />
            <br />
            Caro
            <br />
            ATTN: Caro - Privacy
            <br />
            240 3rd St, Suite 200 Los Altos, CA 94022
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="title">17. Effective Date of this Privacy Policy</div>
        </Col>
        <Col span={24}>
          <div className="subtitle-pp">This Privacy Policy is effective as of January 1, 2014</div>
        </Col>
      </Row>
    </Container>
  )
}

export default Faq
