import React, { useState } from 'react'
import './signIn.scss'

import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Row, Col, message } from 'antd'

import EmailSignIn from './email/EmailSignIn'
import PasswordSignIn from './password/PasswordSignIn'

import Container from '../Container'
import { login } from '../../../api/user'
import '../../../Icons.scss'

import Validator from 'validatorjs'

const SignIn = () => {
  const history = useHistory()

  const [auth, setAuth] = useState({
    email: '',
    password: '',
  })

  const [messages, setMessages] = useState({
    email: {
      message: '',
      validateStatus: null,
    },
    password: {
      message: '',
      validateStatus: null,
    },
  })

  /**
   * Handle change input.
   *
   * @param e
   * @param name
   */
  const handleChange = (e, name) => {
    setAuth({ ...auth, [name]: e.target.value })
    setMessages({
      ...messages,
      [name]: {
        message: '',
        validateStatus: null,
      },
    })
  }

  const handleSubmit = () => {
    const data = {
      ...auth,
    }

    const rules = {
      email: 'required|email',
      password: ['required', 'regex:/^[a-zA-Z0-9!@#$%^&*)(+=._-]{8,24}$/'],
    }

    const customMessages = {
      required: 'The :attribute field is required',
      'regex.password': 'The password should be 8 to 24 chars long',
    }

    const validation = new Validator(data, rules, customMessages)

    if (validation.fails()) {
      const errors = validation.errors.all()
      let msg = { ...messages }

      for (let prop in errors) {
        msg = {
          ...msg,
          [prop]: { message: errors[prop][0], validateStatus: 'error' },
        }
      }
      setMessages(msg)

      return
    }

    login(auth.email, auth.password)
      .then((response) => {
        localStorage.setItem('auth', JSON.stringify(response.data))
        history.push('/order/inspection/select-car')
      })
      .catch((err) => message.error(err.response.data.error))
  }

  return (
    <Container>
      <section className="formSngIn">
        <section className="sign-in-form">
          <h2>Sign in</h2>
          <Row>
            <Col span={24}>
              <EmailSignIn onChange={(e) => handleChange(e, 'email')} message={messages?.email?.message} validateStatus={messages?.email?.validateStatus} />
            </Col>
          </Row>

          <Row style={{ marginTop: '20px' }}>
            <Col span={24}>
              <PasswordSignIn
                onChange={(e) => handleChange(e, 'password')}
                placeholder="Password"
                errorMessage={messages?.password?.message}
                validateStatus={messages?.password?.validateStatus}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: '27px' }}>
            <Col span={12}>
              <Link className="forgot-password" to="/customer/forgot-password">
                Forgot Password
              </Link>
            </Col>

            <Col span={12}>
              <Link className="label-default" to="/customer/sign-up">
                Sign Up
              </Link>
            </Col>
          </Row>

          <Row style={{ marginTop: '23px' }}>
            <Col span={24}>
              <button className="ep-button ep-sign-in" onClick={handleSubmit}>
                Sign In
              </button>
            </Col>
          </Row>
        </section>
      </section>
    </Container>
  )
}

export default SignIn
