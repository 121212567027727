import moment from 'moment'

export const getPeriodString = (from, end) => {
  if (!from || !end) return null

  let displayTimeFrom = moment(from, 'hh:mm A').format('hA')

  if (from === '7' && end === '10') return 'Morning 7AM - 10AM'

  if (from === '10' && end === '15') return 'Day 10AM - 3PM'

  if (from === '15' && end === '19') return 'Evening 3PM - 7PM'

  return `Anytime ${displayTimeFrom} - 7PM`
}
