import moment from 'moment'
import React, { useEffect, useState } from 'react'
import MyAccount from '../../myAccount'
import InspectedStatistic from './inspectedStatistic'
import ScheduledStatistic from './scheduledStatistic'
import './statistic.scss'
import { getScheduledDataForChart, getFilteredByDatePeriod, getFilteredByPeriodType, getPreviousDataForChart } from 'helper/appointmentFilters'
import Chart from './chart'
import { getUpcoming } from 'api/getUpcomingInspectors'
import { getHistory } from 'api/getPreviousInspectors'

const Statistic = () => {
  const [selectedStatistic, setSelectedStatistic] = useState('Inspected')
  const [scheduledPeriodType, setScheduledPeriodType] = useState('')
  const [previousPeriodType, setPreviousPeriodType] = useState('')
  const [inspectedSelectedDate, setInspectedSelectedDate] = useState({
    from: moment(),
    to: moment(),
  })
  const [scheduledSelectedDate, setScheduledSelectedDate] = useState({
    from: moment(),
    to: moment(),
  })

  const [scheduledList, setScheduledList] = useState([])
  const [previousList, setPreviousList] = useState([])
  let assignedListScheduled = []
  let acceptedListScheduled = []
  let assignedListPrevious = []
  let acceptedListPrevious = []
  let filteredScheduledList = []
  let filteredPreviousList = []
  let dataForChart = []

  if (scheduledPeriodType.length > 0) {
    filteredScheduledList = getFilteredByPeriodType(scheduledPeriodType, scheduledList, true)
  } else {
    filteredScheduledList = getFilteredByDatePeriod(scheduledSelectedDate, scheduledList, true)
  }

  if (previousPeriodType.length > 0) {
    filteredPreviousList = getFilteredByPeriodType(previousPeriodType, previousList, false)
  } else {
    filteredPreviousList = getFilteredByDatePeriod(inspectedSelectedDate, previousList)
  }

  const changeScheduledDate = (days) => {
    setScheduledSelectedDate({
      from: moment(),
      to: moment().add(days, 'days'),
    })
  }

  const changePreviousDate = (days) => {
    setInspectedSelectedDate({
      from: moment().subtract(days, 'days'),
      to: moment(),
    })
  }

  useEffect(() => {
    if (scheduledPeriodType === '24H') {
      changeScheduledDate(1)
    } else if (scheduledPeriodType === '7 DAYS') {
      changeScheduledDate(7)
    } else if (scheduledPeriodType === '30 DAYS') {
      changeScheduledDate(30)
    }
  }, [scheduledPeriodType])

  useEffect(() => {
    if (previousPeriodType === '24H') {
      changePreviousDate(1)
    } else if (previousPeriodType === '7 DAYS') {
      changePreviousDate(7)
    } else if (previousPeriodType === '30 DAYS') {
      changePreviousDate(30)
    }
  }, [previousPeriodType])

  filteredScheduledList.map((item) => {
    if (item.location === 'The client comes to our location.') {
      assignedListScheduled.push(item)
    } else {
      acceptedListScheduled.push(item)
    }
  })
  filteredPreviousList.map((item) => {
    if (item.location === 'The client comes to our location.') {
      assignedListPrevious.push(item)
    } else {
      acceptedListPrevious.push(item)
    }
  })

  useEffect(() => {
    getUpcoming().then((res) => setScheduledList(res.data.data))
    getHistory().then((res) => setPreviousList([...res.data.customer_appointments_history, ...res.data.inspector_appointments_history]))
  }, [])

  if (selectedStatistic === 'Scheduled') {
    dataForChart = getScheduledDataForChart(filteredScheduledList, scheduledPeriodType)
  } else {
    dataForChart = getPreviousDataForChart(filteredPreviousList, previousPeriodType)
  }

  return (
    <MyAccount>
      <div className="statistic-container">
        <div className="two-statistics">
          <InspectedStatistic
            selectedStatistic={selectedStatistic}
            setSelectedStatistic={setSelectedStatistic}
            selectedDate={inspectedSelectedDate}
            handleDateChange={setInspectedSelectedDate}
            setPeriodType={setPreviousPeriodType}
            assignedListPrevious={assignedListPrevious}
            acceptedListPrevious={acceptedListPrevious}
          />
          <ScheduledStatistic
            selectedStatistic={selectedStatistic}
            setSelectedStatistic={setSelectedStatistic}
            selectedDate={scheduledSelectedDate}
            handleDateChange={setScheduledSelectedDate}
            setPeriodType={setScheduledPeriodType}
            assignedListScheduled={assignedListScheduled}
            acceptedListScheduled={acceptedListScheduled}
          />
        </div>
        <div className="statistic-name">
          <h2>{selectedStatistic}</h2>
        </div>
        <Chart data={dataForChart} />
      </div>
    </MyAccount>
  )
}

export default Statistic
