import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import Container from '../../../Container'
import './Index.scss'
import { getAuthData } from '../../../../../helper/auth'

const Success = () => {
  const history = useHistory()
  const [auth, setAuth] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
    
    setAuth(getAuthData())

    if (getAuthData()?.technician) {
      history.push('/inspector/my-account/statistic')
    }
  }, [])

  return (
    <Container>
      <div className="ep-confirm-container">
        <div className="ep-confirm-form">
          <h1>Thank you!</h1>
          <h2>We have sent you an email with order confirmation and details.</h2>
          <div>
            {auth === null && (
              <button className="ep-button ep-sign-in" style={{ width: '314px' }} onClick={() => history.push('/sign-in')}>
                Login to account
              </button>
            )}

            <button className="ep-button ep-sign-in" style={{ width: '314px', marginTop: '10px' }} onClick={() => history.push('/order/inspection/select-car')}>
              Request a new inspection
            </button>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default connect(null, null)(Success)
