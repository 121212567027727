import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import './Index.scss'
import moment from 'moment'
import actions from '../../../../../../../store/order/actions'

/**
 * Chose time component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ChoseCustomerTime = (props) => {
  let anytimeStart = '7am'
  const currentHour = moment().format('HH')
  const min = moment().format('m')
  const currentHourPlusTwo = moment().add(2, 'hours').format('ha')
  const currentHourPlusThree = moment().add(3, 'hours').format('ha')

  const isSelectedToday = () => {
    const currentTime = moment()
    const selectedDay = moment(props.inspection.date).format('DD')
    return +selectedDay === +currentTime.format('DD')
  }

  const getAnytimeStart = (forServer) => {
    if (isSelectedToday()) {
      if (currentHour <= 4 || currentHour >= 17 || (currentHour == 16 && +min > 30)) {
        if (forServer) {
          return moment(anytimeStart, 'hh:mma').format('HH')
        } else return anytimeStart
      }

      if (currentHour >= 5 && +min > 30) {
        anytimeStart = currentHourPlusThree
        if (forServer) {
          return moment(anytimeStart, 'hh:mma').format('HH')
        } else return anytimeStart
      }

      if (currentHour >= 5 && +min <= 30) {
        anytimeStart = currentHourPlusTwo
        if (forServer) {
          return moment(anytimeStart, 'hh:mma').format('HH')
        } else return anytimeStart
      }
    }

    return anytimeStart
  }

  const isDisabled = (dayPeriod) => {
    if (isSelectedToday()) {
      if (dayPeriod === 'morning') {
        return currentHour > 7 || (currentHour == 7 && +min > 30)
      }

      if (dayPeriod === 'day') {
        return currentHour > 12 || (currentHour == 12 && +min > 30)
      }

      if (dayPeriod === 'evening' || dayPeriod === 'anytime') {
        return currentHour > 16 || (currentHour == 16 && +min > 30)
      }
    }

    return false
  }

  useEffect(() => {
    if (isSelectedToday() && isDisabled('anytime')) {
      props.setOrderInspection({
        ...props.inspection,
        time: null,
      })
    }
  }, [props.inspection.date])

  /**
   * Select time
   * @param time
   */
  const selectTime = (from, to) => {
    props.setOrderInspection({
      ...props.inspection,
      timeFrom: from,
      timeTo: to,
    })
  }

  return (
    <div className="ep-time-scroll-container">
      <div className="ep-time-title">Choose suggested time for the inspection</div>
      <div className="ep-time-scroll">
        <div className="ep-time-scroll-items">
          <div
            className={`ep-time-scroll-customer-item ${isDisabled('anytime') && 'disabled'} ${
              props.inspection.timeFrom === getAnytimeStart(true) && props.inspection.timeTo === '19' && 'active'
            }`}
            onClick={!isDisabled('anytime') ? () => selectTime(getAnytimeStart(true), '19') : null}
          >
            <div className="ep-time-time">Anytime {getAnytimeStart()} – 7pm</div>
          </div>
          <div
            className={`ep-time-scroll-customer-item ${isDisabled('morning') && 'disabled'} ${
              props.inspection.timeFrom === '7' && props.inspection.timeTo === '10' && 'active'
            }`}
            onClick={!isDisabled('morning') ? () => selectTime('7', '10') : null}
          >
            <div className="ep-time-time">Morning 7am – 10am</div>
          </div>
          <div
            className={`ep-time-scroll-customer-item ${isDisabled('day') && 'disabled'} ${
              props.inspection.timeFrom === '10' && props.inspection.timeTo === '15' && 'active'
            }`}
            onClick={!isDisabled('day') ? () => selectTime('10', '15') : null}
          >
            <div className="ep-time-time">Day 10am – 3pm</div>
          </div>
          <div
            className={`ep-time-scroll-customer-item ${isDisabled('evening') && 'disabled'} ${
              props.inspection.timeFrom === '15' && props.inspection.timeTo === '19' && 'active'
            }`}
            onClick={!isDisabled('evening') ? () => selectTime('15', '19') : null}
          >
            <div className="ep-time-time">Evening 3pm – 7pm</div>
          </div>
        </div>
      </div>
      <div className="ep-time-title" style={{marginTop: 10}}>Once the order is placed we will contact you to confirm the exact inspection time</div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    inspection: state.order.orderReducer.inspection,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrderInspection: (payload) => dispatch(actions.setOrderInspection(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChoseCustomerTime)
