import React from 'react'

const ModalContent = ({ selectedAppointment }) => {
  let linkMap = '#'
  if (selectedAppointment.address !== '-') {
    linkMap = `https://maps.google.com/?q=${selectedAppointment.address}`
  }
  return (
    <section className="modal-container">
      <section className="modal-content content-info">
        <h2>Appointment details</h2>

        <ul className="list-row">
          <li className="p-row">
            <p className="row-label">Appointment сreation date:</p>
            <p className="row-value">{selectedAppointment.created_at}</p>
          </li>

          <li className="p-row">
            <p className="row-label">Scheduled inspection time:</p>
            <p className="row-value">{selectedAppointment.scheduled_date + ' ' + selectedAppointment.scheduled_time}</p>
          </li>

          <li className="p-row">
            <p className="row-label">End of inspection:</p>
            <p className="row-value">{selectedAppointment.end_of_inspection}</p>
          </li>

          <li className="p-row">
            <p className="row-label">Inspector/Mechanic shop:</p>
            <p className="row-value">{selectedAppointment.technician}</p>
          </li>

          <li className="p-row">
            <p className="row-label">Address Inspection:</p>
            <a href={linkMap} target="_blank" className="row-value" rel="noreferrer">
              {selectedAppointment.address}
            </a>
          </li>

          <li className="p-row">
            <p className="row-label">Inspection Requested:</p>
            <a href={linkMap} target="_blank" className="row-value" rel="noreferrer">
              -
            </a>
          </li>

          <li className="p-row">
            <p className="row-label">Vehicle:</p>
            <p className="row-value">{selectedAppointment.name}</p>
          </li>

          <li className="p-row">
            <p className="row-label">Phone Number:</p>
            <p className="row-value">{selectedAppointment.technician_phone}</p>
          </li>

          <li className="p-row">
            <p className="row-label">Note:</p>
            <p className="row-value">{selectedAppointment.note}</p>
          </li>

          <li className="p-row">
            <p className="row-label">Report:</p>
            <a href={selectedAppointment.report} target="_blank" className="row-value" rel="noreferrer">
              Open in new window
            </a>
          </li>
        </ul>
      </section>

      <section className="modal-content content-photo">
        <img src={selectedAppointment.photo} alt="Car image" />
      </section>
    </section>
  )
}

export default ModalContent
