import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import Steps from 'components/steps'
import { Checkbox, Col, Form, Row, Select, Modal, Button } from 'antd'

import EmailSignIn from '../../../../signIn/email/EmailSignIn'
import TextSelectCar from './text/TextSelectCar'

import Container from '../../../../Container'
import { getAuthData } from 'helper/auth'
import { carInfo, carInfoByModel, carMakes, carModels, carYears } from 'api/car'
import Validator from 'validatorjs'
import actions from 'store/order/actions'
import './Index.scss'
import { customer } from 'api/customer'
import moment from 'moment'

/**
 * Select car component.
 * @param props
 * @returns {*}
 * @constructor
 */
const SelectCar = (props) => {
  const history = useHistory()
  const [makes, setMakes] = useState([])
  const [models, setModels] = useState([])
  const [years, setYears] = useState([])
  const [make, setMake] = useState(null)
  const [model, setModel] = useState(null)
  const [year, setYear] = useState(null)
  const [showModel, setShowModel] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [messages, setMessages] = useState({
    email: {
      message: '',
      validateStatus: null,
    },
    make: {
      message: '',
      validateStatus: null,
    },
    model: {
      message: '',
      validateStatus: null,
    },
    year: {
      message: '',
      validateStatus: null,
    },
  })
  const refForm = useRef()
  const auth = localStorage.getItem('auth')

  useEffect(() => {
    window.scrollTo(0, 0)
    
    const auth = getAuthData()

    if (auth?.customer) {
      history.push('/order/inspection/select-car')
      customer()
        .then((data) => {
          props.setOrderApplicantInfo({
            ...props.applicantInfo,
            email: data.data.email,
          })
        })
        .catch((e) => console.log(e, '-error'))
    }

    if (auth?.technician) {
      history.push('/inspector/my-account/statistic')
    }

    carMakes().then((data) => {
      setMakes(data.data)
    })

    props.setOrderInspection({
      ...props.inspection,
      isShopLocation: false
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (showModel) {
      props.setOrderCar({ ...props.car, vin: '' })
    } else {
      props.setOrderCar({ ...props.car, model: null, year: null, make: null })
      setYear(null)
      setModel(null)
      setMake(null)
    }
  }, [showModel])

  const handleSubmit = () => {
    const data = {
      email: props.applicantInfo.email,
    }

    const rules = {
      email: 'required|email',
    }

    if (!showModel) {
      rules['vin'] = 'required'

      data['vin'] = props.car.vin
    } else {
      rules['make'] = 'required'
      rules['model'] = 'required'
      rules['year'] = 'required'

      data['make'] = props.car.make
      data['model'] = props.car.model
      data['year'] = props.car.year
    }

    const validation = new Validator(data, rules)

    if (validation.fails()) {
      const errors = validation.errors.all()
      let msg = { ...messages }
      for (let prop in errors) {
        errors[prop].map((error) => {
          msg[prop] = { message: error, validateStatus: 'error' }
        })
      }

      setMessages(msg)
    }

    if (props.car.vin === '' && props.car.make && props.car.model && props.car.year) {
      carInfoByModel(props.car.make, props.car.model, props.car.year)
        .then((response) => {
          props.setOrderCar({
            ...props.car,
            detail: response.data,
          })

          if (validation.passes()) {
            const timeStart = moment(new Date()).format('DD-MM-YYYY hh:mm:ss')
            props.setOrderInspection({
              ...props.inspection,
              inspectionTimeStart: moment(timeStart, 'DD-MM-YYYY hh:mm:ss')
            })
            history.push('/order/location/select-location-type')
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data.error.message)
          setErrorModal(true)
        })
    }

    if (props.car.vin.length && !props.car.make && !props.car.model && !props.car.year) {
      carInfo(props.car.vin)
        .then((response) => {
          props.setOrderCar({
            ...props.car,
            detail: response.data,
          })

          if (validation.passes()) {
            const timeStart = moment(new Date()).format('DD-MM-YYYY hh:mm:ss')
            props.setOrderInspection({
              ...props.inspection,
              inspectionTimeStart: moment(timeStart, 'DD-MM-YYYY hh:mm:ss')
            })
            history.push('/order/location/select-location-type')
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data.error.message)
          setErrorModal(true)
        })
    }
  }

  /**
   * Handle change input.
   *
   * @param e
   * @param name
   */
  const handleChange = (e, name) => {
    setMessages({
      ...messages,
      [name]: {
        message: '',
        validateStatus: null,
      },
    })

    if (name === 'email') {
      props.setOrderApplicantInfo({
        ...props.applicantInfo,
        email: e.target.value,
      })
    }

    if (name === 'year' || name === 'make' || name === 'model') {
      if (name === 'model' && props.car.model !== e) {
        props.setOrderCar({ ...props.car, [name]: e, year: null })
      } else {
        props.setOrderCar({ ...props.car, [name]: e })
      }
    }

    if (name === 'make' && props.car.make && e !== props.car.make) {
      props.setOrderCar({ ...props.car, [name]: e, model: null, year: null })
      setModel(null)
      setYear(null)
    }

    if (name === 'make') {
      carModels(e).then((data) => {
        setModels(data.data)
        setYears([])
      })
      setMake(e)
    } else if (name === 'model') {
      carYears(make, e).then((data) => {
        setYears(data.data)
      })
      setModel(e)
      setYear(null)
    } else if (name === 'year') {
      setYear(e)
    }

    if (name === 'vin') {
      props.setOrderCar({ ...props.car, [name]: e.target.value })
    }
  }

  const handleChangeVIN = (e) => {
    setShowModel(e.target.checked)
  }

  return (
    <Container>
      <Steps style={{ marginTop: '15px' }} />
      <div className="formInspectionForm">
        <div className="ep-order-inspection-form" ref={refForm}>
          <h2 style={{ marginTop: '27px' }}>Order Inspection</h2>
          <Row>
            <Col span={24}>
              <EmailSignIn
                onChange={(e) => handleChange(e, 'email')}
                value={props.applicantInfo.email}
                message={messages?.email?.message}
                validateStatus={messages?.email?.validateStatus}
                readOnly={auth ? true : false}
              />
            </Col>
          </Row>
          <Row
            style={{
              marginTop: '20px',
              display: !showModel ? 'block' : 'none',
            }}
          >
            <Col span={24}>
              <TextSelectCar placeholder="VIN number" onChange={(e) => handleChange(e, 'vin')} value={props.car.vin} />
            </Col>
          </Row>
          <Row style={{ marginTop: '30px', display: showModel ? 'block' : 'none' }}>
            <Col span={24}>
              <Form.Item validateStatus={messages.make.validateStatus ? 'error' : ''} help={messages.make.message}>
                <Select style={{ width: '100%' }} placeholder="Select car manufacturer" onChange={(e) => handleChange(e, 'make')} showSearch value={make}>
                  {makes.map((make, index) => (
                    <Select.Option key={`make-${index}`} value={make.make}>
                      {make.make}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: '30px', display: showModel ? 'block' : 'none' }}>
            <Col span={24}>
              <Form.Item validateStatus={messages.model.validateStatus ? 'error' : ''} help={messages.model.message}>
                <Select style={{ width: '100%' }} placeholder="Select car model" onChange={(e) => handleChange(e, 'model')} showSearch value={model}>
                  {models.map((model, index) => (
                    <Select.Option key={`model-${index}`} value={model.model}>
                      {model.model}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: '30px', display: showModel ? 'block' : 'none' }}>
            <Col span={24}>
              <Form.Item validateStatus={messages.year.validateStatus ? 'error' : ''} help={messages.year.message}>
                <Select style={{ width: '100%' }} placeholder="Select year" onChange={(e) => handleChange(e, 'year')} showSearch value={year}>
                  {years.map((year, index) => (
                    <Select.Option key={`year-${index}`} value={year.year}>
                      {year.year}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: '30px' }}>
            <Col span={24} style={{ margin: '0 auto' }}>
              <Checkbox onChange={handleChangeVIN}>I don&apos;t know my VIN number</Checkbox>
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col span={24}>
              <button className="ep-button ep-sign-in" onClick={handleSubmit}>
                <span>Continue</span>
              </button>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        style={{ display: 'flex', justifyContent: 'center' }}
        visible={errorModal}
        onOk={() => setErrorModal(false)}
        onCancel={() => setErrorModal(false)}
        footer={[
          <Button key="submit" type="primary" onClick={() => setErrorModal(false)}>
            Ok
          </Button>,
        ]}
      >
        <span style={{ display: 'block', margin: '20px 100px' }}>{errorMessage}</span>
      </Modal>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    applicantInfo: state.order.orderReducer.applicantInfo,
    car: state.order.orderReducer.car,
    inspection: state.order.orderReducer.inspection
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrderApplicantInfo: (payload) => dispatch(actions.setOrderApplicantInfo(payload)),
    setOrderCar: (payload) => dispatch(actions.setOrderCar(payload)),
    setOrderInspection: (payload) => dispatch(actions.setOrderInspection(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectCar)
