import React from 'react'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'

import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import rootReducer from './reducers'
import { save, load } from "redux-localstorage-simple"
import {GTag} from '@deptno/gtag-react'


const middleware = applyMiddleware(thunk, logger, save())
const createStoreWithMiddleware
    = applyMiddleware(
    save() // Saving done here
)(createStore)

const store = createStoreWithMiddleware(
    rootReducer,
    load() // Loading done here
)

ReactDOM.render(
  <Provider store={store}>
    <GTag id={`G-T8N47GVNKP`} />
    <App />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
