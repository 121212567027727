import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Row, Col, Checkbox, message, Button, Modal } from 'antd'
import Container from '../../../Container'
import actions from 'store/order/actions'
import Steps from 'components/steps'
import './Index.scss'
import ChoseDate from './components/ChoseDate/Index'
import ChoseTime from './components/ChoseTime/Index'
import ApplicantInfo from './components/ApplicantInfo/Index'
import CardInfo from './components/CardInfo/Index'
import { store } from 'api/appointment'
import Validator from 'validatorjs'
import ChoseCustomerTime from './components/ChoseCustomerTime/Index'
import noImage from 'images/inspection/noimage-car.jpeg'
import moment from 'moment'
import momenttz from 'moment-timezone'
import { initialInspection } from 'store/order/reducers'
import StepsForCarPrice from 'components/stepsForCarPrice/Steps'
import { getTimezoneByState } from './helpers'
import TotalAndNotes from './components/TotalAndNotes'
import { getPromo, getTax } from 'api/getTaxAndPromo'
import { create, all } from 'mathjs'

const config = {}
const math = create(all, config)

const Payment = (props) => {
  const history = useHistory()
  const [price, setPrice] = useState()
  const [sameApplicantInfo, setSameApplicantInfo] = useState(false)
  const [applicantInfoErrors, setApplicantInfoErrors] = useState(null)
  const [cardErrors, setCardErrors] = useState(null)
  const [modal, setModal] = useState(false)
  const [timeModal, setTimeModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [messages, setMessages] = useState({
    zip: {
      message: '',
      validateStatus: null,
    },
  })
  const [inspectionDate, setInspectionDate] = useState(props.inspection.date)
  const [promoCode, setPromoCode] = useState('')
  const [tax, setTax] = useState(null)
  const [discount, setDiscount] = useState(null)
  const [total, setTotal] = useState(null)
  const [packagePrice, setPackagePrice] = useState(null)
  const [estimatedTax, setEstimatedTax] = useState(null)

  useEffect(() => {
    setPackagePrice(props.inspection.inspectionPackage?.price)
    // const end = moment(new Date()).format('DD-MM-YYYY hh:mm:ss')
    // const endTime = moment(end, 'DD-MM-YYYY hh:mm:ss')
    // const startTime = props.inspection.inspectionTimeStart

    // var minutesDiff = endTime.diff(startTime, 'minutes');
  }, [props.inspection])

  useEffect(() => {
    if (props.inspection.date && props.inspection.location.selected_state) {
      const date = moment(props.inspection.date).format('YYYY-MM-DD')

      if (props.inspection.isShopLocation && props.inspection.time) {
        const time = moment(props.inspection.time).format('HH:mm')
        const full = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm')
        const dateTZ = momenttz.tz(full, getTimezoneByState(props.inspection.location.selected_state))
        const dateInUTC = dateTZ.clone().utc()
        setInspectionDate(dateInUTC.format())
      } else {
        const date = moment(props.inspection.date).format('YYYY-MM-DD')
        const dateTZ = momenttz.tz(date, getTimezoneByState(props.inspection.location.selected_state))
        const dateInUTC = dateTZ.clone().utc()
        setInspectionDate(dateInUTC.format())
      }
    }
  }, [props.inspection.date, props.inspection.time])

  let imageCar
  if (typeof props.car.detail.src_car_info.photos !== 'undefined' && props.car.detail.src_car_info.photos.length > 0) {
    imageCar = <img src={props.car.detail.src_car_info.photos[0].url} alt={props.car.detail.model} style={{ width: '480px' }} />
  } else {
    imageCar = <img src={noImage} alt={props.car.detail.model || 'Car no-image'} style={{ width: '300px' }} />
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    
    if (props.inspection.location?.selected_state === "CA") {
      getTax().then((res) => {
        if (res.status === 200) {
          setTax(res.data.tax)
        }
      })
    }
  }, [])

  const beforeCarPrice = useLocation()?.state?.beforeCarPrice

  useEffect(() => {
    if (sameApplicantInfo) {
      setCardErrors({
        ...cardErrors,
        firstName: {
          message: '',
          validateStatus: null,
        },
        lastName: {
          message: '',
          validateStatus: null,
        },
      })
    }
  }, [sameApplicantInfo])

  useEffect(() => {
    let totalAmount = null
    let taxPercent = tax / 100
    let estimatedTax = null

    if (discount) {
      let promoDiscount = +`0.${discount}`
      if (tax) {
      estimatedTax = math.ceil(taxPercent * (packagePrice - packagePrice * promoDiscount), 2)
      totalAmount = math.ceil(packagePrice - packagePrice * promoDiscount + estimatedTax, 2)
      } else {
        totalAmount = math.ceil(packagePrice - packagePrice * promoDiscount, 2)
      }
    } else {
      if (tax) {
      estimatedTax = math.ceil(taxPercent * packagePrice, 2)
      totalAmount = math.ceil(packagePrice + estimatedTax, 2)
      } else {
        totalAmount = packagePrice
      }
    }

    setEstimatedTax(estimatedTax)
    setTotal(totalAmount)
  }, [tax, discount, packagePrice])

  useEffect(() => {
    props.setOrderInspection({
      ...props.inspection,
      timeFrom: null,
      timeTo: null,
    })
  }, [props.inspection.date])

  const clearData = () => {
    props.setOrderPayment({
      cardNumber: '',
      month: null,
      year: null,
      cvc: null,
    })
    props.setOrderCar({
      vin: '',
      make: null,
      model: null,
      year: null,
      detail: {
        model: null,
        vehicle_type: null,
        vin: null,
        src_car_info: {
          photos: [],
          attributes: {},
        },
      },
    })
    props.setOrderApplicantInfo({
      firstName: null,
      lastName: null,
      email: '',
      phone: null,
    })
    props.setOrderInspection(initialInspection)
  }

  const handlePayAndConfirm = () => {
    setIsLoading(true)
    const rules = {
      'customer.first_name': ['required', 'regex:/^[A-Za-z]+$/'],
      'customer.last_name': ['required', 'regex:/^[A-Za-z]+$/'],
      'customer.email': 'required|email',
      'customer.phone': 'required|digits:10',
      'card.cardNumber': 'required|digits:16',
      'card.cvc': 'required|digits:3',
      'card.month': 'required',
      'card.total': 'required',
      'card.year': 'required',
      'card.firstName': ['required', 'regex:/^[A-Za-z]+$/'],
      'card.lastName': ['required', 'regex:/^[A-Za-z]+$/'],
    }

    const getBackendPhone = (num) => {
      return `+1${num}`
    }

    const getOnlyDigits = (string) => {
      if (string !== null) {
        if (string.includes('-')) {
          return string.split('-').join('')
        } else {
          return string.split(' ').join('')
        }
      }
    }

    const appointmentData = {
      package_id: props.inspection.inspectionPackage.id,
      inspection_date_time: inspectionDate,
      inspection_time_from: props.inspection.isShopLocation ? null : props.inspection.timeFrom,
      inspection_time_end: props.inspection.isShopLocation ? null : props.inspection.timeTo,
      inspection: {},
      car_info: props.car.detail,
      vin: props.car.detail.vin,
      is_our_location: props.inspection.isShopLocation,
      is_split_payment: false,
      body_shop: props.inspection.shop,
      location: {
        address: props.inspection.location.address,
        gps_coordinates: props.inspection.location.geo_coordinates,
      },
      customer: {
        first_name: props.applicantInfo.firstName,
        last_name: props.applicantInfo.lastName,
        email: props.applicantInfo.email,
        phone: getOnlyDigits(props.applicantInfo.phone),
        location: {
          address: props.inspection.location.address,
          gps_coordinates: props.inspection.location.geo_coordinates,
        },
      },
      card: {
        ...props.payment,
        cardNumber: props.payment.cardNumber.replace(/\s/g, ''),
        total,
        price,
        tax,
      },
      src_car_info: props.car.detail.src_car_info,
      note: props.inspection.note,
      promo_code: promoCode,
    }

    let auth = localStorage.getItem('auth')
    let token = null

    auth = auth && JSON.parse(auth)

    if (auth) {
      token = auth.access_token
    }

    const customMessages = {
      'required.card.firstName': 'Input your first name',
      'required.card.lastName': 'Input your last name',
      'required.card.cardNumber': 'Input your card number',
      'required.card.month': 'Required',
      'required.card.year': 'Required',
      'required.card.cvc': 'Required',

      'required.customer.first_name': 'Input your first name',
      'required.customer.last_name': 'Input your last name',
      'required.customer.phone': 'Input your phone',

      regex: 'Use only letters',
      'digits.customer.phone': 'Use only 10 digits',
      'digits.card.cardNumber': 'Use only 16 digits',
    }

    const validation = new Validator(appointmentData, rules, customMessages)

    let timeForModal = props.inspection.isShopLocation ? props.inspection.time : props.inspection.timeFrom

    if (validation.passes() && props.inspection.date && timeForModal) {
      const end = moment(new Date()).format('DD-MM-YYYY hh:mm:ss')
      const endTime = moment(end, 'DD-MM-YYYY hh:mm:ss')
      const startTime = props.inspection.inspectionTimeStart
  
      var minutesDiff = endTime.diff(startTime, 'minutes');

      if (minutesDiff > 60) {
        setTimeModal(true)
        setIsLoading(false)
      } else {
        store(
          {
            ...appointmentData,
            customer: {
              ...appointmentData.customer,
              phone: getBackendPhone(getOnlyDigits(props.applicantInfo.phone)),
            },
          },
          token
        )
          .then(() => {
            setIsLoading(false)
            clearData()
            history.push('/order/success')
          })
          .catch((err) => {
            setIsLoading(false)
            if (typeof err.response.data !== 'undefined') {
              message.error(err.response.data.error.message)
            } else {
              message.error('Unknown error')
            }
          })
      }
    }

    if (!props.inspection.date || !timeForModal) {
      setIsLoading(false)
      setModal(true)
    }

    if (validation.fails()) {
      setIsLoading(false)
      const errors = validation.errors.all()

      let appInfoErrors = {}
      let cardInfoErrors = {}

      for (let prop in errors) {
        const c = prop.split('.')
        let msg = { ...messages }

        errors[prop].map((error) => {
          msg[prop] = { message: error, validateStatus: 'error' }

          if (c[0] === 'customer') {
            appInfoErrors[c[1]] = { message: error, validateStatus: 'error' }
          }

          if (c[0] === 'card') {
            if ((c[1] === 'firstName' || c[1] === 'lastName') && sameApplicantInfo) {
              return null
            } else {
              cardInfoErrors[c[1]] = {
                message: error,
                validateStatus: 'error',
              }
            }
          }
        })
        setMessages(msg)
      }

      setApplicantInfoErrors(appInfoErrors)
      setCardErrors(cardInfoErrors)
    }
  }

  const onPromoChange = (ev) => {
    getPromo(ev.target.value).then((res) => {
      if (res.status === 200) {
        setDiscount(res.data.discount)
      }
    })

    setPromoCode(ev.target.value)
  }

  return (
    <Container>
      {!beforeCarPrice && <Steps />}
      {beforeCarPrice && <StepsForCarPrice />}
      <Row>
        <Col span={2} style={{ textAlign: 'left' }}>
          <Button
            type="primary"
            style={{ marginLeft: 'left' }}
            size="small"
            onClick={() => {
              history.goBack()
            }}
          >
            Back
          </Button>
        </Col>
      </Row>
      <Row className="PaymentTableCheckoutRow">
        <Col>
          <Row>
            <Col span="24">
              <div className="ep-order-car-info">
                <div className="ep-order-car-info-title">{props.car.detail.model}</div>
                <div className="ep-order-car-info-item">
                  <span>Trim:</span>
                  {props.car.detail.vehicle_type}
                </div>
                <div className="ep-order-car-info-item">
                  <span>VIN: </span>
                  {props.car.detail.vin}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <div className="ep-order-car-info-image">{imageCar}</div>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col span="24">
              <ChoseDate isBodyshop={props.inspection.isShopLocation} />
            </Col>
          </Row>
          <Row>
            <Col span="24">
              {props.inspection.isShopLocation && <ChoseTime />}
              {!props.inspection.isShopLocation && <ChoseCustomerTime />}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="PaymentTableCheckoutRow">
        <Col>
          <ApplicantInfo errors={applicantInfoErrors} setErrors={setApplicantInfoErrors} />
        </Col>
        <Col>
          <div className="ep-same-applicant-info">
            <Checkbox onChange={(e) => setSameApplicantInfo(e.target.checked)}>Same as application information</Checkbox>
          </div>
          <CardInfo sameApplicantInfo={sameApplicantInfo} errors={cardErrors} setErrors={setCardErrors} />
        </Col>
      </Row>
      <TotalAndNotes 
        onPromoChange={onPromoChange} 
        onNotesChange={(ev) => props.setOrderInspection({ ...props.inspection, note: ev.target.value })}
        discount={discount}
        tax={estimatedTax}
        subtotal={packagePrice}
        total={total}
      />
      <Row className="PaymentTableCheckoutRow">
        <Col span="24">
          <div className="ep-term-info">
            By pressing Pay & Confirm button I agree to Caro Automotive LLC Terms and Conditions <a href="">View Terms & Conditions</a>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span="24">
          <div className="ep-confirm-button-container">
            <div
              className={isLoading ? 'ep-pay-and-confirm-btn btn-loading' : 'ep-pay-and-confirm-btn'}
              onClick={() => (isLoading ? null : handlePayAndConfirm())}
            >
              Pay & Confirm
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        style={{ display: 'flex', justifyContent: 'center' }}
        visible={modal}
        onOk={() => setModal(false)}
        onCancel={() => setModal(false)}
        footer={[
          <Button key="submit" type="primary" onClick={() => setModal(false)}>
            Ok
          </Button>,
        ]}
      >
        <span style={{ display: 'block', margin: '20px 100px' }}>PLEASE, SELECT DATE AND TIME!</span>
      </Modal>
      <Modal
        style={{ display: 'flex', justifyContent: 'center' }}
        visible={timeModal}
        onOk={() => setTimeModal(false)}
        onCancel={() => setTimeModal(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              clearData()
              history.push('/order/inspection/select-car')
            }}
          >
            Request an inspection
          </Button>,
        ]}
      >
        <span style={{ display: 'block', margin: '20px 100px' }}>SESSION TIMED OUT</span>
      </Modal>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    orderReducer: state.order.orderReducer,
    applicantInfo: state.order.orderReducer.applicantInfo,
    inspection: state.order.orderReducer.inspection,
    car: state.order.orderReducer.car,
    payment: state.order.orderReducer.payment,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrderInspection: (payload) => dispatch(actions.setOrderInspection(payload)),
    setOrderApplicantInfo: (payload) => dispatch(actions.setOrderApplicantInfo(payload)),
    setOrderPayment: (payload) => dispatch(actions.setOrderPayment(payload)),
    setOrderCar: (payload) => dispatch(actions.setOrderCar(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment)
