import React, { useEffect } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Menu, Row, Col } from 'antd'
import './myAccount.scss'
import Container from '../../Container'
import { getAuthData } from '../../../helper/auth'
import UserWelcome from '../../../components/UserWelcome/UserWelcome'

const MyAccount = (props) => {
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const auth = getAuthData()

    if (auth !== null && auth.technician === null) {
      history.push('/')
    } else if (auth === null) {
      history.push('/sign-in')
    }
  }, [])

  return (
    <Container>
      <UserWelcome /> {/* greeting line */}
      <Row className="MyAccountTableSt" style={{ paddingTop: '30px' }}>
        <Col className="MyAccountMenuSt" span="6">
          <Menu selectedKeys={[location.pathname]} mode="inline">
            <Menu.Item className="MyAccountMenuItem" key="/inspector/my-account/statistic">
              <Link to={'/inspector/my-account/statistic'}>Statistic</Link>
            </Menu.Item>
            <Menu.Item className="MyAccountMenuItem" key="/inspector/my-account/scheduled">
              <Link to={'/inspector/my-account/scheduled'}>Scheduled</Link>
            </Menu.Item>
            <Menu.Item className="MyAccountMenuItem" key="/inspector/my-account/inspected">
              <Link to={'/inspector/my-account/inspected'}>Inspected</Link>
            </Menu.Item>
            <Menu.Item className="MyAccountMenuItem" key="/inspector/my-account/contactSupport">
              <Link to={'/inspector/my-account/contactSupport'}>Contact Support</Link>
            </Menu.Item>
            <Menu.Item className="MyAccountMenuItem" key="/inspector/my-account/setting">
              <Link to={'/inspector/my-account/setting'}>Setting page</Link>
            </Menu.Item>
          </Menu>
        </Col>
        <Col span="18">{props.children}</Col>
      </Row>
    </Container>
  )
}

export default MyAccount
