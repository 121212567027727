import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'
import './Index.scss'
import moment from 'moment'
import actions from '../../../../../../../store/order/actions'

/**
 * Chose time component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ChoseTime = (props) => {
  const workTime = props.inspection.shop.workTime.split('-')
  const workStart = workTime[0]
  const workEnd = workTime[1]

  const [times, setTimes] = useState([])
  const [prevDisabled, setPrevDisabled] = useState(true)
  const [nextDisabled, setNextDisabled] = useState(false)

  useEffect(() => {
    const current = moment(workStart, 'hh:mma')
    refreshTimes(current)
  }, [])

  const refreshTimes = (current) => {
    let timesList = []
    let work_end = workEnd

    for (let i = 1; i <= 4; i++) {
      if (moment(current).format('hh:mma') === moment(work_end, 'hh:mma').subtract(60, 'minutes').format('hh:mma')) {
        timesList.push({
          time: current.format('hh:mma'),
          date: current.format('YYYY-MM-DD HH:mm'),
        })
        break
      }

      timesList.push({
        time: current.format('hh:mma'),
        date: current.format('YYYY-MM-DD HH:mm'),
      })

      i !== 4 && current.add(15, 'minutes')
    }

    setTimes(timesList)
  }

  useEffect(() => {
    if (moment(times[0]?.date).format('hh:mma') === moment(workStart, 'hh:mma').format('hh:mma')) {
      setPrevDisabled(true)
    } else {
      setPrevDisabled(false)
    }

    if (times[times?.length - 1]?.time === moment(workEnd, 'hh:mma').subtract(60, 'minutes').format('hh:mma')) {
      setNextDisabled(true)
    } else {
      setNextDisabled(false)
    }
  }, [times])

  const next = () => {
    if (nextDisabled) {
      return null
    } else {
      const currentDate = moment(times[0].date)
      currentDate.add(60, 'minutes')
      refreshTimes(currentDate)
    }
  }

  const prev = () => {
    if (prevDisabled) {
      return null
    } else {
      const currentDate = moment(times[0].date)
      currentDate.subtract(60, 'minutes')
      refreshTimes(currentDate)
    }
  }

  /**
   * Select time
   * @param date
   */
  const selectTime = (date) => {
    props.setOrderInspection({
      ...props.inspection,
      time: moment(date).format('HH:mm'),
    })
  }

  const currentTime = moment()

  const isSelectedToday = () => {
    const selectedDay = moment(props.inspection.date).format('MM/DD/YYYY')
    return selectedDay === moment().format('MM/DD/YYYY')
  }

  const isDisabled = (time) => {
    if (isSelectedToday()) {
      if (currentTime.format('HH') > moment(time.date).format('HH')) {
        return true
      }

      if (currentTime.format('HH') === moment(time.date).format('HH') && currentTime.format('m') === moment(time.date).format('m')) {
        return true
      }

      if (currentTime.format('HH') === moment(time.date).format('HH') && currentTime.format('m') >= moment(time.date).format('m')) {
        return true
      }

      if (currentTime.format('HH') === moment(time.date).format('HH') && currentTime.format('m') < moment(time.date).format('m')) {
        return false
      }
    }

    return false
  }

  useEffect(() => {
    props.setOrderInspection({
      ...props.inspection,
      time: null,
    })
  }, [props.inspection.date])

  return (
    <div className="ep-time-scroll-container">
      <div className="ep-time-title">Choose Time</div>
      <div className="ep-time-scroll">
        <div className={`ep-time-scroll-left ${prevDisabled && 'disabled'}`} onClick={prev}>
          <LeftOutlined />
        </div>
        <div className="ep-time-scroll-items">
          {times.map((item, index) => {
            return (
              <div
                className={`ep-time-scroll-item ${isDisabled(item) && 'disabled'} ${props.inspection.time === moment(item.date).format('HH:mm') && 'active'}`}
                key={`time-${index}`}
                onClick={!isDisabled(item) ? () => selectTime(item.date) : null}
              >
                <div className="ep-time-time">{item.time}</div>
              </div>
            )
          })}
        </div>
        <div className={`ep-time-scroll-right ${nextDisabled && 'disabled'}`} onClick={next}>
          <RightOutlined />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    inspection: state.order.orderReducer.inspection,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrderInspection: (payload) => dispatch(actions.setOrderInspection(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChoseTime)
