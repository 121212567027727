import React from 'react'
import { Col, Row, Form, Button } from 'antd'
import './setting.scss'

import TextField from '@material-ui/core/TextField'
import InputMask from 'react-input-mask'

const GeneralSettings = ({ inspectorData, form, errors, handleChangeInput, submitInfo, cancelInfo }) => {
  return (
    <Form className="inspector-form inspection" initialValues={inspectorData} form={form} style={{ marginBottom: '40px', padding: '0px 20px' }}>
      <Row>
        <Col span="8">
          <h1 className="form-title ">General settings</h1>
        </Col>
      </Row>

      <Row gutter={[15, 0]}>
        <Col span="12">
          <Row>
            <Col span="24">
              <Form.Item name="first_name" validateStatus={errors.first_name.length && 'error'} help={errors.first_name.length ? errors.first_name : null}>
                <TextField
                  id="mui-theme-provider-standard-input"
                  label="Name"
                  value={inspectorData.first_name}
                  onChange={(e) => handleChangeInput(e, 'first_name')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span="24">
              <Form.Item name="last_name" validateStatus={errors.last_name.length && 'error'} help={errors.last_name.length ? errors.last_name : null}>
                <TextField
                  id="mui-theme-provider-standard-input"
                  label="Last Name"
                  value={inspectorData.last_name}
                  onChange={(e) => handleChangeInput(e, 'last_name')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span="12">
          <Row>
            <Col span="24">
              <Form.Item name="email" validateStatus={errors.email.length && 'error'} help={errors.email.length ? errors.email : null}>
                <TextField disabled id="mui-theme-provider-standard-input" label="Email" onChange={(e) => handleChangeInput(e, 'email')} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span="24">
              <Form.Item name="phone" validateStatus={errors.phone.length && 'error'} help={errors.phone.length ? errors.phone : null}>
                <InputMask className="phone" type="tel" mask="999 999 9999" initialvalues={inspectorData.phone} onChange={(e) => handleChangeInput(e, 'phone')}>
                  {() => <TextField label="Phone" />}
                </InputMask>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginBottom: 10 }} justify="end" gutter={[15, 0]}>
        <Col>
          <Button type="primary" onClick={() => cancelInfo('generalTechnician')} className="setting-btn setting-btn-cancel">
            Cancel
          </Button>
        </Col>
        <Col>
          <Button type="primary" onClick={submitInfo} className="setting-btn setting-btn-save">
            Save changes
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default GeneralSettings
