import React from 'react'
import { Button } from 'antd'
import './Button.scss'

const EpButton = (props) => {
  return (
    <Button {...props} className="ep-home-buttons" type={typeof props.type !== 'undefined' ? props.type : 'default'}>
      {props.children}
    </Button>
  )
}

export default EpButton
