import React, { useState, useEffect } from 'react'
import { Row, Col, Input, Button, notification, Select } from 'antd'
import MyAccount from '../../myAccount'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { sendMessage } from '../../../../../api/mail'
import './contactSupport.scss'
import { customer } from 'api/customer'

const ContactSupport = () => {
  const [email, setEmail] = useState(null)
  const [subject, setSubject] = useState('')
  const [text, setText] = useState('')
  const [buttonText, setButtonText] = useState('Send')

  useEffect(() => {
    customer()
      .then((data) => {
        setEmail(data.data.email)
      })
      .catch((e) => console.log(e, '-error'))
  })

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }

  const handleSubject = (e) => {
    setSubject(e)
  }

  const send = () => {
    setButtonText('Please wait...')

    sendMessage(subject, text)
      .then(() => {
        notification.success({
          message: 'Successful',
          description: 'Thank you, your message was sent successfully',
          onClose: () => {
            setEmail('')
            setSubject('')
            setText('')
            setButtonText('Send')
          },
        })
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  return (
    <MyAccount>
      <Row>
        <Col span="24" style={{ padding: '10px' }}>
          <Input placeholder="Email" onChange={(e) => handleEmail(e, 'email')} value={email ? `From: ${email}` : 'From: Email is loading...'} disabled={true} />
        </Col>
      </Row>
      <Row>
        <Col span="24" className="customerSupport">
          <Select
            style={{ width: '100%', textAlign: 'left' }}
            placeholder="Subject..."
            onChange={(e) => handleSubject(e, 'subject')}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Select.Option value="Support">Support</Select.Option>
            <Select.Option value="Payment">Payment</Select.Option>
            <Select.Option value="General">General</Select.Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span="24" style={{ padding: '10px' }}>
          <CKEditor
            height={'200px'}
            editor={ClassicEditor}
            onChange={(event, editor) => {
              const data = editor.getData()

              setText(data)
            }}
            data={text}
          />
        </Col>
      </Row>
      <Row>
        <Col span="24" style={{ textAlign: 'right', marginBottom: '40px' }}>
          <Button type="primary" onClick={send} disabled={buttonText === 'Please wait...'}>
            {buttonText}
          </Button>
        </Col>
      </Row>
    </MyAccount>
  )
}

export default ContactSupport
