import React from 'react'
import { Menu, Dropdown } from 'antd'
import { Link } from 'react-router-dom'
import { MenuOutlined } from '@ant-design/icons'
import './index.scss'

const MobileMenu = () => {
  const menu = (
    <Menu style={{ borderRadius: 5 }}>
      <Menu.Item key="0">
        <Link to="https://caroinspection.com/services/">Services</Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link to="https://caroinspection.com/how-it-works/">How it Wotks</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="https://caroinspection.com/become-a-partner/">Become a Partner</Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to="https://caroinspection.com/faq/">FAQ</Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link to="https://caroinspection.com/contact-1/">Contact Us</Link>
      </Menu.Item>

      <Menu.Divider style={{ height: 3, backgroundColor: '#5459f4' }} />
      <Menu.Item key="6">
        <Link to="/order/inspection/select-car">Request an Inspection</Link>
      </Menu.Item>
      <Menu.Item key="7">
        <Link to="/customer/my-account/current-appointments">My Account</Link>
      </Menu.Item>
    </Menu>
  )
  return (
    <>
      <div className="mobile-navBar">
        <Dropdown overlay={menu} trigger={['click']}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <MenuOutlined
              style={{
                fontSize: 25,
                color: 'black',
              }}
            />
          </a>
        </Dropdown>
      </div>
    </>
  )
}

export default MobileMenu
