import React from 'react'
import './Password.scss'

const Password = (props) => {
  let errorJsx = null
  const inputClasses = ['ep-input-email']

  if (props.errorMessage) {
    errorJsx = (
      <div className="ep-error-message">
        <div>{props.errorMessage}</div>
      </div>
    )

    inputClasses.push('ep-error')
  }

  return (
    <>
      <i className="ep-icon ep-icon-key ep-icon-absolute"></i>
      <input className={inputClasses.join(' ')} type="password" placeholder={props.placeholder} onChange={props.onChange} />

      {errorJsx}
    </>
  )
}

export default Password
