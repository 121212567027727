import React, { useEffect } from 'react'
import { Col, Input, Row, Select } from 'antd'
import Card from '../../../../../../../components/inputs/card'
import moment from 'moment'

const CardData = (props) => {
  useEffect(() => {}, [props.validateStatus])

  return (() => {
    let errorCVCJsx = null
    let errorMonthJsx = null
    let errorYearJsx = null

    const inputCVCClasses = []
    const inputYearClasses = []
    const inputMonthClasses = []

    if (props.validateCVCStatus && props.validateCVCStatus === 'error' && props.cvcErrors) {
      errorCVCJsx = (
        <div className="ep-error-message">
          <div>{props.messageCVC}</div>
        </div>
      )

      inputCVCClasses.push('ep-cvc-error')
    }

    if (props.validateYearStatus && props.validateYearStatus === 'error' && props.yearErrors) {
      errorYearJsx = (
        <div className="ep-error-message">
          <div>{props.messageYear}</div>
        </div>
      )

      inputYearClasses.push('ep-year-error')
    }

    if (props.validateMonthStatus && props.validateMonthStatus === 'error' && props.monthErrors) {
      errorMonthJsx = (
        <div className="ep-error-message">
          <div>{props.messageMonth}</div>
        </div>
      )

      inputMonthClasses.push('ep-month-error')
    }

    const monthArr = Array.from({ length: 12 }, (v, k) => k + 1)

    const yearsList = () => {
      const year = parseInt(moment().format('YYYY'))
      const years = []
      const endYear = year + 10

      for (let i = year; i < endYear; i++) {
        years.push(
          <Select.Option key={i} value={i}>
            {i}
          </Select.Option>
        )
      }

      return years
    }

    return (
      <>
        <Row style={{ marginTop: '20px' }}>
          <Col span={24}>
            <Card
              placeholder="Card Number"
              onChange={(e, v) => props.handleChangeCard('cardNumber', v)}
              value={typeof props.orderReducer.payment.cardNumber !== 'undefined' ? props.orderReducer.payment.cardNumber : ''}
              message={props.messages.cardNumber ? props.messages.cardNumber.message : ''}
              validateStatus={props.messages.cardNumber ? props.messages.cardNumber.validateStatus : null}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col span={8}>
            <Select
              placeholder="MM"
              style={{ width: '99%' }}
              size="large"
              onChange={(month) => props.handleChangeCard('month', month)}
              className={inputMonthClasses.join(' ')}
            >
              {monthArr.map((i) => {
                return (
                  <Select.Option value={`${i}`} key={`opt-${i}`}>
                    {i}
                  </Select.Option>
                )
              })}
            </Select>
            {errorMonthJsx}
          </Col>
          <Col span={8}>
            <Select
              placeholder="YYYY"
              style={{ width: '99%' }}
              size="large"
              onChange={(year) => props.handleChangeCard('year', year)}
              className={inputYearClasses.join(' ')}
            >
              {yearsList()}
            </Select>
            {errorYearJsx}
          </Col>
          <Col span={8}>
            <Input placeholder="CVC/CVV" size="large" onChange={props.handleChangeCVC} className={inputCVCClasses.join(' ')} />
            {errorCVCJsx}
          </Col>
        </Row>
      </>
    )
  })()
}

export default CardData
