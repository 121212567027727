import React, { useEffect } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Menu, Row, Col } from 'antd'

import './myAccount.scss'
import Container from '../../Container'
import { getAuthData } from '../../../helper/auth'
import UserWelcome from '../../../components/UserWelcome/UserWelcome'

const MyAccount = (props) => {
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const auth = getAuthData()

    if (auth !== null && auth.customer === null) {
      history.push('/')
    } else if (auth === null) {
      history.push('/sign-in')
    }
  }, [])

  return (
    <Container>
      <UserWelcome /> {/* greeting line */}
      <Row className="MyAccountTableSt" style={{ paddingTop: '30px' }}>
        <Col className="MyAccountMenuSt" span="6">
          <Menu selectedKeys={[location.pathname]} mode="inline">
            <Menu.Item className="MyAccountMenuItem" key="/customer/my-account/current-appointments">
              <Link to={'/customer/my-account/current-appointments'}>Current appointments</Link>
            </Menu.Item>
            <Menu.Item className="MyAccountMenuItem" key="/customer/my-account/previous-appointment">
              <Link to={`/customer/my-account/previous-appointment`}>Previous appointments</Link>
            </Menu.Item>
            <Menu.Item className="MyAccountMenuItem" key="/customer/my-account/notifications">
              <Link to={'/customer/my-account/notifications'}>Notification</Link>
            </Menu.Item>
            <Menu.Item className="MyAccountMenuItem" key="/customer/my-account/contact-support">
              <Link to={'/customer/my-account/contact-support'}>Contact support</Link>
            </Menu.Item>
            <Menu.Item className="MyAccountMenuItem" key="/customer/my-account/setting">
              <Link to={'/customer/my-account/setting'}>Setting page</Link>
            </Menu.Item>
          </Menu>
        </Col>
        <Col span="18" className="rightSideBar">
          {props.children}
        </Col>
      </Row>
    </Container>
  )
}

export default MyAccount
