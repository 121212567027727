import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Row, Col } from 'antd'
import Container from '../Container'

const SignUpPicker = () => {
  const history = useHistory()

  return (
    <Container>
      <Row>
        <Col span="12">
          <Button type="primary" shape="round" size="large" onClick={() => history.push('/inspector/sign-up')}>
            Register as Inspector
          </Button>
        </Col>
        <Col span="12">
          <Button type="primary" shape="round" size="large" onClick={() => history.push('/mechanic-shop/sign-up')}>
            Register as Mechanic Shop
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default SignUpPicker
