import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Validator from 'validatorjs'

import Container from '../Container'
import { Row, Col, Button, message } from 'antd'

import EmailSignIn from '../signIn/email/EmailSignIn'

import '../../../Icons.scss'
import './forgotPassword.scss'
import { requestResetPassword } from '../../../api/user'

const ForgotPassword = () => {
  const history = useHistory()
  const [email, setEmail] = useState()

  const [messages, setMessages] = useState({
    email: {
      message: '',
      validateStatus: null,
    },
  })

  /**
   * Handle change input.
   *
   * @param e
   * @param name
   */

  const handleChange = (e, name) => {
    setEmail(e.target.value)
    setMessages({
      ...messages,
      [name]: {
        message: '',
        validateStatus: null,
      },
    })
  }

  const handleSubmit = () => {
    const data = {
      email,
    }
    const rules = {
      email: 'required|email',
    }
    const customMessages = {
      required: 'The :attribute field is required',
    }

    const validation = new Validator(data, rules, customMessages)
    if (validation.fails()) {
      const errors = validation.errors.all()
      let msg = { ...messages }

      for (let prop in errors) {
        msg = {
          ...msg,
          [prop]: { message: errors[prop][0], validateStatus: 'error' },
        }
      }
      setMessages(msg)
      return
    }

    // TODO: send email
    requestResetPassword({
      email,
    })
      .then(() => {
        message.info({
          content: 'Please check your email',
          style: {
            marginTop: '17vh',
          },
        })
      })
      .catch((err) => {
        if (typeof err.response.data.error !== 'undefined') {
          message.error({
            content: err.response.data.error.message,
            style: {
              marginTop: '17vh',
            },
          })
        }
      })
  }

  return (
    <Container>
      <Row>
        <Col span={2} style={{ textAlign: 'left' }}>
          <Button
            type="primary"
            style={{ marginLeft: 'left' }}
            size="small"
            onClick={() => {
              history.goBack()
            }}
          >
            Back
          </Button>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <div className="formForgotPassword">
            <div className="ep-forgot-password-form">
              <h2>Restore Password</h2>
              <Row>
                <Col span={24}>
                  <EmailSignIn onChange={(e) => handleChange(e, 'email')} message={messages?.email?.message} validateStatus={messages?.email?.validateStatus} />
                </Col>
              </Row>
              <Row style={{ marginTop: '53px' }}>
                <Col span={24}>
                  <button className="ep-button ep-restore" onClick={handleSubmit}>
                    Restore
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default ForgotPassword
