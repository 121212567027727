import React, { useEffect, useRef } from 'react'

const Test = () => {
  const canvasRef = useRef(null)

  useEffect(() => {
    drawBorder()
  }, [])

  const drawBorder = () => {
    const ctx = canvasRef.current.getContext('2d')
    ctx.beginPath()
    ctx.moveTo(10, 10)
    ctx.lineTo(300, 10)
    ctx.lineTo(300, 300)
    ctx.lineTo(10, 300)
    ctx.lineTo(10, 10)
    ctx.stroke()
  }

  return <canvas ref={canvasRef} width={300} height={300} />
}

export default Test
