import axios from 'axios'

export const statuses = ['Awaiting Approve', 'Awaiting Execution', 'Inspected', 'Canceled']

/**
 * Appointments history.
 * @returns {Promise<AxiosResponse<T>>}
 */
export const appointmentsHistory = (status) => {
  let config = {}
  const auth = JSON.parse(localStorage.getItem('auth'))
  let customerId = 0

  if (auth && typeof auth.access_token !== 'undefined') {
    config = {
      headers: {
        Authorization: `Bearer ${auth.access_token}`,
      },
    }
  }

  if (auth && typeof auth.customer !== 'undefined' && auth.customer) {
    customerId = auth.customer.id
  }
  return axios.get(`${process.env.REACT_APP_API_URL}/appointments/customer/${customerId}/history?status=${status}`, config)
}

/**
 * Appointments current history.
 * @returns {Promise<AxiosResponse<T>>}
 */
export const appointmentsHistoryCurrent = () => {
  let config = {}
  const auth = JSON.parse(localStorage.getItem('auth'))
  let customerId = 0

  if (auth && typeof auth.access_token !== 'undefined') {
    config = {
      headers: {
        Authorization: `Bearer ${auth.access_token}`,
      },
    }
  }

  if (auth && typeof auth.customer !== 'undefined' && auth.customer) {
    customerId = auth.customer.id
  }
  return axios.get(`${process.env.REACT_APP_API_URL}/appointments/customer/${customerId}/history/current`, config)
}

/**
 * Appointments prev history.
 * @returns {Promise<AxiosResponse<T>>}
 */
export const appointmentsHistoryPrev = () => {
  let config = {}
  const auth = JSON.parse(localStorage.getItem('auth'))
  let customerId = 0

  if (auth && typeof auth.access_token !== 'undefined') {
    config = {
      headers: {
        Authorization: `Bearer ${auth.access_token}`,
      },
    }
  }

  if (auth && typeof auth.customer !== 'undefined' && auth.customer) {
    customerId = auth.customer.id
  }
  return axios.get(`${process.env.REACT_APP_API_URL}/appointments/customer/${customerId}/history/prev`, config)
}

/**
 * Customer.
 * @returns {Promise<AxiosResponse<T>>}
 */
export const customer = () => {
  let config = {}
  const auth = JSON.parse(localStorage.getItem('auth'))
  let customerId = 0

  if (auth && typeof auth.access_token !== 'undefined') {
    config = {
      headers: {
        Authorization: `Bearer ${auth.access_token}`,
      },
    }
  }

  if (auth && typeof auth.customer !== 'undefined' && auth.customer) {
    customerId = auth.customer.id
  }
  return axios.get(`${process.env.REACT_APP_API_URL}/dashboard/customers/${customerId}`, config)
}
