import React from 'react'
import { LoadScriptNext, GoogleMap, Marker, InfoBox } from '@react-google-maps/api'

const GOOGLE_MAPS_API = process.env.REACT_APP_GOOGLE_API_KEY

const MapComponentWithMarkers = ({
  markers = [], // array of bodyshop objects. Needs for multipleMarkers
  location = { lat: 0, lng: 0 }, // coordinates for center of map and marker
  style = { height: '100px', width: '100px' }, // styles for map container
  zoom = 0, // defines default map zoom
  selected, // selected body shop
  onPress,
}) => {
  const selectedMarker = markers.find(m => m.id === selected) || markers[0]

  const arrayOfMarkers = markers.map((marker, index) => {
    const { coords, id } = marker
    const isCurrentSelected = id === selected
    return (
      <div key={`marker-${index}`}>
        <Marker
          onClick={() => onPress(id)}
          position={coords}
          zIndex={isCurrentSelected ? 100 : 0}
          icon={
            isCurrentSelected
              ? {
                  url: 'http://maps.google.com/mapfiles/ms/micons/blue-dot.png',
                }
              : {
                  url: 'http://maps.google.com/mapfiles/ms/micons/red-dot.png',
                }
          }
        />
      </div>
    )
  })

  if (markers.length) {
    return (
      <LoadScriptNext id="script-loader" googleMapsApiKey={GOOGLE_MAPS_API}>
        <GoogleMap id="google-map-component" mapContainerStyle={{ maxWidth: '800px', height: '400px' }} center={selectedMarker.coords} zoom={zoom ? zoom : 10}>
          {arrayOfMarkers}
        </GoogleMap>
      </LoadScriptNext>
    )
  }

  return (
    <LoadScriptNext id="script-loader" googleMapsApiKey={GOOGLE_MAPS_API}>
      <GoogleMap id="google-map-component" mapContainerStyle={style} center={location} zoom={zoom ? zoom : 20}>
        <Marker position={location} />
      </GoogleMap>
    </LoadScriptNext>
  )
}

export default MapComponentWithMarkers
