export const isOpen = (openTimeStr, closeTimeStr) => {
  let openDate = getDate(openTimeStr)
  let closeDate = getDate(closeTimeStr)

  if (!openDate || !closeDate) return null

  let currentDate = new Date()

  return openDate <= currentDate && currentDate <= closeDate
}

const removePeriod = (str) => str.replace('am', '').replace('pm', '')
const isPm = (str = '') => str.includes('pm')
const additionalTime = (str) => (isPm(str) ? 12 : 0)
const withPeriodInTime = (str) => /[0-9]{1,2}:[0-9]{1,2}(am|pm)/.test(str)
const withoutPeriodInTime = (str) => /[0-9]{1,2}:[0-9]{1,2}/.test(str)

const getDate = (timeStr) => {
  if (!timeStr) return null

  let timeElements

  if (withPeriodInTime(timeStr)) {
    timeElements = removePeriod(timeStr).split(':')
  } else if (withoutPeriodInTime(timeStr)) {
    timeElements = timeStr.split(':')
  } else {
    return null
  }

  timeElements = timeElements.map((e) => parseInt(e))

  const date = new Date()
  const [hours, minutes] = timeElements

  date.setHours(hours + additionalTime(timeStr), minutes)

  return date
}
