import React from 'react'
import { Layout } from 'antd'
import Footer from '../../components/footer/Footer'
import './Container.scss'
import Header from '../Header'

const Container = (props) => {
  return (
    <div className="ep-container">
      <Layout className="ep-layout">
        <Header />
        <Layout.Content>{props.children}</Layout.Content>
        <Layout.Footer>
          <Footer />
        </Layout.Footer>
      </Layout>
    </div>
  )
}

export default Container
