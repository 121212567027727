import types from './types'

const setOrderApplicantInfo = (payload) => ({
  type: types.SET_ORDER_APPLICANT_INFO,
  payload,
})

const setOrderCar = (payload) => ({
  type: types.SET_ORDER_CAR,
  payload,
})

const setOrderInspection = (payload) => ({
  type: types.SET_ORDER_INSPECTION,
  payload,
})

const setOrderPayment = (payload) => ({
  type: types.SET_ORDER_PAYMENT,
  payload,
})

export default {
  setOrderApplicantInfo,
  setOrderCar,
  setOrderInspection,
  setOrderPayment,
}
