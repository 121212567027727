import axios from 'axios'
import { getAuthData } from 'helper/auth'

export const dealer = () => {
  let config = {}
  const auth = JSON.parse(localStorage.getItem('auth'))
  let dealerId = 0

  if (auth && typeof auth.access_token !== 'undefined') {
    config = {
      headers: {
        Authorization: `Bearer ${auth.access_token}`,
      },
    }
  }

  if (auth?.user) {
    dealerId = auth.user.id
  }
  return axios.get(`${process.env.REACT_APP_API_URL}/dashboard/dealers/${dealerId}`, config)
}

export const changeInfoDealer = (data) => {
  const auth = getAuthData()
  const id = auth?.role?.id === 6 && auth.user.id

  if (auth) {
    const options = {
      method: 'PATCH',
      headers: { Authorization: 'Bearer ' + auth.access_token },
      data,
      url: `${process.env.REACT_APP_API_URL}/dashboard/dealers/${id}`,
    }
    console.log(data, data)
    return axios(options)
  }

  return new Promise((resolve, reject) => {
    reject('need authorization')
  })
}
