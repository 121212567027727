import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Col, message, Result, Row } from 'antd'
import PersonName from '../../../components/inputs/personName'
import Email from '../../../components/inputs/email'
import Phone from '../../../components/inputs/phone'
import Password from '../../../components/inputs/password'
import { Link } from 'react-router-dom'
import { registrationInspector } from '../../../api/user'
import { TimePicker, Divider } from 'antd'

import './index.scss'

const Form = () => {
  const history = useHistory()
  const [successful, setSuccessful] = useState(false)
  const [regForm, setRegForm] = useState({
    company_id: 1,
    is_bodyshop: 0,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: '',
    confirm_password: '',
    state_code: 'LA',
  })

  /**
   * Handle change input.
   *
   * @param e
   * @param name
   */
  const handleChange = (e, name) => {
    setRegForm({ ...regForm, [name]: e.target.value })
  }

  const handleChangeTime = (e, name) => {
    setRegForm({ ...regForm, [name]: e.format('HH:mm') })
  }

  const handleSubmit = () => {
    registrationInspector(regForm)
      .then(() => {
        setSuccessful(true)
      })
      .catch((err) => {
        for (let prop in err.response.data.error) {
          message.error(err.response.data.error[prop][0])
        }
      })
  }

  const goSignIn = () => {
    history.push('/sign-in')
  }

  const goHome = () => {
    history.push('/sign-in')
  }

  return (() => {
    if (successful) {
      return (
        <Result
          status="success"
          title="Successfully Registration!"
          subTitle="Thank you for registering!"
          extra={[
            <Button type="primary" key="sign-in" onClick={goSignIn}>
              Sign In
            </Button>,
            <Button key="home" onClick={goHome}>
              Go Home
            </Button>,
          ]}
        />
      )
    } else {
      return (
        <div className="ep-reg-inspector-form" style={{ height: '950px' }}>
          <div className="ep-sign-up-form">
            <h2>Inspector</h2>
            <Row>
              <Col span={24}>
                <PersonName
                  onChange={(firstName, lastName) => {
                    if (firstName) {
                      setRegForm({
                        ...regForm,
                        first_name: firstName,
                      })
                    }

                    if (lastName) {
                      setRegForm({
                        ...regForm,
                        last_name: lastName,
                      })
                    }
                  }}
                />
              </Col>
            </Row>
            <Row className="ep-reg-form-row">
              <Col span={24}>
                <Email onChange={(e) => handleChange(e, 'email')} />
              </Col>
            </Row>
            <Row className="ep-reg-form-row">
              <Col span={24}>
                <Phone onChange={(e) => handleChange(e, 'phone')} />
              </Col>
            </Row>
            <Row className="ep-reg-form-row">
              <Col span={24}>
                <Password placeholder="Password" onChange={(e) => handleChange(e, 'password')} />
              </Col>
            </Row>
            <Row className="ep-reg-form-row">
              <Col span={24}>
                <Password placeholder="Confirm Password" onChange={(e) => handleChange(e, 'confirm_password')} />
              </Col>
            </Row>
            <Divider>Available time</Divider>
            <Row className="ep-reg-form-row ep-available-time">
              <Col span={12}>
                <TimePicker className="ep-timepicker-from" size="large" onChange={(e) => handleChangeTime(e, 'open_time')} placeholder="From" format="HH:mm" />
              </Col>
              <Col span={12}>
                <TimePicker className="ep-timepicker-to" size="large" onChange={(e) => handleChangeTime(e, 'close_time')} placeholder="To" format="HH:mm" />
              </Col>
            </Row>

            <Row style={{ marginTop: '47px' }}>
              <Col span={12}>
                <Link to="/sign-in" className="ep-label-primary" style={{ width: '78px' }}>
                  Sign In Now
                </Link>
              </Col>
              <Col span={12}>
                <Link to="/sign-in" className="ep-label-default">
                  Already have an account?
                </Link>
              </Col>
            </Row>
            <Row className="ep-reg-form-row">
              <Col span={24}>
                <button className="ep-button ep-sign-up" onClick={handleSubmit}>
                  Sign Up
                </button>
              </Col>
            </Row>
          </div>
        </div>
      )
    }
  })()
}

export default Form
