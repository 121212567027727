import React from 'react'
import './PersonNameSignUp.scss'

import TextField from '@material-ui/core/TextField'

const PersonNameSignUp = (props) => {
  let errorFirstNameJsx = null
  let errorLastNameJsx = null
  const inputClasses = ['input-person-name']

  if (props.validateFirstStatus && props.validateFirstStatus === 'error' && props.firstNameErrors) {
    errorFirstNameJsx = (
      <section className="ep-error-message error-msg-personName err-msg-personNameFirst">
        <section>{props.messageFirst}</section>
      </section>
    )

    inputClasses.push('ep-error')
  }

  if (props.validateLastStatus && props.validateLastStatus === 'error' && props.lastNameErrors) {
    errorLastNameJsx = (
      <section className="ep-error-message error-msg-personName err-msg-personNameLast">
        <section>{props.messageLast}</section>
      </section>
    )

    inputClasses.push('ep-error')
  }

  const nameOptions = {
    className: inputClasses.join(' '),
    type: 'text',
  }

  let firstNameOptions
  let lastNameOptions

  if (!props.sameApplicantInfo) {
    firstNameOptions = {
      ...nameOptions,
      onChange: (e) => props.onChange(e.target.value, null),
      defaultValue: props.firstName,
    }

    lastNameOptions = {
      ...nameOptions,
      onChange: (e) => props.onChange(null, e.target.value),
      defaultValue: props.lastName,
    }
  } else {
    firstNameOptions = {
      ...nameOptions,
      onChange: (e) => props.onChange(e.target.value, null, 'same'),
      value: props.firstNameValue ? props.firstNameValue : '',
    }

    lastNameOptions = {
      ...nameOptions,
      onChange: (e) => props.onChange(null, e.target.value, 'same'),
      value: props.lastNameValue ? props.lastNameValue : '',
    }
  }

  return (
    <section className="ep-input-person-name-container">
      <i className="ep-icon ep-icon-person ep-icon-absolute" />

      <section className="ep-input-person-name-inputs">
        {props.sameApplicantInfo ? (
          <>
            <input {...firstNameOptions} />
            <input {...lastNameOptions} />
          </>
        ) : (
          <>
            <section className="textField">
              <TextField id="mui-theme-provider-standard-input" label="First name" {...firstNameOptions} />

              {errorFirstNameJsx}
            </section>

            <section className="textField">
              <TextField id="mui-theme-provider-standard-input" label="Last name" {...lastNameOptions} />

              {errorLastNameJsx}
            </section>
          </>
        )}
      </section>
    </section>
  )
}

export default PersonNameSignUp
