import React from 'react'
import './EmailSignIn.scss'

import { Form } from 'antd'
import TextField from '@material-ui/core/TextField'

const EmailSignIn = (props) => {
  let errorJsx = null
  const inputClasses = ['input-email']

  if (props.validateStatus && props.validateStatus === 'error') {
    errorJsx = (
      <section className="ep-error-message error-msg-email">
        <section>{props.message}</section>
      </section>
    )

    inputClasses.push('ep-error')
  }

  return (
    <section className={inputClasses.join(' ')}>
      <i className="ep-icon ep-icon-email ep-icon-absolute" />

      <Form.Item className="email-inputSignIn">
        <TextField
          className="email-input"
          id="mui-theme-provider-standard-input"
          label="Email"
          onChange={props.onChange}
          value={props.value}
          readOnly={props.readOnly ? props.readOnly : false}
        />
      </Form.Item>

      {errorJsx}
    </section>
  )
}

export default EmailSignIn
