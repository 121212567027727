import React from 'react'
import { DatePicker } from 'antd'
const { RangePicker } = DatePicker

const Filters = (props) => {
  const onCalendarChange = (range) => {
    props.setPeriodType('')
    if (range) {
      props.handleDateChange({
        from: range[0],
        to: range[1],
      })
    }
  }

  return (
    <>
      <div className="filter-buttons">
        <button className="filter-button" onClick={() => props.setPeriodType('24H')}>
          24H
        </button>
        <button className="filter-button" onClick={() => props.setPeriodType('7 DAYS')}>
          7 DAYS
        </button>
        <button className="filter-button" onClick={() => props.setPeriodType('30 DAYS')}>
          30 DAYS
        </button>
      </div>
      <div className="filter-inputs">
        <RangePicker
          format="MM/DD/YYYY"
          onCalendarChange={onCalendarChange}
          defaultValue={[props.selectedDate.from, props.selectedDate.to]}
          value={[props.selectedDate.from, props.selectedDate.to]}
          allowClear={false}
        />
      </div>
    </>
  )
}

export default Filters
