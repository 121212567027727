import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Container from '../../../../Container'
import { Col, Image, Row } from 'antd'
import Validator from 'validatorjs'
import Steps from 'components/steps'
import inspectorImg from 'images/inspection/inspectors.jpg'
import DriveToYourLocation from './components/DriveToYourLocation'
import ContinueBackCmp from 'components/buttons/ContinueBackCmp'
import actions from 'store/order/actions'
import './Index.scss'
import { customer } from 'api/customer'

const SelectLocationType = (props) => {
  const history = useHistory()
  const [messages, setMessages] = useState({})

  useEffect(() => {
    window.scrollTo(0, 0)
    
    const authJson = window.localStorage.getItem('auth')

    if (authJson) {
      const auth = JSON.parse(authJson)

      if (auth?.technician) {
        history.push('/inspector/my-account/statistic')
      }

      if (auth?.customer) {
        customer().then((data) => {
          props.setOrderApplicantInfo({
            ...props.applicantInfo,
            lastName: data.data.last_name,
            firstName: data.data.first_name,
            phone: data.data.phone,
          })
        })
      } else {
        props.setOrderApplicantInfo({
          ...props.applicantInfo,
          lastName: auth.user.last_name,
          firstName: auth.user.first_name,
          phone: auth.user.phone,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  let data
  let rules
  let customMessages

  if (props.inspection.isShopLocation) {
    data = {
      zip: props.inspection.location.zip,
    }

    rules = {
      zip: 'required|digits:5',
    }

    customMessages = {
      'required.zip': 'Please input your :attribute code',
    }
  } else {
    data = {
      address: props.inspection.location.address,
      apartment: props.inspection.location.apartment,
    }

    rules = {
      address: 'required',
      apartment: 'regex:/^[0-9a-zA-Z.-]+$/',
    }

    customMessages = {
      'required.address': 'Please input your address',
      'regex.apartment': 'Use only letters, digits, dots and dashes',
    }
  }

  const validation = new Validator(data, rules, customMessages)

  const getStep = () => {
    if (validation.passes() && props.inspection.isShopLocation) {
      return '/order/location/select-shop'
    }
    if (validation.passes() && !props.inspection.isShopLocation) {
      // return '/order/payment'
      return '/order/inspection/select-package'
    }
    if (validation.fails()) {
      return '/order/location/select-location-type'
    }
  }

  const handleContinueClick = () => {
    if (validation.fails()) {
      const errors = validation.errors.all()
      let msg = { ...messages }

      let zipErrors = {}
      let addressErrors = {}

      for (let prop in errors) {
        errors[prop].map((error) => {
          msg[prop] = { message: error, validateStatus: 'error' }

          if (prop === 'zip') {
            zipErrors[prop] = { message: error, validateStatus: 'error' }
          }

          if (prop === 'address' || prop === 'apartment') {
            addressErrors[prop] = { message: error, validateStatus: 'error' }
          }
        })
      }

      props.inspection.isShopLocation ? setMessages(zipErrors) : setMessages(addressErrors)
    }
  }

  useEffect(() => {
    props.setOrderInspection({
      ...props.orderReducer.inspection,
      shop: null,
      location: {
        ...props.orderReducer.inspection.location,
        zip: '',
        address: '',
        apartment: '',
      },
    })
  }, [])

  return (() => {
    return (
      <Container>
        <Steps style={{ marginTop: '35px' }} />
        <div className="ep-order-inspection-container" style={{ marginTop: '50px' }}>
          <Row className="SelectLocTypeStyle">
            <Col span={24}>
              <div className="ep-order-inspection-block ep-left">
                <h2 className="location-h2">Technician drives to you</h2>
                <Image src={inspectorImg} preview={false} />
                <p>CARO inspectors are experienced and certified professionals you can trust and can perform auto inspection at any given location.</p>
              </div>
            </Col>
          </Row>
          <Row
            style={{
              display: !props.orderReducer.inspection.isShopLocation ? 'flex' : 'none',
              justifyContent: 'center',
              marginTop: 81,
            }}
          >
            <Col span={20}>
              <DriveToYourLocation
                messageAddress={messages?.address?.message || ''}
                validateStatusAddress={messages?.address?.validateStatus}
                messageApartment={messages?.apartment?.message || ''}
                validateStatusApartment={messages?.apartment?.validateStatus}
                errorsAddress={messages?.address}
                errorsApartment={messages?.apartment}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ContinueBackCmp route={getStep()} onClick={handleContinueClick} pageName="location type" />
            </Col>
          </Row>
        </div>
      </Container>
    )
  })()
}

const mapStateToProps = (state) => {
  return {
    orderReducer: state.order.orderReducer,
    applicantInfo: state.order.orderReducer.applicantInfo,
    inspection: state.order.orderReducer.inspection,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrderInspection: (payload) => dispatch(actions.setOrderInspection(payload)),
    setOrderApplicantInfo: (payload) => dispatch(actions.setOrderApplicantInfo(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectLocationType)
