import React, { useEffect, useState } from 'react'
import { Table, Badge, Modal, Button } from 'antd'
import moment from 'moment'

import './previousAppointment.scss'
import MyAccount from '../../myAccount'
import { appointmentsHistoryPrev, statuses } from '../../../../../api/customer'
import { getAppointment, getAppointmentType, getStatus } from '../../../../../api/appointment'
import WheelLoader from 'components/loaders/WheelLoader'
import noImage from 'images/inspection/noimage-car.jpeg'
import { getPeriodString } from 'helper/periodHelper'
import ModalContent from './modalContent'

const PreviousAppointment = () => {
  const [data, setData] = useState([])
  const [loadingData, setLoadingData] = useState(false)
  const [selectedAppointment, setSelectedAppointment] = useState({
    status: 0,
    src_car_info: null,
    date: '-',
    time: '-',
    technician: '-',
    technician_phone: '-',
    is_our_location: -1,
    location: {},
    note: '-',
    address: '-',
    photo: null,
    name: '-',
    report: '-',
  })

  const [visibleDetail, setVisibleDetail] = useState(false)
  const columns = [
    {
      title: 'Appointment',
      dataIndex: 'appointment',
      key: 'appointment',
      render: (text) => <>{text}</>,
    },
    {
      title: 'Technician',
      dataIndex: 'technician',
      key: 'technician',
      render: (text) => <>{text.length > 10 ? text.slice(0, 11) + '...' : text}</>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <Badge color={'blue'} text={text} />,
    },
    {
      title: 'Appointment сreation date',
      dataIndex: 'application_date',
      key: 'application_date',
      render: (text) => (
        <>
          <div>{moment(text).format('MM/DD/YYYY')}</div>
          <div>{moment(text).format('h:mm A')}</div>
        </>
      ),
    },
    {
      title: 'End of inspection',
      dataIndex: 'inspection_date_time',
      key: 'inspection_date_time',
      render: (text) => (
        <>
          <div>{moment(text).format('MM/DD/YYYY')}</div>
          <div>{moment(text).format('h:mm A')}</div>
        </>
      ),
    },
    {
      title: 'Detail',
      dataIndex: 'view',
      key: 'view',
      render: (id) => (
        <Button type="dashed">
          <a href="#" onClick={() => handleView(id)}>
            Click for Details
          </a>
        </Button>
      ),
    },
  ]

  useEffect(() => {
    setLoadingData(true)
    appointmentsHistoryPrev()
      .then((data) => {
        const rows = []

        data.data.map((row, index) => {
          rows.push({
            appointment: row.id,
            technician: row.technician ? `${row.technician.user.first_name} ${row.technician.user.last_name}` : '-',
            application_date: row.created_at,
            inspection_date_time: row.updated_at,
            status: row.status == 4 ? statuses[1] : statuses[row.status],
            view: row.id,
            key: index,
          })
        })

        let DescendingRows = [...rows].sort(function (a, b) {
          return Date.parse(b.inspection_date_time) - Date.parse(a.inspection_date_time)
        })

        setData(DescendingRows)
        setLoadingData(false)
      })
      .catch(() => setLoadingData(false))
  }, [])

  const handleView = (id) => {
    setVisibleDetail(true)
    getAppointment(id).then((response) => {
      let technician = '-'
      let technician_phone = '-'
      let company = '-'
      let address = '-'
      let photo = null
      let name = '-'
      let scheduledTime = ''

      if (response.data.inspection_time_end && response.data.inspection_time_from) {
        scheduledTime = getPeriodString(response.data.inspection_time_from, response.data.inspection_time_from)
      } else {
        scheduledTime = moment(response.data.inspection_date_time).format('hh:mm A')
      }

      let type = getAppointmentType(response.data.inspection_date_time)

      let report = `${process.env.REACT_APP_RESULTS_URL}${id}?type=${type}&format=html`

      if (response.data.technician) {
        technician = response.data.technician.user.first_name + ' ' + response.data.technician.user.last_name
        technician_phone = response.data.technician.user.phone
      }

      if (response.data.company) {
        company = response.data.company.name
      }

      if (response.data.is_our_location === 1 && response.data.technician) {
        address = response.data.technician.city + ', ' + response.data.technician.street
      } else {
        address = response.data.location.address
      }

      if (response.data.src_car_info) {
        photo = response.data.src_car_info?.photos[0]?.url || noImage
        name =
          response.data.src_car_info.attributes.make +
          ' ' +
          response.data.src_car_info.attributes.model +
          ' ' +
          response.data.src_car_info.attributes.year +
          ' ' +
          `(${response.data.src_car_info.attributes.type})`
      }

      const data = {
        status: getStatus(response.data.status),
        src_car_info: response.data.src_car_info,
        scheduled_date: moment(response.data.inspection_date_time).format('MM/DD/YYYY'),
        created_at: moment(response.data.created_at).format('MM/DD/YYYY h:mmA'),
        scheduled_time: scheduledTime,
        end_of_inspection: moment(response.data.updated_at).format('MM/DD/YYYY h:mmA'),
        technician: technician,
        technician_phone: technician_phone,
        company: company,
        is_our_location: -1,
        location: {},
        address: address,
        note: response.data.note ? response.data.note : '-',
        photo: photo,
        name: name,
        report: report,
      }
      setSelectedAppointment(data)
    })
  }

  return (
    <MyAccount>
      {loadingData ? (
        <WheelLoader />
      ) : (
        <Table
          pagination={{ pageSize: 7 }}
          columns={columns}
          dataSource={data}
          onRow={() => {
            return {
              onClick: () => {
                // setSelectedAppointment(record);
              }, // click row
              onDoubleClick: () => {}, // double click row
              onContextMenu: () => {}, // right button click row
              onMouseEnter: () => {}, // mouse enter row
              onMouseLeave: () => {}, // mouse leave row
            }
          }}
          className="table"
        />
      )}
      <Modal
        className="ap-modal"
        visible={visibleDetail}
        onOk={() => setVisibleDetail(false)}
        onCancel={() => setVisibleDetail(false)}
        footer={[
          <Button key="submit" type="primary" onClick={() => setVisibleDetail(false)}>
            Ok
          </Button>,
        ]}
      >
        <ModalContent selectedAppointment={selectedAppointment} />
      </Modal>
    </MyAccount>
  )
}

export default PreviousAppointment
