import React from 'react'
import moment from 'moment'
import './notifsListItem.scss'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

const NotifsListItem = ({ notif }) => {
  return (
    <ul className="notifList list">
      <li className="">
        {[...notif.appointment_notifs].reverse().map((item) => {
          return (
            <List key={item.id} component="section" className="list">
              <ListItem className="list-item">
                <ListItemText className="list-item-text" primary={item.message} />
              </ListItem>

              <ListItem className="list-item">
                <ListItemText className="list-item-time" primary={`${moment(item.created_at, 'YYYY-MM-DD, hh:mm:ss').format('DD/MM/YY, hh:mm A')}`} />
              </ListItem>

              <ListItem className="list-item">
                <ListItemText
                  primary={
                    item.report_link !== null && (
                      <a target="_blank" rel="noreferrer noopener" href={item.report_link}>
                        View report
                      </a>
                    )
                  }
                />
              </ListItem>
            </List>
          )
        })}
      </li>
    </ul>
  )
}

export default NotifsListItem
