import React from 'react'
import Container from '../../Container'

import Form from './form'

const SignUp = () => {
  return (
    <Container>
      <Form />
    </Container>
  )
}

export default SignUp
