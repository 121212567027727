import axios from 'axios'

export const readNotifications = (notificationId) => {
    let config = {}
    const auth = JSON.parse(localStorage.getItem('auth'))
  
    if (auth && typeof auth.access_token !== 'undefined') {
      config = {
        headers: {
          Authorization: `Bearer ${auth.access_token}`,
        },
      }
    }

    return axios.post(`${process.env.REACT_APP_API_URL}/notifications/mark-as-read/${notificationId}`, null, config)
}