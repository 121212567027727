import React from 'react'
import { useHistory } from 'react-router-dom'
import Container from '../Container'
import { Col, Row } from 'antd'
import EpButton from '../../components/buttons/Button'
import CollapseList from '../../components/CollapseList'

import listData from './listContent'

import { ReactComponent as CarIcon } from '../../images/home/car-3.svg'
import { ReactComponent as CalendarIcon } from '../../images/home/calendar.svg'
import { ReactComponent as PayIcon } from '../../images/home/Group.svg'

import './Faq.scss'

const Faq = () => {
  const history = useHistory()
  const handleRequestInspection = () => {
    history.push('/terms-of-use')
  }

  return (
    <Container>
      <div className="ep-header-container">
        <Row>
          <Col span={24}>
            <div className="page-header-title">Frequently Asked Questions</div> <br />
            <div className="subtitle">What are you looking for?</div>
          </Col>
        </Row>
        <Row style={{ marginTop: '39px' }}>
          <Col span={24}>
            <EpButton type="primary" style={{ marginRight: '9px' }} onClick={handleRequestInspection}>
              Terms of Use
            </EpButton>
            <EpButton onClick={() => history.push('/privacy-policy')}>Privacy Policy</EpButton>
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={24}>
          <div className="faq-header">
            Are you looking for answers on buying or selling?
            <br />
            Choose above.
          </div>
          <div className="subtitle">We&apos;ll take you from 0 to happy!</div>
        </Col>
      </Row>
      <Row>
        <Col span={8} flex={1} className="cards-col-container">
          <div className="cards-container">
            <CarIcon />
            <div className="cards-title">Get a Caro great price!</div>
          </div>
        </Col>
        <Col span={8} flex={1} className="cards-col-container">
          <div className="cards-container">
            <CalendarIcon />
            <div className="cards-title">Schedule an inspection with a Caro mechanic.</div>
          </div>
        </Col>
        <Col span={8} flex={1} className="cards-col-container">
          <div className="cards-container">
            <PayIcon />
            <div className="cards-title">Sell and get paid!</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="list-container">
            <CollapseList list={listData.collapseList_0} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="faq-header">Get Ready to be Caro Joyful!</div>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <div className="image-card-1"></div>
          <div className="image-card-subtitle">Find your baby right here. All cars are amazing and Caro-certified.</div>
        </Col>
        <Col span={8}>
          <div className="image-card-2"></div>
          <div className="image-card-subtitle">Car Buying, meet the 21st century. You do everything online, we take care of the grunt work.</div>
        </Col>
        <Col span={8}>
          <div className="image-card-3"></div>
          <div className="image-card-subtitle">Your wondercar arrives home with Caro&apos;s unparalleled 10-day money-back guarantee.</div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="list-container">
            <CollapseList list={listData.collapseList_0} />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Faq
