import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Container from '../Container'
import { Row, Col, message } from 'antd'
import Password from '../../../components/inputs/password'
import '../../../Icons.scss'
import './restorePassword.scss'
import { resetPassword } from '../../../api/user'

const RestorePassword = () => {
  const { token } = useParams()
  const [data, setData] = useState({
    token,
  })
  const [newPasswordMessage, setNewPasswordMessage] = useState('')
  const [repeatPasswordMessage, setRepeatPasswordMessage] = useState('')

  const history = useHistory()

  /**
   * Handle change input.
   *
   * @param e
   * @param name
   */
  const handleChange = (e, name) => {
    let newData = { ...data }
    newData[name] = e.target.value

    if (name === 'password') {
      setNewPasswordMessage('')
    } else {
      setRepeatPasswordMessage('')
    }

    setData(newData)
  }

  const handleSubmit = () => {
    const emptyErr = 'Cannot be blank'
    const lengthErr = 'The password should be 8 to 24 chars long'

    if (!data.password) {
      setNewPasswordMessage(emptyErr)
    }

    if (!data.repeat_password) {
      setRepeatPasswordMessage(emptyErr)
    }

    if (data.password !== data.repeat_password) {
      setRepeatPasswordMessage('The passwords are different!')
    }

    if (data.password && (data.password.length < 8 || data.password.length > 24)) {
      setNewPasswordMessage(lengthErr)
    }

    if (data.repeat_password && (data.repeat_password.length < 8 || data.repeat_password.length > 24)) {
      setRepeatPasswordMessage(lengthErr)
    }

    if (
      data.password &&
      data.repeat_password &&
      data.password.length >= 8 &&
      data.password.length <= 24 &&
      data.repeat_password.length >= 8 &&
      data.repeat_password.length <= 24 &&
      data.password === data.repeat_password
    ) {
      console.log('eee')
      resetPassword(data)
        .then(() => {
          message.success({
            content: 'Password changed successful',
            style: {
              marginTop: '17vh',
            },
          })

          setTimeout(() => {
            history.push('/sign-in')
          }, 2000)
        })
        .catch((err) => {
          if (typeof err.response.data.error !== 'undefined') {
            message.error({
              content: err.response.data.error.message,
              style: {
                marginTop: '17vh',
              },
            })
          } else {
            message.error({
              content: 'unknown error',
              style: {
                marginTop: '17vh',
              },
            })
          }
        })
    }
  }

  return (
    <Container>
      <Row>
        <Col span={24}>
          <div className="ep-form">
            <div className="ep-forgot-password-form">
              <h2>Enter new password</h2>
              <Row>
                <Col span={24}>
                  <Password placeholder="New password" onChange={(e) => handleChange(e, 'password')} errorMessage={newPasswordMessage} />
                </Col>
              </Row>
              <Row className="repeatNewPassword">
                <Col span={24}>
                  <Password placeholder="Repeat new password" onChange={(e) => handleChange(e, 'repeat_password')} errorMessage={repeatPasswordMessage} />
                </Col>
              </Row>
              <Row style={{ marginTop: '53px' }}>
                <Col span={24}>
                  <button className="ep-button ep-restore" onClick={handleSubmit}>
                    Restore
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default RestorePassword
