import React from 'react'

const ModalContent = ({ selectedAppointment }) => {
  let linkMap = '#'
  if (selectedAppointment.location) {
    linkMap = `https://maps.google.com/?q=${selectedAppointment.location.address}`
  }
  return (
    <div className="modal-container">
      <div className="modal-content content-info">
        <h2>Appointment details</h2>
        <p className="p-row">
          <span className="row-label">Date of inspection</span>
          <span className="row-value">{selectedAppointment.inspectionDate}</span>
        </p>
        <p className="p-row">
          <span className="row-label">Time of inspection</span>
          <span className="row-value">{selectedAppointment.inspectionTime}</span>
        </p>
        <p className="p-row">
          <span className="row-label">Vehicle</span>
          <span className="row-value">{selectedAppointment.vehicle}</span>
        </p>
        <p className="p-row">
          <span className="row-label">Customer Name</span>
          <span className="row-value">
            {selectedAppointment.firstName} {selectedAppointment.lastName}
          </span>
        </p>
        <p className="p-row">
          <span className="row-label">Phone Number</span>
          <span className="row-value">{selectedAppointment.phone}</span>
        </p>
        <p className="p-row">
          <span className="row-label">Address</span>
          <a href={linkMap} target="_blank" className="row-value" rel="noreferrer">
            {selectedAppointment.location && selectedAppointment.location.address}
          </a>
        </p>
        <p className="p-row">
          <span className="row-label">Inspection Requested</span>
          <span className="row-value">{selectedAppointment.package}</span>
        </p>
        {selectedAppointment.note && (
          <p className="p-row">
            <span className="row-label">Note</span>
            <span className="row-value">{selectedAppointment.note}</span>
          </p>
        )}
      </div>
      <div className="modal-content content-photo">
        <img src={selectedAppointment.carPhoto} alt="Car image" />
      </div>
    </div>
  )
}

export default ModalContent
