import types from './types'
import { combineReducers } from 'redux'

export const initialInspection = {
  isShopLocation: false,
  shop: null,
  inspectionPackage: {
    price: 0,
  },
  location: {
    zip: '',
    address: '',
    apartment: '',
    geo_coordinates: {
      lat: '',
      lng: '',
    },
  },
  date: null,
  time: null,
  note: null,
};

const initOrderState = {
  applicantInfo: {
    firstName: null,
    lastName: null,
    email: '',
    phone: null,
  },
  car: {
    vin: '',
    make: null,
    model: null,
    year: null,
    detail: {
      model: null,
      vehicle_type: null,
      vin: null,
      src_car_info: {
        photos: [],
        attributes: {},
      },
    },
  },

  inspection: initialInspection,

  payment: {
    cardNumber: '',
    month: null,
    year: null,
    cvc: null,
  },
}

const orderReducer = (state = initOrderState, action) => {
  switch (action.type) {
    case types.SET_ORDER_APPLICANT_INFO:
      return {
        ...state,
        applicantInfo: action.payload,
      }
    case types.SET_ORDER_CAR:
      return {
        ...state,
        car: action.payload,
      }
    case types.SET_ORDER_INSPECTION:
      return {
        ...state,
        inspection: action.payload,
      }
    case types.SET_ORDER_PAYMENT:
      return {
        ...state,
        payment: action.payload,
      }
    default:
      return state
  }
}

const reducer = combineReducers({
  orderReducer,
})

export default reducer
