import axios from 'axios'
import { getAuthData } from '../helper/auth'

/**
 * Check email.
 * @param email
 * @returns {Promise<AxiosResponse<T>>}
 */
export const checkEmail = (email) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/user/check-email`, {
    email,
  })
}

/**
 * Login.
 * @param email
 * @param password
 * @returns {Promise<AxiosResponse<T>>}
 */
export const login = (email, password) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/login`, {
    email,
    password,
  })
}

export const registrationCustomer = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/registration/customer`, data)
}

export const registrationInspector = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/registration/technician`, data)
}

export const registrationMechanicShop = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/registration/technician`, data)
}

/**
 * Change user info.
 * @param data
 * @returns {Promise<unknown>|*}
 */
export const changeInfo = (data) => {
  const auth = getAuthData()
  const id = auth.customer.user_id
  const dataWithId = { ...data, user_id: id }

  if (auth) {
    return axios.post(`${process.env.REACT_APP_API_URL}/user/change-info/${id}`, dataWithId)
  }

  return new Promise((resolve, reject) => {
    reject('need authorization')
  })
}

export const changeInfoTechician = (data) => {
  const auth = getAuthData()
  const id = auth.technician.id

  if (auth) {
    const options = {
      method: 'PATCH',
      headers: { Authorization: 'Bearer ' + auth.access_token },
      data,
      url: `${process.env.REACT_APP_API_URL}/dashboard/technicians/${id}`,
    }
    return axios(options)
  }

  return new Promise((resolve, reject) => {
    reject('need authorization')
  })
}

/**
 * Request reset password.
 * @param data
 * @returns {*}
 */
export const requestResetPassword = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/request-reset-password`, data)
}

/**
 * Reset password.
 *
 * @param data
 * @returns {*}
 */
export const resetPassword = (data) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/reset-password`, data)
}

export const changePassword = (data) => {
  const auth = getAuthData()
  const id = auth.customer ? auth.customer.user_id : auth.technician.user_id
  if (auth) {
    return axios.post(`${process.env.REACT_APP_API_URL}/change-password/${id}`, data)
  }
}
