const SET_ORDER_APPLICANT_INFO = 'SET_ORDER_APPLICANT_INFO'
const SET_ORDER_CAR = 'SET_ORDER_CAR'
const SET_ORDER_INSPECTION = 'SET_ORDER_INSPECTION'
const SET_ORDER_PAYMENT = 'SET_ORDER_PAYMENT'

export default {
  SET_ORDER_APPLICANT_INFO,
  SET_ORDER_CAR,
  SET_ORDER_INSPECTION,
  SET_ORDER_PAYMENT,
}
