import React from 'react'
import InputMask from 'react-input-mask'

const Card = (props) => {
  let errorJsx = null
  const inputClasses = ['ep-input-email']

  if (props.message && props.validateStatus === 'error') {
    errorJsx = (
      <div className="ep-error-message">
        <div>{props.message}</div>
      </div>
    )

    inputClasses.push('ep-card-error')
  }

  const onCardChange = (e) => {
    props.onChange(e, e.target.value)
  }

  return (
    <>
      <InputMask
        placeholder={props.placeholder}
        className={`${inputClasses.join(' ')}`}
        mask="9999 9999 9999 9999"
        maskChar=" "
        value={props.value}
        onChange={onCardChange}
      />
      {errorJsx}
    </>
  )
}

export default Card
