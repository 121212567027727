import React from 'react'

import { connect } from 'react-redux'
import { Row, Col } from 'antd'

import Zip from 'components/inputs/zip'
import actions from 'store/order/actions'

const DriveToOurLocation = (props) => {
  const handleChangeZip = (e) => {
    props.setOrderInspection({
      ...props.inspection,
      // isShopLocation: true,
      location: {
        ...props.inspection.location,
        zip: e.target.value,
        address: '',
        apartment: '',
      },
    })
  }

  return (
    <>
      <Row style={{ marginTop: '81px' }}>
        <Col span={8} offset={8}>
          <Zip
            onChange={handleChangeZip}
            value={props.inspection.location.zip}
            message={props.message}
            validateStatus={props.validateStatus}
            errors={props.errors}
          />
        </Col>
      </Row>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    inspection: state.order.orderReducer.inspection,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrderInspection: (payload) => dispatch(actions.setOrderInspection(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DriveToOurLocation)
