import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Steps from 'components/steps'
import { Col, Row, Modal, Button } from 'antd'
import ContinueBackCmp from 'components/buttons/ContinueBackCmp'
import Email from 'components/inputs/email'
import Container from '../../../../Container'
import { getPackages } from 'api/packages'
import { getAuthData } from 'helper/auth'
import actions from 'store/order/actions'
import './Index.scss'
import vinAudit from '../../../../../../images/vin-audit.png'
import Validator from 'validatorjs'
import moment from 'moment'
import SelectLocType from './SelectLocation'
import StepsForCarPrice from 'components/stepsForCarPrice/Steps'
import { useHistory } from 'react-router'

const ShowCarPrice = (props) => {
  const [currentCarPhoto, setCurrentCarPhoto] = useState('')
  const [auth, setAuth] = useState(null)
  const [addressMessages, setAddressMessages] = useState(null)
  const history = useHistory()

  const [messages, setMessages] = useState({
    email: {
      message: '',
      validateStatus: null,
    },
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    
    const auth = getAuthData()
    setAuth(auth)

    if (props.car.detail && props.car.detail.src_car_info.photos && props.car.detail.src_car_info.photos.length > 0) {
      const numberOfCarPhotos = props.car.detail.src_car_info.photos.length
      const randPhotoIndex = Math.floor(Math.random() * numberOfCarPhotos)
      setCurrentCarPhoto(props.car.detail.src_car_info.photos[randPhotoIndex].url)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Handle change email.
   *
   * @param e
   */
  const handleChangeEmail = (e) => {
    setMessages({
      ...messages,
      email: {
        message: '',
        validateStatus: null,
      },
    })

    props.setOrderApplicantInfo({
      ...props.applicantInfo,
      email: e.target.value,
    })
  }

  const handleSelectCategory = (e, categoryId) => {
    props.setOrderInspection({ ...props.inspection, categoryId })
  }

  const onHandleContinue = () => {
    setAddressMessages(null)
    const data = {
      email: props.applicantInfo.email,
      address: props.inspection.location.address,
      apartment: props.inspection.location.apartment,
    }

    const rules = {
      email: 'required|email',
      address: 'required',
      apartment: 'regex:/^[0-9a-zA-Z.-]+$/',
    }

    const customMessages = {
      'required.address': 'Please input your address',
      'regex.apartment': 'Use only letters, digits, dots and dashes',
    }

    const validation = new Validator(data, rules, customMessages)

    if (validation.passes()) {
      history.push('/order/inspection/select-package')
    }

    if (validation.fails()) {
      history.push('/car-price')
      const errors = validation.errors.all()
      let addressErrors = {}

      for (let prop in errors) {
        errors[prop].map((error) => {
          if (prop === 'address' || prop === 'apartment') {
            addressErrors[prop] = { message: error, validateStatus: 'error' }
          }

          if (prop === 'email') {
            setMessages({
              email: {
                message: 'Please, enter valid email address.',
                validateStatus: 'error',
              },
            })
          }
        })
      }

      setAddressMessages(addressErrors)
    }
  }

  return (
    <Container>
      <StepsForCarPrice style={{ marginTop: '35px' }} />
      <div className="car-price-container">
        <div className="car-price__information">
          <div className="car-price__image">
            <img src={currentCarPhoto} alt="car-image" />
          </div>

          <div className="car-price__info">
            <h4 className="car-price__title">{props.car.detail.model}</h4>
            <div className="car-price__range">Market Range: {props.car.detail.priceMarketValue}</div>
            <h4 className="car-price__assumption">Assumptions</h4>
            <span className="car-price__assumption-text">
              Estimates based on similar vehicles sold between {moment().subtract(90, 'days').format('MMMM D')} - {moment().format('MMMM D')}
              <img src={vinAudit} alt="vin-audit" />
            </span>
          </div>
        </div>

        <div className="car-price__footer">
          Get <span className="car-price__highlighted">accurate price & condition report</span> based on up to date auto inspection
        </div>
      </div>
      <div className="ep-order-inspection-container" style={{ marginTop: '50px' }}>
        <SelectLocType addressMessages={addressMessages} setAddressMessages={setAddressMessages}/>
        <Row style={{ marginTop: '20px' }}>
          <Col offset={6} span={12}>
            <Email
              onChange={handleChangeEmail}
              value={props.applicantInfo.email}
              message={messages.email.message}
              validateStatus={messages.email.validateStatus}
              readOnly={!!auth}
            />
          </Col>
        </Row>
        <ContinueBackCmp
          pageName="get instant car price"
          onClick={onHandleContinue}
          beforeCarPrice={true}
        />
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    inspection: state.order.orderReducer.inspection,
    applicantInfo: state.order.orderReducer.applicantInfo,
    car: state.order.orderReducer.car,
    srcCarInfo: state.order.orderReducer.car.detail.src_car_info,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setOrderApplicantInfo: (payload) => dispatch(actions.setOrderApplicantInfo(payload)),
    setOrderInspection: (payload) => dispatch(actions.setOrderInspection(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowCarPrice)
