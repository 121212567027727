import React from 'react'
import Filters from './filters'
import { ReactComponent as Calendar } from '../../../../../images/inspector/calendar.svg'

const ScheduledStatistic = (props) => {
  return (
    <div className={`statistic-card statistic-card-scheduled`} onClick={() => props.setSelectedStatistic('Scheduled')}>
      <h1>Scheduled</h1>
      <Filters handleDateChange={props.handleDateChange} selectedDate={props.selectedDate} setPeriodType={props.setPeriodType} />
      <div className="inspected-length">
        <div className="inspected-length-label">
          <Calendar />
          Assigned by the client
        </div>
        <div className="inspected-length-value">{props.assignedListScheduled.length}</div>
      </div>
      <div className="inspected-length">
        <div className="inspected-length-label">
          <Calendar />
          Accepted
        </div>
        <div className="inspected-length-value">{props.acceptedListScheduled.length}</div>
      </div>
    </div>
  )
}

export default ScheduledStatistic
