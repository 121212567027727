import { extendMoment } from 'moment-range'
const Moment = require('moment')
const moment = extendMoment(Moment)

export const getFilteredByDatePeriod = (datePeriod, list, scheduled) => {
  const arr = list.filter((item) => {
    const start = moment(datePeriod.from).format('DD MMM YYYY')
    const end = moment(datePeriod.to).format('DD MMM YYYY')
    const range = moment.range(start, end)
    const itemDate = scheduled ? moment(item.inspection_date_time).format('DD MMM YYYY') : moment(item.updated_at).format('DD MMM YYYY')

    if (range.contains(moment(itemDate))) {
      return true
    }

    if (start === end && itemDate === start) {
      return true
    }
  })

  return arr
}

export const getFilteredByPeriodType = (periodType, list, scheduled) => {
  const arr = list.filter((item) => {
    const ourLocation = item.location === 'The client comes to our location.'

    let itemDate = ''
    let itemTime = ''

    if (scheduled) {
      itemDate = moment(item.inspection_date_time).format('DD MMM YYYY')
      if (ourLocation) {
        itemTime = moment(item.inspection_date_time).format('h:mma')
      } else {
        itemTime = moment(item.inspection_time_from, 'hh:mm a').format('h:mma')
      }
    } else {
      itemDate = moment(item.updated_at).format('DD MMM YYYY')
      itemTime = moment(item.updated_at).format('h:mma')
    }

    const rangeSevenDaysScheduled = moment.range(moment().format('DD MMM YYYY'), moment().add(7, 'days').format('DD MMM YYYY'))
    const rangeThirtyDaysScheduled = moment.range(moment().format('DD MMM YYYY'), moment().add(30, 'days').format('DD MMM YYYY'))
    const rangeSevenDaysPrevious = moment.range(moment().subtract(7, 'days').format('DD MMM YYYY'), moment().format('DD MMM YYYY'))
    const rangeThirtyDaysPrevious = moment.range(moment().subtract(30, 'days').format('DD MMM YYYY'), moment().format('DD MMM YYYY'))

    const timeWithMoment = moment({
      years: moment().format('YYYY'),
      months: (+moment().format('MM') - 1).toString(),
      date: moment().format('DD'),
      hours: moment(itemTime, 'h:mm a').format('HH'),
      minutes: moment(itemTime, 'h:mm a').format('mm'),
      seconds: '0',
      milliseconds: '0',
    })

    const timeAndDateWithMoment = moment({
      years: moment(itemDate).format('YYYY'),
      months: (+moment(itemDate).format('MM') - 1).toString(),
      date: moment(itemDate).format('DD'),
      hours: moment(itemTime, 'h:mm a').format('HH'),
      minutes: moment(itemTime, 'h:mm a').format('mm'),
      seconds: '0',
      milliseconds: '0',
    })

    if (periodType && periodType === '24H') {
      if (
        (itemDate === moment().format('DD MMM YYYY') && timeWithMoment.isSameOrAfter(moment())) ||
        (itemDate === moment().add(1, 'days').format('DD MMM YYYY') && moment().isSameOrAfter(timeWithMoment) && scheduled) ||
        (itemDate === moment().subtract(1, 'days').format('DD MMM YYYY') && timeWithMoment.isSameOrAfter(moment()) && !scheduled)
      ) {
        return true
      }
    }

    if (periodType && periodType === '7 DAYS') {
      if ((rangeSevenDaysScheduled.contains(moment(itemDate)) && scheduled) || (rangeSevenDaysPrevious.contains(moment(itemDate)) && !scheduled)) {
        return true
      }
    }

    if (periodType && periodType === '30 DAYS') {
      if (
        (rangeThirtyDaysScheduled.contains(moment(timeAndDateWithMoment)) && scheduled) ||
        (rangeThirtyDaysPrevious.contains(moment(timeAndDateWithMoment)) && !scheduled)
      ) {
        return true
      }
    }
  })
  return arr
}

export const getScheduledDataForChart = (list, periodType) => {
  let res = []
  let count = 9
  let getTimeWithMoment = (time, date) => {
    return moment({
      years: moment(date).format('YYYY'),
      months: (+moment(date).format('MM') - 1).toString(),
      date: moment(date).format('DD'),
      hours: moment(time, 'h:mm a').format('HH'),
      minutes: moment(time, 'h:mm a').format('mm'),
      seconds: '0',
      milliseconds: '0',
    })
  }
  if (periodType === '7 DAYS') {
    count = 9
  } else if (periodType === '30 DAYS') {
    count = 32
  } else {
    count = 25
  }

  if (periodType === '7 DAYS' || periodType === '30 DAYS') {
    for (let i = 1; i <= count; i++) {
      let dateStart = moment().add(i - 1, 'days')

      let filteredArr = list.filter((item) => {
        return moment(item.inspection_date_time).format('DD MM YYY') === moment(dateStart).format('DD MM YYY')
      })

      res.push({
        name: moment(dateStart).format('DD MMM YYYY'),
        count: filteredArr.length,
      })
    }
  } else {
    for (let i = 1; i <= count; i++) {
      let dateStart = moment()
        .add(i - 1, 'hours')
        .startOf('hour')
      let dateEnd = moment().add(i, 'hours').startOf('hour')
      const range = moment.range(dateStart, dateEnd)
      let filteredArr = list.filter((item) => {
        const ourLocation = item.location === 'The client comes to our location.'

        const itemTime = ourLocation ? moment(item.inspection_date_time).format('h:mma') : moment(item.inspection_time_from, 'h:mm a').format('h:mma')

        return range.contains(getTimeWithMoment(itemTime, item.inspection_date_time))
      })

      res.push({
        name: moment(dateStart).format('hh:mm A'),
        count: filteredArr.length,
      })
    }
  }

  return res
}

export const getPreviousDataForChart = (list, periodType) => {
  let res = []
  let count = 7
  if (periodType === '7 DAYS') {
    count = 7
  } else if (periodType === '30 DAYS') {
    count = 30
  } else {
    count = 25
  }

  if (periodType === '7 DAYS' || periodType === '30 DAYS') {
    for (let i = count; i >= -1; i--) {
      let dateStart = i === -1 ? moment().add(1, 'days') : moment().subtract(i, 'days')
      let filteredArr = list.filter((item) => {
        return moment(item.updated_at).format('DD MM YYY') === moment(dateStart).format('DD MM YYY')
      })
      res.push({
        name: moment(dateStart).format('DD MMM YYYY'),
        count: filteredArr.length,
      })
    }
  } else {
    for (let i = 1; i <= count; i++) {
      let dateStart = moment()
        .add(i - 1, 'hours')
        .startOf('hour')
      let dateEnd = moment().add(i, 'hours').startOf('hour')
      const range = moment.range(dateStart, dateEnd)
      let filteredArr = list.filter((item) => {
        const itemTime = moment(item.updated_at, 'h:mm a').format('h:mma')

        const timeWithMoment = moment({
          years: moment(item.updated_at).format('YYYY'),
          months: (+moment(item.updated_at).format('MM') - 1).toString(),
          date: moment(item.updated_at).format('DD'),
          hours: moment(itemTime, 'h:mm a').format('HH'),
          minutes: moment(itemTime, 'h:mm a').format('mm'),
          seconds: '0',
          milliseconds: '0',
        })
        return range.contains(timeWithMoment)
      })
      res.push({
        name: moment(dateEnd).format('hh:mm A'),
        count: filteredArr.length,
      })
    }
  }

  return res
}
