import React from 'react'
import './PhoneSingUp.scss'

import InputMask from 'react-input-mask'
import TextField from '@material-ui/core/TextField'
import { Form } from 'antd'

const PhoneSingUp = (props) => {
  let errorJsx = null
  const inputClasses = ['input-phone']

  if (props.validateStatus && props.validateStatus === 'error') {
    errorJsx = (
      <section className="ep-error-message error-msg-phone">
        <section>{props.message}</section>
      </section>
    )

    inputClasses.push('ep-error')
  }

  return (
    <section className={inputClasses.join(' ')}>
      <i className="ep-icon ep-icon-phone ep-icon-absolute" />
      <Form.Item name="phone">
        <InputMask mask="999-999-9999" value={props.value} onChange={props.onChange}>
          {() => <TextField className="phoneSingUp" label="Phone" onChange={props.onChange} value={props.value} />}
        </InputMask>

        {errorJsx}
      </Form.Item>
    </section>
  )
}

export default PhoneSingUp
