import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import './setting.scss'
import { ReactComponent as WatchIcon } from 'images/inspector/watch.svg'
import { ReactComponent as ArrowIcon } from 'images/inspector/arrow-right.svg'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const WorkTimeAndDays = ({ inspectorData }) => {
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

  const [workDays, setWorkDays] = useState([])
  const [workTime, setWorkTime] = useState({
    open: '',
    close: '',
  })

  useEffect(() => {
    setWorkDays(inspectorData.work_days)
    setWorkTime({
      open: inspectorData.open_time,
      close: inspectorData.close_time,
    })
  }, [inspectorData])

  return (
    <Row className="inspector-form inspection" style={{ padding: '0px 20px' }}>
      <Col span="24">
        <Row gutter={[30, 0]} className="work-days-container">
          <Col span="8">
            <h1 className="form-title">Working time and days</h1>

            <section className="from-to-settings">
              <section className="from-settings">
                <WatchIcon width="20" height="20" />
                <span className="from-text-settings">
                  <span>From</span>
                  <span>{workTime.open}</span>
                </span>
              </section>

              <section className="to-settings">
                <ArrowIcon width="20" height="20" />
                <span className="from-text-settings">
                  <span>To</span>
                  <span>{workTime.close}</span>
                </span>
              </section>
            </section>
          </Col>

          <Col span="16">
            <section className="work-days-settings">
              <section className="work-days-title">
                <section className="work-days-mark">
                  <ExclamationCircleOutlined />
                </section>

                <p className="work-days-change">!!! To change working time and days, please go to the application.</p>
              </section>

              {days.map((day, index) => {
                return (
                  <section className={`work-day ${workDays.includes(day) && 'selected'}`} key={index}>
                    {day}
                  </section>
                )
              })}
            </section>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default WorkTimeAndDays
