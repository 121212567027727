import React, { useEffect, useState } from 'react'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import NotificationsIcon from '@material-ui/icons/Notifications'
import Badge from '@material-ui/core/Badge'
import NotifsListItem from '../notifsListItem/NotifsListItem'
import { readNotifications } from 'api/readNotifications'

const AppointmentNotifications = ({ notif, pageNumber }) => {
  const [notReadedCount, setNotReadedCount] = useState(0);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (notif && notif.appointment_notifs?.length > 0) {
      let count = 0

      notif.appointment_notifs.forEach((notification) => {
        if (notification.status === 1) {
          count = count + 1
        }
      })

      setNotReadedCount(count)
    }
  }, [pageNumber])

  const onHandleRead = () => {
    setExpanded(!expanded)
    if (notif && notif.appointment_notifs?.length > 0) {
      let count = notReadedCount
      !expanded && notif.appointment_notifs.forEach((notification) => {
        readNotifications(notification.id)
          .then((res) => {
            if (res.status === 200) {
              count = count - 1
            }
          })
          .then(() => {
            setNotReadedCount(count)
          })
      })
    }
  }

  return (
    <List key={notif.appointment_id} className="listNotifs">
      <ListItemText
        primary={
          notif.appointment_id === null ? (
            <List className="listGeneral">
              <ListItem>
                <ListItemText primary={<h2>General notifications from Caro</h2>} />
              </ListItem>

              <ListItem>
                <ListItemText primary={<NotifsListItem notif={notif} />} />
              </ListItem>
            </List>
          ) : (
            <Accordion onChange={onHandleRead} expanded={expanded}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <ListItem button>
                  <ListItemText
                    className="listItemApp"
                    primary={
                      <h2>
                        Appointment # {notif.appointment_id} {notif.appointment_notifs[notif.appointment_notifs.length - 1].message}
                      </h2>
                    }
                  />
                </ListItem>

                <Badge badgeContent={notReadedCount > 0 ? notReadedCount : null} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </AccordionSummary>

              <AccordionDetails>
                <Typography component="section">
                  <NotifsListItem notif={notif} />
                </Typography>
              </AccordionDetails>
            </Accordion>
          )
        }
      />
    </List>
  )
}

export default AppointmentNotifications
