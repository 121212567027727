export const getTimezoneByState = state => {
    const hawaii = ["HI"];
    const alaska = ["AK"];
    const pacific = ["WA", "OR", "NV", "CA"];
    const mountain = ["MT", "ID", "WY", "UT", "CO", "AZ", "NM"];
    const central = [
      "ND",
      "MN",
      "SD",
      "IA",
      "NE",
      "MO",
      "KS",
      "OK",
      "AR",
      "TX",
      "LA",
      "IL",
      "WI",
      "MS",
      "AL",
      "TN",
    ];
    const eastern = [
      "MI",
      "IN",
      "KY",
      "GA",
      "FL",
      "SC",
      "NC",
      "NC",
      "VA",
      "WV",
      "OH",
      "PA",
      "NY",
      "ME",
      "VT",
      "NH",
      "MA",
      "RI",
      "CT",
      "NJ",
      "DE",
      "MD",
      "DC",
    ];
  
    if (hawaii.includes(state)) {
      return "America/Honolulu";
    }
  
    if (alaska.includes(state)) {
      return "America/Anchorage";
    }
  
    if (pacific.includes(state)) {
      return "America/Los_Angeles";
    }
  
    if (mountain.includes(state)) {
      return "America/Phoenix";
    }
  
    if (central.includes(state)) {
      return "America/Chicago";
    }
  
    if (eastern.includes(state)) {
      return "America/Washington";
    }
  };
  