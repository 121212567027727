/*global google*/
import React, { useState, useEffect, useRef } from 'react'
import { Select, Button } from 'antd'
import { GoogleMap, LoadScriptNext, Marker } from '@react-google-maps/api'
import 'antd/dist/antd.css'

const { Option } = Select
const libs = ['places']

const ModalContent = ({ setVisibleLocation, setInspectorData, inspectorData }) => {
  const [query, setQuery] = useState(null)
  const [service, setService] = useState(null)
  const [zip, setZip] = useState(inspectorData.zip)
  const [city, setCity] = useState(inspectorData.city)
  const [street, setStreet] = useState(inspectorData.street)
  const [suite, setSuite] = useState(inspectorData.suite)
  const [predictionsList, setPredictionsList] = useState([])
  const [optionsList, setOptionsList] = useState([])
  const [selectedCoords, setSelectedCoords] = useState({
    lat: 32.732346,
    lng: -117.196053,
  })
  const refSearch = useRef()

  useEffect(() => {
    const options = []

    predictionsList.forEach((prediction) => {
      options.push(prediction)
    })

    setOptionsList(options)
  }, [predictionsList])

  const getAddressFromCoords = async (lat, lng) => {
    let response = {
      address: 'NO-ADDRESS',
      location: {},
      isResolved: false,
    }
    try {
      const res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GOOGLE_API_KEY}&latlng=${lat},${lng}`, {
        method: 'GET',
      })
      const json = await res.json()

      if (json.results[0].formatted_address) {
        response.address = json.results[0].formatted_address
        response.isResolved = true
        response.location = json.results[0].address_components
      }
    } catch (e) {
      console.warn(e)
    }

    setQuery(response.address)

    response.location.map((component) => {
      if (component.types.includes('postal_code')) {
        setZip(component.long_name)
      }

      if (component.types.includes('street_number')) {
        setSuite(component.long_name)
      }

      if (component.types.includes('route')) {
        setStreet(component.long_name)
      }

      if (component.types.includes('locality') && component.types.includes('political')) {
        setCity(component.long_name)
      }
    })
  }

  const codeAddress = (value) => {
    let geocoder = new google.maps.Geocoder()

    let codeAdressValue = 'San Diego'

    if (value && predictionsList.length < 1) {
      codeAdressValue = value
    } else if (value && predictionsList.length > 0) {
      codeAdressValue = predictionsList[value].description
    }

    geocoder.geocode(
      {
        address: codeAdressValue,
      },
      (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          if (value) {
            setSelectedCoords({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            })
            results[0].address_components.map((component) => {
              if (component.types.includes('postal_code')) {
                setZip(component.long_name)
              }
              if (component.types.includes('street_number')) {
                setSuite(component.long_name)
              }

              if (component.types.includes('route')) {
                setStreet(component.long_name)
              }
              if (component.types.includes('locality') && component.types.includes('political')) {
                setCity(component.long_name)
              }
            })
          }
        } else {
          alert('Geocode was not successful for the following reason: ' + status)
        }
      }
    )
  }

  return (() => {
    const displaySuggestions = (predictions, status) => {
      const filteredPredictions = []
      predictions &&
        predictions.forEach((prediction) => {
          const item = prediction.structured_formatting.secondary_text ? prediction.structured_formatting.secondary_text : ''

          if ((item.includes('USA') || item.includes('США')) && (item.includes('CA') || item.includes('California') || item.includes('Калифорния'))) {
            filteredPredictions.push(prediction)
          }
        })
      setPredictionsList(filteredPredictions)
    }

    const handleScriptLoad = () => {
      if (service) return

      const createdService = new google.maps.places.AutocompleteService()
      setService(createdService)

      codeAddress(zip + ' ' + city + ' ' + street + ' ' + suite)
      setQuery(street + ', ' + city + ', ' + zip + ' ' + suite)
    }

    const handleSearch = (value) => {
      if (service) {
        const request = {
          location: new google.maps.LatLng(37, -120),
          radius: 750000,
          input: value,
        }

        service.getQueryPredictions(request, displaySuggestions)
      }
    }

    const handleChange = (value) => {
      setQuery(value)
      codeAddress(value)
    }

    return (
      <section className="ep-search-address inspection">
        <LoadScriptNext id="script-loader" googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={libs} onLoad={handleScriptLoad}>
          <section className="search-container">
            <section className="search">
              <Select
                ref={refSearch}
                showSearch
                placeholder="Please provide your address"
                value={query}
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                notFoundContent={null}
                className={`DriveToOurLocationAd`}
              >
                {optionsList.map((item, index) => (
                  <Option key={index}>{item.description}</Option>
                ))}
              </Select>
            </section>

            <GoogleMap
              id="google-map-component"
              mapContainerStyle={{ height: '350px', width: '100%' }}
              center={selectedCoords}
              zoom={20}
              onClick={(e) => {
                const latLng = e.latLng
                const lat = latLng.lat()
                const lng = latLng.lng()
                getAddressFromCoords(lat, lng)
                setSelectedCoords({ lat, lng })
              }}
            >
              <Marker
                position={selectedCoords}
                draggable
                onDragEnd={(e) => {
                  const latLng = e.latLng
                  const lat = latLng.lat()
                  const lng = latLng.lng()
                  getAddressFromCoords(lat, lng)
                  setSelectedCoords({ lat, lng })
                }}
              />
            </GoogleMap>

            <Button
              className="search-btn"
              key="submit"
              type="primary"
              onClick={() => {
                setInspectorData({
                  ...inspectorData,
                  zip,
                  city,
                  street: suite + ' ' + street,
                })
                setVisibleLocation(false)
              }}
            >
              Change address
            </Button>
          </section>
        </LoadScriptNext>
      </section>
    )
  })()
}

export default ModalContent
