import React from 'react'
import { Col, Row, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import './ContinueBackCmp.scss'

const ContinueBackCmp = ({ route, onClick, pageName, beforeCarPrice }) => {
  const history = useHistory()

  const handleClickNextStep = () => {
    if (typeof onClick !== 'undefined') {
      onClick()
    }
    !beforeCarPrice && history.push(route)
    beforeCarPrice && history.push({
      pathname: route,
      state: { beforeCarPrice }
    })
  }

  const handleBack = () => {
    if (pageName && pageName === 'location type') {
      history.push('/order/inspection/select-car')
    }

    if (pageName && pageName === 'select package' && !beforeCarPrice) {
      history.push('/order/location/select-location-type')
    }

    if (pageName && pageName === 'select package' && beforeCarPrice) {
      history.push('/car-price')
    }

    if (pageName && pageName === 'select shop' && !beforeCarPrice) {
      history.push('/order/location/select-location-type')
    }

    if (pageName && pageName === 'select shop' && beforeCarPrice) {
      history.push('/car-price')
    }

    if (pageName && pageName === 'get instant car price') {
      history.push('/get-instant-car-price')
    }
  }

  return (
    <Row className="contineBack">
      <Col span={12}>
        <Button type="primary" className="backBtn" style={{ marginLeft: 'left' }} size="small" onClick={handleBack}>
          Back
        </Button>
      </Col>
      <Col span={12} className="continueDiv">
        <div className="ep-button ep-inspection-start" onClick={handleClickNextStep}>
          <span>Continue</span>
        </div>
      </Col>
    </Row>
  )
}
export default ContinueBackCmp
