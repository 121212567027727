import React, { useEffect, useState, useRef } from 'react'
import './Line.scss'

const Line = (props) => {
  const refLabel = useRef()
  const refLine = useRef()


  const [left, setLeft] = useState(0)
  const [top, setTop] = useState(0)
  const [label, setLabel] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: -78,
  })

  useEffect(() => {
    setLeft(props.point.x - props.width)
    setTop(props.point.y)

    setLabel({
      left:
        typeof props.labelPosition !== 'undefined' && typeof props.labelPosition.left !== 'undefined'
          ? props.labelPosition.left
          : -1 * (props.width / 2) - refLabel.current.offsetHeight,
      top: typeof props.labelPosition !== 'undefined' && typeof props.labelPosition.top !== 'undefined' ? props.labelPosition.top : 0,
    })
  }, [])

  return (
    <div style={{ position: 'absolute' }}>
      <div
        className="block-line"
        ref={refLine}
        style={{
          width: `${props.width}px`,
          left: `${left}px`,
          top: `${top}px`,
          transform: `rotate(${props.rotate}deg)`,
        }}
      >
        <span
          ref={refLabel}
          style={{
            transform: `rotate(-${props.rotate}deg)`,
            top: `${label.top}px`,
            left: `${label.left}px`,
            position: 'relative',
          }}
        >
          {props.label}
        </span>
      </div>
    </div>
  )
}

export default Line
